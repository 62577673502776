import { Close, Description } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { brandKeysByUnit } from '@prismamedia/one-brandkey';
import {
  AutocompleteItem,
  LoadingButton,
  NotificationTypeEnum,
  SearchBar,
  SearchBarVariant,
  SearchParams,
  SimpleSelect,
  isDefined,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { RawDraftContentState } from 'draft-js';
import { FC, useState } from 'react';
import {
  AssignmentOrderingInput,
  GetAssignmentsForDuplication_assignments,
  PrintIssueOrderingInput,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';
import { BrandKey } from '../../../__generated__/queries-web';
import { ArticleState } from '../reducer';
import {
  useAssignmentsGetter,
  useCreateSubject,
  useGetPrintHeadings,
  useGetPrintIssues,
  useGetPrintPublications,
  useUnitsGetter,
  useUpdateAssignment,
} from './duplicatePrint.topic.graphql';
import {
  DUPLICATE_TO_TOPIC_MACHINE_TAG,
  useCreateMachineTag,
} from './duplicatePrint.web.graphql';

interface NewAssignment {
  publication?: string;
  issue?: string;
  heading?: string;
}

export const DuplicatePrintDialog: FC<{
  articleState: ArticleState;
}> = ({ articleState }) => {
  const { closeDialog } = useDialog();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [createMode, setCreateMode] = useState(false);
  const [
    selectedAssignment,
    setSelectedAssignment,
  ] = useState<AutocompleteItem | null>(null);
  const [correctionLoading, setCorrectionLoading] = useState(false);
  const [mockupLoading, setMockupLoading] = useState(false);
  const [newAssignment, setNewAssignment] = useState<NewAssignment>({});
  const getAssignments = useAssignmentsGetter();
  const createSubject = useCreateSubject();
  const updateAssignment = useUpdateAssignment();
  const getUnits = useUnitsGetter();
  const createMachineTag = useCreateMachineTag();
  const { pushNotification } = useNotification();

  const unit = Object.entries(brandKeysByUnit).find(([, brandKeys]) =>
    (brandKeys as BrandKey[]).includes(articleState.brandKey),
  )?.[0];

  const {
    data: printPublicationsData,
    error: printPublicationError,
  } = useGetPrintPublications({
    first: 100,
    where: { brand: { unit: { title: unit } } },
  });

  const { data: printIssuesData, error: printIssueError } = useGetPrintIssues({
    first: 100,
    orderBy: [PrintIssueOrderingInput.title_ASC],
    where: {
      printPublication: { id: newAssignment.publication },
    },
  });

  const {
    data: printHeadingsData,
    error: printHeadingError,
  } = useGetPrintHeadings({
    first: 100,
    where: {
      printIssue: { id: newAssignment.issue },
    },
  });

  const fetchAutocompleteList = async (search: string) => {
    const data = await getAssignments({
      first: 50,
      orderBy: [AssignmentOrderingInput.createdAt_DESC],
      where: {
        subject: {
          title_contains: search,
          unit: { title: unit },
        },
        rawArticle_is_null: true,
      },
    });

    const autoCompleteItems:
      | AutocompleteItem[]
      | undefined = data?.assignments.map((assignment) => {
      const { printHeading } = assignment;

      return {
        id: assignment.id,
        label: assignment.subject.title,
        secondaryLabel: [
          printHeading?.printIssue.printPublication.title,
          printHeading?.printIssue.title,
          printHeading?.title,
        ]
          .filter(isDefined)
          .join(' • '),
        selected: selectedAssignment?.id === assignment.id,
        additionnals: { assignment },
      };
    });

    return autoCompleteItems;
  };

  const createAMachineTag = async (topicAssignmentId: string | undefined) => {
    if (!topicAssignmentId) return;

    await createMachineTag({
      data: {
        tag: `${DUPLICATE_TO_TOPIC_MACHINE_TAG}=${topicAssignmentId}`,
        articleMachineTags: {
          create: [
            {
              article: { connect: { id: articleState.id } },
              order: articleState.articleMachineTags.length,
            },
          ],
        },
      },
    });
  };

  const cleanDraft = (draftState: RawDraftContentState | null) => {
    if (!draftState) return null;

    return {
      blocks: draftState.blocks
        .filter(({ type }) => type !== 'atomic')
        .map((block) => ({
          ...block,
          entityRanges: [],
        })),
      entityMap: {},
    };
  };

  const duplicateToPrint = async (articleStatus: RawArticleStatus) => {
    try {
      const rawArticleCreate = {
        versions: {
          create: [
            {
              title: articleState.title,
              lead: JSON.stringify(cleanDraft(articleState.lead)),
              body: JSON.stringify(cleanDraft(articleState.body)),
              status: articleStatus,
            },
          ],
        },
      };

      if (createMode) {
        if (!articleState.title) return;

        const unitData = await getUnits({
          where: { title: unit },
          first: 1,
        });

        const subjectTitle = articleState.title.replace(
          /[^A-Za-z0-9áàâäãåéèêëíìîïñóòôöõúùûüÂÊÁËÈÍÎÏÌÓÔŒœ'()+,-.!_ ]/g,
          ' ',
        );

        const createData = await createSubject({
          data: {
            title: subjectTitle,
            unit: { connect: { id: unitData.units[0]?.id } },
            assignments: {
              create: [
                {
                  printHeading: {
                    connect: { id: newAssignment.heading },
                  },
                  rawArticle: {
                    create: rawArticleCreate,
                  },
                },
              ],
            },
          },
        }).catch((error) => {
          if (error.message.startsWith('Duplicate "Subject" entry')) {
            throw new Error(
              `Duplication impossible, un sujet intitulé "${subjectTitle}" existe déjà sur le print`,
            );
          }
          throw error;
        });

        await createAMachineTag(
          createData.data?.createSubject?.assignments[0].id,
        );
      } else {
        const selectedAssignmentData = selectedAssignment?.additionnals
          ?.assignment as GetAssignmentsForDuplication_assignments;
        if (!selectedAssignmentData) return;

        const updateData = await updateAssignment({
          where: { id: selectedAssignmentData.id },
          data: {
            rawArticle: { create: rawArticleCreate },
          },
        });

        await createAMachineTag(updateData.data?.updateAssignment?.id);
      }

      pushNotification({
        message: `L’article web "${articleState.title}" a été dupliqué sur le print`,
        type: NotificationTypeEnum.success,
      });

      closeDialog();
    } catch (e) {
      pushNotification({
        message: (e as Error).message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  return (
    <>
      <DialogTitle>Duplication print</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {createMode ? (
          <>
            <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', mb: 3 }}>
              <Box component="span" sx={{ color: theme.palette.primary.main }}>
                Créer un nouveau sujet
              </Box>{' '}
              pour le magazine
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <SimpleSelect
                label="Magazine"
                options={
                  printPublicationsData?.printPublications.map(
                    ({ id, title }) => ({
                      value: id,
                      label: title,
                    }),
                  ) || []
                }
                value={newAssignment.publication}
                sx={{ flex: 1 }}
                onChange={(_, publication) => setNewAssignment({ publication })}
              />
              <SimpleSelect
                label="Numéro de parution"
                options={
                  // eslint-disable-next-line fp/no-mutating-methods
                  [...(printIssuesData?.printIssues || [])]
                    .sort(({ title: titleA }, { title: titleB }) => {
                      if (isNaN(parseInt(titleA)) && !isNaN(parseInt(titleB))) {
                        return -1;
                      }
                      if (!isNaN(parseInt(titleA)) && isNaN(parseInt(titleB))) {
                        return 1;
                      }
                      if (
                        !isNaN(parseInt(titleA)) &&
                        !isNaN(parseInt(titleB))
                      ) {
                        return parseInt(titleA) < parseInt(titleB) ? 1 : -1;
                      }
                      return 0;
                    })
                    .map(({ id, title }) => ({
                      value: id,
                      label: title,
                    })) || []
                }
                value={newAssignment.issue}
                disabled={!newAssignment.publication}
                sx={{ flex: 1, mx: 3 }}
                onChange={(_, issue) =>
                  setNewAssignment((prev) => ({
                    publication: prev.publication,
                    issue,
                  }))
                }
              />
              <SimpleSelect
                label="Têtière"
                options={
                  printHeadingsData?.printHeadings.map(({ id, title }) => ({
                    value: id,
                    label: title,
                  })) || []
                }
                value={newAssignment.heading}
                disabled={!newAssignment.publication || !newAssignment.issue}
                sx={{ flex: 1 }}
                onChange={(_, heading) =>
                  setNewAssignment((prev) => ({ ...prev, heading }))
                }
              />
            </Box>
            {(printPublicationError ||
              printIssueError ||
              printHeadingError) && (
              <Typography color="error" sx={{ mt: 2 }}>
                {printPublicationError || printIssueError || printHeadingError}
              </Typography>
            )}
          </>
        ) : (
          <>
            <SearchBar
              variant={SearchBarVariant.TERTIARY}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              placeholder="Rechercher un sujet existant"
              autocomplete={{
                fetchList: fetchAutocompleteList,
                onSelect: (selected) => {
                  setSelectedAssignment(selected);
                  setSearchParams({});
                },
              }}
            />
            {selectedAssignment ? (
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pr: 1,
                  pl: 2,
                  py: 1,
                  mt: 1,
                }}
              >
                <Description
                  sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                    color: theme.palette.primary.main,
                    mr: 2,
                  }}
                />
                <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                  {selectedAssignment?.label}
                </Typography>
                <Typography sx={{ flexShrink: 1 }}>
                  {selectedAssignment?.secondaryLabel}
                </Typography>
                <IconButton
                  sx={{ ml: 2 }}
                  onClick={() => setSelectedAssignment(null)}
                >
                  <Close />
                </IconButton>
              </Card>
            ) : (
              <>
                <Divider sx={{ my: 2 }}>ou</Divider>
                <Button
                  variant="contained"
                  onClick={() => setCreateMode(true)}
                  sx={{ alignSelf: 'center' }}
                >
                  Créer un nouveau sujet
                </Button>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Annuler</Button>
        <LoadingButton
          variant="contained"
          disabled={
            mockupLoading || createMode
              ? !newAssignment.publication ||
                !newAssignment.heading ||
                !newAssignment.issue
              : !selectedAssignment
          }
          loading={correctionLoading}
          onClick={async () => {
            setCorrectionLoading(true);
            await duplicateToPrint(RawArticleStatus.Validation);
            setCorrectionLoading(false);
          }}
        >
          À valider
        </LoadingButton>
        <LoadingButton
          variant="contained"
          disabled={
            correctionLoading || createMode
              ? !newAssignment.publication ||
                !newAssignment.heading ||
                !newAssignment.issue
              : !selectedAssignment
          }
          loading={mockupLoading}
          onClick={async () => {
            setMockupLoading(true);
            await duplicateToPrint(RawArticleStatus.Mockup);
            setMockupLoading(false);
          }}
        >
          À maquetter
        </LoadingButton>
      </DialogActions>
    </>
  );
};
