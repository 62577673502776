import { gql, useQuery } from '@apollo/client';
import {
  GetGoogleGroup,
  GetGoogleGroupVariables,
} from '../../../__generated__/queries-auth';

export const GET_GOOGLE_GROUP = gql`
  query GetGoogleGroup($where: GoogleGroupUniqueFilterInput!) {
    googleGroup(where: $where) {
      id
      groupTitle
      role {
        id
        slug
      }
    }
  }
`;

export const useGetGoogleGroup = (id?: string, skip?: boolean) =>
  useQuery<GetGoogleGroup, GetGoogleGroupVariables>(GET_GOOGLE_GROUP, {
    variables: { where: { id } },
    skip: !id || skip,
  });
