import { FetchResult } from '@apollo/client';
import { FieldValidator } from '@prismamedia/one-components';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import {
  CreateUser,
  GetUser_user,
  UpdateUser,
  UserCreationInput,
  UserType,
  UserUpdateInput,
} from '../../../__generated__/queries-auth';

export const emptyItem: GetUser_user = {
  __typename: 'User',
  id: '',
  name: '',
  createdAt: '',
  email: '',
  avatarUrl: null,
  roles: [],
};

export const saveMandatoryFields: FieldValidator<GetUser_user>[] = [
  {
    label: 'Nom',
    validate: ({ name }) => !!name,
  },
  {
    label: 'Email',
    validate: ({ email }) => !!email,
  },
  {
    label: "Rôle de l'utilisateur",
    // TO DO REWORK ROLES
    validate: ({ roles }) => !!roles[0],
  },
];

export const onSave = async (
  item: GetUser_user | undefined,
  isCreate: boolean,
  history: any,
  setSaveLoading: (loading: boolean) => void,
  createItem: (
    data: UserCreationInput,
  ) => Promise<
    FetchResult<CreateUser, Record<string, any>, Record<string, any>>
  >,
  updateItem: (
    id: string,
    data: UserUpdateInput,
  ) => Promise<
    FetchResult<UpdateUser, Record<string, any>, Record<string, any>>
  >,
) => {
  if (item) {
    setSaveLoading(true);
    const res = isCreate
      ? await createItem({
          name: item.name,
          email: item.email,
          type: UserType.User,
        })
      : await updateItem(item.id, {
          name: item.name,
          email: item.email,
        });
    setSaveLoading(false);
    if (res && isCreate) {
      history.replace(
        replaceParams(paths.USER_EDIT, {
          id: (res.data as CreateUser).createUser.id,
        }),
      );
    }
  }
};
