/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPhotos
// ====================================================

export interface GetPhotos_photos_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPhotos_photos_photoMetadatas_metadata {
  __typename: "Metadata";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: MetadataNameEnumType;
}

export interface GetPhotos_photos_photoMetadatas {
  __typename: "PhotoMetadata";
  /**
   * The ID
   */
  id: number;
  value: string | null;
  metadata: GetPhotos_photos_photoMetadatas_metadata;
}

export interface GetPhotos_photos_previews {
  __typename: "Preview";
  size: number | null;
  href: string | null;
}

export interface GetPhotos_photos {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: GetPhotos_photos_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  isUploaded: boolean | null;
  isLegacy: boolean | null;
  originalName: string | null;
  /**
   * get an expirable public download URL
   */
  publicUrl: string | null;
  /**
   * "PhotoMetadata.photo"'s inverse relation
   */
  photoMetadatas: GetPhotos_photos_photoMetadatas[];
  /**
   * get all supported resized previews
   */
  previews: GetPhotos_photos_previews[] | null;
}

export interface GetPhotos {
  /**
   * optimized for speed photos count query
   */
  photoCount: number;
  /**
   * optimized for speed search photos query
   */
  photos: GetPhotos_photos[];
}

export interface GetPhotosVariables {
  where?: PhotoWhereInput | null;
  first: number;
  orderBy?: PhotoOrderByInput[] | null;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Photo
// ====================================================

export interface Photo_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface Photo_photoMetadatas_metadata {
  __typename: "Metadata";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: MetadataNameEnumType;
}

export interface Photo_photoMetadatas {
  __typename: "PhotoMetadata";
  /**
   * The ID
   */
  id: number;
  value: string | null;
  metadata: Photo_photoMetadatas_metadata;
}

export interface Photo_previews {
  __typename: "Preview";
  size: number | null;
  href: string | null;
}

export interface Photo {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: Photo_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  isUploaded: boolean | null;
  isLegacy: boolean | null;
  originalName: string | null;
  /**
   * get an expirable public download URL
   */
  publicUrl: string | null;
  /**
   * "PhotoMetadata.photo"'s inverse relation
   */
  photoMetadatas: Photo_photoMetadatas[];
  /**
   * get all supported resized previews
   */
  previews: Photo_previews[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArchiveKeyType {
  CAM_LIVRAISON = "CAM_LIVRAISON",
  CAM_SUJETS = "CAM_SUJETS",
  CBG_SUJETS = "CBG_SUJETS",
  CLE_SUJETS = "CLE_SUJETS",
  CTM_ARCHIVES = "CTM_ARCHIVES",
  CTM_SUJETS = "CTM_SUJETS",
  FAP_BEAUTE_ARCHIVES = "FAP_BEAUTE_ARCHIVES",
  FAP_CUISINE_ARCHIVES = "FAP_CUISINE_ARCHIVES",
  FAP_DECO_ARCHIVES = "FAP_DECO_ARCHIVES",
  FATV_SUJETS = "FATV_SUJETS",
  GALA_LIVRAISON = "GALA_LIVRAISON",
  GAL_PEOPLE = "GAL_PEOPLE",
  GEO_ARCHIVES = "GEO_ARCHIVES",
  HBZ_ARCHIVE = "HBZ_ARCHIVE",
  HBZ_LIVRAISON = "HBZ_LIVRAISON",
  HBZ_SUJETS = "HBZ_SUJETS",
  MAD_SUJETS = "MAD_SUJETS",
  NEON_SUJETS = "NEON_SUJETS",
  NGE_ARCHIVE = "NGE_ARCHIVE",
  NGE_SUJETS = "NGE_SUJETS",
  NR_PERSONNALITES_ARCHIVES = "NR_PERSONNALITES_ARCHIVES",
  PECO_LIVRAISON = "PECO_LIVRAISON",
  PECO_SUJETS = "PECO_SUJETS",
  PECO_WEB = "PECO_WEB",
  PFEM_LIVRAISON = "PFEM_LIVRAISON",
  PFEM_SUJETS = "PFEM_SUJETS",
  PGAL_SUJETS = "PGAL_SUJETS",
  PGEO_SUJETS = "PGEO_SUJETS",
  PTVS_ARCHIVES_TV = "PTVS_ARCHIVES_TV",
  PTVS_LIVRAISON = "PTVS_LIVRAISON",
  PTVS_SUJETS = "PTVS_SUJETS",
  TEST_UNIT = "TEST_UNIT",
  TRV_CUI = "TRV_CUI",
  TRV_PHOTOS = "TRV_PHOTOS",
  TRV_WEB_GRAPHIX = "TRV_WEB_GRAPHIX",
  TVS_SUJETS = "TVS_SUJETS",
  VOICI_LIVRAISON = "VOICI_LIVRAISON",
  VOICI_SUJETS = "VOICI_SUJETS",
  VOI_ARCHIVES = "VOI_ARCHIVES",
  VOI_BASE_PEOPLE = "VOI_BASE_PEOPLE",
  VOI_BEAUTE = "VOI_BEAUTE",
  VOI_MODE = "VOI_MODE",
  VOI_SCOOP = "VOI_SCOOP",
}

export enum ArchiveType {
  ARCHIVE = "ARCHIVE",
  DELIVERY = "DELIVERY",
  NEWS = "NEWS",
  SUJET = "SUJET",
}

export enum ImportQueueStatusType {
  DELETED = "DELETED",
  FAILED = "FAILED",
  FINISHED = "FINISHED",
  PENDING = "PENDING",
}

export enum MetadataNameEnumType {
  AccessRights = "AccessRights",
  AddlModelInfo = "AddlModelInfo",
  Archive_child = "Archive_child",
  Archive_parent = "Archive_parent",
  AssetState = "AssetState",
  Audience = "Audience",
  AuthorsPosition = "AuthorsPosition",
  BackupName = "BackupName",
  BitmapGrayscalePicture = "BitmapGrayscalePicture",
  Brand = "Brand",
  BrandPrisma = "BrandPrisma",
  BrandShortName = "BrandShortName",
  CaptionWriter = "CaptionWriter",
  Category = "Category",
  CategoryPrisma = "CategoryPrisma",
  Certificate = "Certificate",
  CiAdrCity = "CiAdrCity",
  CiAdrCtry = "CiAdrCtry",
  CiAdrExtadr = "CiAdrExtadr",
  CiAdrPcode = "CiAdrPcode",
  CiAdrRegion = "CiAdrRegion",
  CiEmailWork = "CiEmailWork",
  CiTelWork = "CiTelWork",
  CiUrlWork = "CiUrlWork",
  City = "City",
  Classify = "Classify",
  Company = "Company",
  CompanyShortName = "CompanyShortName",
  Confidentiality = "Confidentiality",
  ContainedIn = "ContainedIn",
  ContentMediaKind = "ContentMediaKind",
  ContentValue = "ContentValue",
  CopyrightLayer = "CopyrightLayer",
  Country = "Country",
  CountryCode = "CountryCode",
  CreatedTime = "CreatedTime",
  CreatorTool = "CreatorTool",
  Credit = "Credit",
  CustomField14 = "CustomField14",
  CustomField15 = "CustomField15",
  CustomField16 = "CustomField16",
  CustomField17 = "CustomField17",
  CustomField20 = "CustomField20",
  CustomField5 = "CustomField5",
  CustomField6 = "CustomField6",
  CustomField7 = "CustomField7",
  CustomField9 = "CustomField9",
  DateCreated = "DateCreated",
  DateTimeDigitized = "DateTimeDigitized",
  DateTimeOriginal = "DateTimeOriginal",
  DeliveryAccount = "DeliveryAccount",
  DeliveryCompany = "DeliveryCompany",
  DeliveryCopyright = "DeliveryCopyright",
  DeliveryDateFolder = "DeliveryDateFolder",
  DeliveryDateTime = "DeliveryDateTime",
  DeliveryDcCreator = "DeliveryDcCreator",
  DeliveryDcRights = "DeliveryDcRights",
  DeliveryFileType = "DeliveryFileType",
  DeliveryFolder = "DeliveryFolder",
  DeliveryKind = "DeliveryKind",
  DeliveryPath = "DeliveryPath",
  DeliveryPersonShownintheImage = "DeliveryPersonShownintheImage",
  DeliveryPhotoshopCredit = "DeliveryPhotoshopCredit",
  DeliveryPhotoshopSource = "DeliveryPhotoshopSource",
  DeliveryService = "DeliveryService",
  DeliverySubject = "DeliverySubject",
  DeliveryUnderSubject = "DeliveryUnderSubject",
  Department = "Department",
  DigitalAssetURL = "DigitalAssetURL",
  Division = "Division",
  DocumentText = "DocumentText",
  EditStatus = "EditStatus",
  Edition = "Edition",
  EditorialVersion = "EditorialVersion",
  EnvironnementPhoto = "EnvironnementPhoto",
  EquipmentInstitution = "EquipmentInstitution",
  EquipmentManufacturer = "EquipmentManufacturer",
  Event = "Event",
  Exif = "Exif",
  FabStorage = "FabStorage",
  FileCheckFor = "FileCheckFor",
  FileInfoBitsPerPixel = "FileInfoBitsPerPixel",
  FileInfoFrames = "FileInfoFrames",
  FileInfoPhotometricInterpretation = "FileInfoPhotometricInterpretation",
  FileInfoTransferSyntax = "FileInfoTransferSyntax",
  FileStage = "FileStage",
  FileStatus = "FileStatus",
  FileType = "FileType",
  FixtureIdentifier = "FixtureIdentifier",
  Format = "Format",
  GPSLatitude = "GPSLatitude",
  GPSLongitude = "GPSLongitude",
  GPSProcessingMethod = "GPSProcessingMethod",
  Header = "Header",
  HeaderPrisma = "HeaderPrisma",
  Headline = "Headline",
  History = "History",
  ICCProfile = "ICCProfile",
  ID_subject = "ID_subject",
  ISOSpeedRatings = "ISOSpeedRatings",
  IdAssignment = "IdAssignment",
  IdMediaContent = "IdMediaContent",
  IdPhotoPrisma = "IdPhotoPrisma",
  ImageNotes = "ImageNotes",
  ImageUniqueID = "ImageUniqueID",
  Instructions = "Instructions",
  IntellectualGenre = "IntellectualGenre",
  Issue = "Issue",
  JobId = "JobId",
  JobProcessing = "JobProcessing",
  LayoutPictureEditing = "LayoutPictureEditing",
  LayoutStorage = "LayoutStorage",
  Links = "Links",
  LocalCaption = "LocalCaption",
  Location = "Location",
  Make = "Make",
  Manifest = "Manifest",
  Marked = "Marked",
  MasterDocumentId = "MasterDocumentId",
  MaxAvailHeight = "MaxAvailHeight",
  MaxAvailWidth = "MaxAvailWidth",
  MinorModelAgeDisclosure = "MinorModelAgeDisclosure",
  Model = "Model",
  ModelAge = "ModelAge",
  ModelReleaseID = "ModelReleaseID",
  ModelReleaseStatus = "ModelReleaseStatus",
  ObjectCycle = "ObjectCycle",
  OrganisationInImageName = "OrganisationInImageName",
  OriginalColor = "OriginalColor",
  OriginalICCProfile = "OriginalICCProfile",
  OriginalName = "OriginalName",
  OriginalWeigth = "OriginalWeigth",
  Owner = "Owner",
  OwnerId = "OwnerId",
  Page = "Page",
  PagePrisma = "PagePrisma",
  PaintBasedCorrections = "PaintBasedCorrections",
  PatientDOB = "PatientDOB",
  PatientID = "PatientID",
  PatientName = "PatientName",
  PatientSex = "PatientSex",
  PersonInImage = "PersonInImage",
  PhotoStorage = "PhotoStorage",
  PrepressPictureEditing = "PrepressPictureEditing",
  PriceLevel = "PriceLevel",
  PrintingProfile = "PrintingProfile",
  PrismaProduction = "PrismaProduction",
  ProcessHistory = "ProcessHistory",
  ProcessParameter = "ProcessParameter",
  ProcessStatus = "ProcessStatus",
  Product = "Product",
  ProductShortName = "ProductShortName",
  Products = "Products",
  Produits = "Produits",
  ProgramVersion = "ProgramVersion",
  PropertyReleaseID = "PropertyReleaseID",
  PropertyReleaseStatus = "PropertyReleaseStatus",
  Publisher = "Publisher",
  QualifiedUsePrismaBy = "QualifiedUsePrismaBy",
  QualifiedUsePrismaDate = "QualifiedUsePrismaDate",
  QualifiedUsePrismaDuration = "QualifiedUsePrismaDuration",
  QualifiedUsePrismaSupport = "QualifiedUsePrismaSupport",
  Rating = "Rating",
  ReferenceDate = "ReferenceDate",
  ReferenceNumber = "ReferenceNumber",
  ReferenceService = "ReferenceService",
  ReleaseDate = "ReleaseDate",
  ReleaseTime = "ReleaseTime",
  RequiredPermission = "RequiredPermission",
  ResolutionKind = "ResolutionKind",
  Royaltyfree = "Royaltyfree",
  Scene = "Scene",
  Section = "Section",
  SentTo = "SentTo",
  SerialNumber = "SerialNumber",
  SeriesDateTime = "SeriesDateTime",
  SeriesDescription = "SeriesDescription",
  SeriesModality = "SeriesModality",
  SeriesNumber = "SeriesNumber",
  ShortUniqueId = "ShortUniqueId",
  Source = "Source",
  State = "State",
  StudyDateTime = "StudyDateTime",
  StudyDescription = "StudyDescription",
  StudyID = "StudyID",
  StudyPhysician = "StudyPhysician",
  SubjectCode = "SubjectCode",
  SubjectPrisma = "SubjectPrisma",
  SupplementalCategories = "SupplementalCategories",
  TargetVersion = "TargetVersion",
  ToneCurve = "ToneCurve",
  TransferredBy = "TransferredBy",
  TransferredByEmail = "TransferredByEmail",
  TransferredByFullName = "TransferredByFullName",
  TransmissionReference = "TransmissionReference",
  Type = "Type",
  UnderUnderSubject = "UnderUnderSubject",
  Undersubject = "Undersubject",
  UniqueId = "UniqueId",
  Unit = "Unit",
  UnitShortName = "UnitShortName",
  UploadTime = "UploadTime",
  UploadedBy = "UploadedBy",
  UploadedByFullName = "UploadedByFullName",
  Urgency = "Urgency",
  UsageTerms = "UsageTerms",
  UserComment = "UserComment",
  UserDefined195 = "UserDefined195",
  UserDefined237 = "UserDefined237",
  UserDefined238 = "UserDefined238",
  UserDefined239 = "UserDefined239",
  UserDefined242 = "UserDefined242",
  UserDefined62 = "UserDefined62",
  Valid = "Valid",
  Version = "Version",
  WebStatement = "WebStatement",
  WorkflowKind = "WorkflowKind",
  XMPFileStamps = "XMPFileStamps",
  XMPHistory = "XMPHistory",
  attributionName = "attributionName",
  attributionURL = "attributionURL",
  creator = "creator",
  description = "description",
  license = "license",
  morePermissions = "morePermissions",
  publishingIssue = "publishingIssue",
  publishingSubject = "publishingSubject",
  rights = "rights",
  subject = "subject",
  title = "title",
}

export enum MetadataValueTypeEnum {
  Date = "Date",
  List = "List",
  Text = "Text",
}

export enum PhotoOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  contentType_ASC = "contentType_ASC",
  contentType_DESC = "contentType_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  filesize_ASC = "filesize_ASC",
  filesize_DESC = "filesize_DESC",
  height_ASC = "height_ASC",
  height_DESC = "height_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  importedAt_ASC = "importedAt_ASC",
  importedAt_DESC = "importedAt_DESC",
  isLegacy_ASC = "isLegacy_ASC",
  isLegacy_DESC = "isLegacy_DESC",
  isUploaded_ASC = "isUploaded_ASC",
  isUploaded_DESC = "isUploaded_DESC",
  originalName_ASC = "originalName_ASC",
  originalName_DESC = "originalName_DESC",
  synced_ASC = "synced_ASC",
  synced_DESC = "synced_DESC",
  takenAt_ASC = "takenAt_ASC",
  takenAt_DESC = "takenAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  width_ASC = "width_ASC",
  width_DESC = "width_DESC",
}

/**
 * Filters the "Agency" nodes by specifying some conditions
 */
export interface AgencyWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archiveAgencies_some?: ArchiveAgencyWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  AND?: AgencyWhereInput[] | null;
  OR?: AgencyWhereInput[] | null;
  NOT?: AgencyWhereInput | null;
}

/**
 * Filters the "ArchiveAgency" nodes by specifying some conditions
 */
export interface ArchiveAgencyWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  agency?: AgencyWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: ArchiveAgencyWhereInput[] | null;
  OR?: ArchiveAgencyWhereInput[] | null;
  NOT?: ArchiveAgencyWhereInput | null;
}

/**
 * Filters the "Archive" nodes by specifying some conditions
 */
export interface ArchiveWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  archiveId?: number | null;
  archiveId_not?: number | null;
  archiveId_is_null?: boolean | null;
  archiveId_in?: (number | null)[] | null;
  archiveId_not_in?: (number | null)[] | null;
  archiveId_lt?: number | null;
  archiveId_lte?: number | null;
  archiveId_gt?: number | null;
  archiveId_gte?: number | null;
  name?: ArchiveKeyType | null;
  name_not?: ArchiveKeyType | null;
  name_is_null?: boolean | null;
  name_in?: (ArchiveKeyType | null)[] | null;
  name_not_in?: (ArchiveKeyType | null)[] | null;
  label?: string | null;
  label_not?: string | null;
  label_is_null?: boolean | null;
  label_in?: (string | null)[] | null;
  label_not_in?: (string | null)[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  type?: ArchiveType | null;
  type_not?: ArchiveType | null;
  type_is_null?: boolean | null;
  type_in?: (ArchiveType | null)[] | null;
  type_not_in?: (ArchiveType | null)[] | null;
  hasCategories?: boolean | null;
  hasCategories_not?: boolean | null;
  hasCategories_is_null?: boolean | null;
  hasCategories_in?: (boolean | null)[] | null;
  hasCategories_not_in?: (boolean | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archiveAgencies_some?: ArchiveAgencyWhereInput | null;
  categories_some?: CategoryWhereInput | null;
  deliverySubjects_some?: DeliverySubjectWhereInput | null;
  gcImportSetting?: GcImportSettingWhereInput | null;
  gcImportSetting_is_null?: boolean | null;
  importQueues_some?: ImportQueueWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  photoMetadatas_some?: PhotoMetadataWhereInput | null;
  metadataSerialNumberList_some?: MetadataSerialNumberWhereInput | null;
  metadataAttributionNameList_some?: MetadataAttributionNameWhereInput | null;
  metadataAttributionUrlList_some?: MetadataAttributionUrlWhereInput | null;
  metadataLicenseList_some?: MetadataLicenseWhereInput | null;
  metadataMorePermissionsList_some?: MetadataMorePermissionsWhereInput | null;
  metadataPaintBasedCorrectionsList_some?: MetadataPaintBasedCorrectionsWhereInput | null;
  metadataToneCurveList_some?: MetadataToneCurveWhereInput | null;
  metadataCreatorList_some?: MetadataCreatorWhereInput | null;
  metadataDescriptionList_some?: MetadataDescriptionWhereInput | null;
  metadataFormatList_some?: MetadataFormatWhereInput | null;
  metadataPageList_some?: MetadataPageWhereInput | null;
  metadataPublisherList_some?: MetadataPublisherWhereInput | null;
  metadataRightsList_some?: MetadataRightsWhereInput | null;
  metadataSubjectList_some?: MetadataSubjectWhereInput | null;
  metadataTitleList_some?: MetadataTitleWhereInput | null;
  metadataTypeList_some?: MetadataTypeWhereInput | null;
  metadataAccessRightsList_some?: MetadataAccessRightsWhereInput | null;
  metadataAudienceList_some?: MetadataAudienceWhereInput | null;
  metadataValidList_some?: MetadataValidWhereInput | null;
  metadataEquipmentInstitutionList_some?: MetadataEquipmentInstitutionWhereInput | null;
  metadataEquipmentManufacturerList_some?: MetadataEquipmentManufacturerWhereInput | null;
  metadataFileInfoBitsPerPixelList_some?: MetadataFileInfoBitsPerPixelWhereInput | null;
  metadataFileInfoFramesList_some?: MetadataFileInfoFramesWhereInput | null;
  metadataFileInfoPhotometricInterpretationList_some?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
  metadataFileInfoTransferSyntaxList_some?: MetadataFileInfoTransferSyntaxWhereInput | null;
  metadataPatientDobList_some?: MetadataPatientDobWhereInput | null;
  metadataPatientIdList_some?: MetadataPatientIdWhereInput | null;
  metadataPatientNameList_some?: MetadataPatientNameWhereInput | null;
  metadataPatientSexList_some?: MetadataPatientSexWhereInput | null;
  metadataSeriesDateTimeList_some?: MetadataSeriesDateTimeWhereInput | null;
  metadataSeriesDescriptionList_some?: MetadataSeriesDescriptionWhereInput | null;
  metadataSeriesModalityList_some?: MetadataSeriesModalityWhereInput | null;
  metadataSeriesNumberList_some?: MetadataSeriesNumberWhereInput | null;
  metadataStudyDateTimeList_some?: MetadataStudyDateTimeWhereInput | null;
  metadataStudyDescriptionList_some?: MetadataStudyDescriptionWhereInput | null;
  metadataStudyIdList_some?: MetadataStudyIdWhereInput | null;
  metadataStudyPhysicianList_some?: MetadataStudyPhysicianWhereInput | null;
  metadataDateTimeDigitizedList_some?: MetadataDateTimeDigitizedWhereInput | null;
  metadataDateTimeOriginalList_some?: MetadataDateTimeOriginalWhereInput | null;
  metadataGpsLatitudeList_some?: MetadataGpsLatitudeWhereInput | null;
  metadataGpsLongitudeList_some?: MetadataGpsLongitudeWhereInput | null;
  metadataGpsProcessingMethodList_some?: MetadataGpsProcessingMethodWhereInput | null;
  metadataImageUniqueIdList_some?: MetadataImageUniqueIdWhereInput | null;
  metadataIsoSpeedRatingsList_some?: MetadataIsoSpeedRatingsWhereInput | null;
  metadataUserCommentList_some?: MetadataUserCommentWhereInput | null;
  metadataCustomField14List_some?: MetadataCustomField14WhereInput | null;
  metadataCustomField15List_some?: MetadataCustomField15WhereInput | null;
  metadataCustomField16List_some?: MetadataCustomField16WhereInput | null;
  metadataCustomField17List_some?: MetadataCustomField17WhereInput | null;
  metadataCustomField20List_some?: MetadataCustomField20WhereInput | null;
  metadataCustomField5List_some?: MetadataCustomField5WhereInput | null;
  metadataCustomField6List_some?: MetadataCustomField6WhereInput | null;
  metadataCustomField7List_some?: MetadataCustomField7WhereInput | null;
  metadataCustomField9List_some?: MetadataCustomField9WhereInput | null;
  metadataCreatedTimeList_some?: MetadataCreatedTimeWhereInput | null;
  metadataEditStatusList_some?: MetadataEditStatusWhereInput | null;
  metadataFixtureIdentifierList_some?: MetadataFixtureIdentifierWhereInput | null;
  metadataLocalCaptionList_some?: MetadataLocalCaptionWhereInput | null;
  metadataProgramVersionList_some?: MetadataProgramVersionWhereInput | null;
  metadataReferenceDateList_some?: MetadataReferenceDateWhereInput | null;
  metadataReferenceNumberList_some?: MetadataReferenceNumberWhereInput | null;
  metadataReferenceServiceList_some?: MetadataReferenceServiceWhereInput | null;
  metadataReleaseDateList_some?: MetadataReleaseDateWhereInput | null;
  metadataReleaseTimeList_some?: MetadataReleaseTimeWhereInput | null;
  metadataAssetStateList_some?: MetadataAssetStateWhereInput | null;
  metadataClassifyList_some?: MetadataClassifyWhereInput | null;
  metadataContainedInList_some?: MetadataContainedInWhereInput | null;
  metadataContentValueList_some?: MetadataContentValueWhereInput | null;
  metadataDocumentTextList_some?: MetadataDocumentTextWhereInput | null;
  metadataExifList_some?: MetadataExifWhereInput | null;
  metadataHistoryList_some?: MetadataHistoryWhereInput | null;
  metadataImageNotesList_some?: MetadataImageNotesWhereInput | null;
  metadataJobIdList_some?: MetadataJobIdWhereInput | null;
  metadataLinksList_some?: MetadataLinksWhereInput | null;
  metadataMasterDocumentIdList_some?: MetadataMasterDocumentIdWhereInput | null;
  metadataObjectCycleList_some?: MetadataObjectCycleWhereInput | null;
  metadataOwnerIdList_some?: MetadataOwnerIdWhereInput | null;
  metadataShortUniqueIdList_some?: MetadataShortUniqueIdWhereInput | null;
  metadataTransferredByList_some?: MetadataTransferredByWhereInput | null;
  metadataTransferredByEmailList_some?: MetadataTransferredByEmailWhereInput | null;
  metadataTransferredByFullNameList_some?: MetadataTransferredByFullNameWhereInput | null;
  metadataUniqueIdList_some?: MetadataUniqueIdWhereInput | null;
  metadataUploadedByList_some?: MetadataUploadedByWhereInput | null;
  metadataUploadedByFullNameList_some?: MetadataUploadedByFullNameWhereInput | null;
  metadataUploadTimeList_some?: MetadataUploadTimeWhereInput | null;
  metadataUserDefined195List_some?: MetadataUserDefined195WhereInput | null;
  metadataUserDefined237List_some?: MetadataUserDefined237WhereInput | null;
  metadataUserDefined238List_some?: MetadataUserDefined238WhereInput | null;
  metadataUserDefined239List_some?: MetadataUserDefined239WhereInput | null;
  metadataUserDefined242List_some?: MetadataUserDefined242WhereInput | null;
  metadataUserDefined62List_some?: MetadataUserDefined62WhereInput | null;
  metadataCiAdrCityList_some?: MetadataCiAdrCityWhereInput | null;
  metadataCiAdrCtryList_some?: MetadataCiAdrCtryWhereInput | null;
  metadataCiAdrExtadrList_some?: MetadataCiAdrExtadrWhereInput | null;
  metadataCiAdrPcodeList_some?: MetadataCiAdrPcodeWhereInput | null;
  metadataCiAdrRegionList_some?: MetadataCiAdrRegionWhereInput | null;
  metadataCiEmailWorkList_some?: MetadataCiEmailWorkWhereInput | null;
  metadataCiTelWorkList_some?: MetadataCiTelWorkWhereInput | null;
  metadataCiUrlWorkList_some?: MetadataCiUrlWorkWhereInput | null;
  metadataCountryCodeList_some?: MetadataCountryCodeWhereInput | null;
  metadataIntellectualGenreList_some?: MetadataIntellectualGenreWhereInput | null;
  metadataLocationList_some?: MetadataLocationWhereInput | null;
  metadataSceneList_some?: MetadataSceneWhereInput | null;
  metadataSubjectCodeList_some?: MetadataSubjectCodeWhereInput | null;
  metadataAddlModelInfoList_some?: MetadataAddlModelInfoWhereInput | null;
  metadataEventList_some?: MetadataEventWhereInput | null;
  metadataMaxAvailHeightList_some?: MetadataMaxAvailHeightWhereInput | null;
  metadataMaxAvailWidthList_some?: MetadataMaxAvailWidthWhereInput | null;
  metadataModelAgeList_some?: MetadataModelAgeWhereInput | null;
  metadataOrganisationInImageNameList_some?: MetadataOrganisationInImageNameWhereInput | null;
  metadataPersonInImageList_some?: MetadataPersonInImageWhereInput | null;
  metadataAuthorsPositionList_some?: MetadataAuthorsPositionWhereInput | null;
  metadataCaptionWriterList_some?: MetadataCaptionWriterWhereInput | null;
  metadataCategoryList_some?: MetadataCategoryWhereInput | null;
  metadataCityList_some?: MetadataCityWhereInput | null;
  metadataCountryList_some?: MetadataCountryWhereInput | null;
  metadataCreditList_some?: MetadataCreditWhereInput | null;
  metadataDateCreatedList_some?: MetadataDateCreatedWhereInput | null;
  metadataHeadlineList_some?: MetadataHeadlineWhereInput | null;
  metadataIccProfileList_some?: MetadataIccProfileWhereInput | null;
  metadataInstructionsList_some?: MetadataInstructionsWhereInput | null;
  metadataSourceList_some?: MetadataSourceWhereInput | null;
  metadataStateList_some?: MetadataStateWhereInput | null;
  metadataSupplementalCategoriesList_some?: MetadataSupplementalCategoriesWhereInput | null;
  metadataTransmissionReferenceList_some?: MetadataTransmissionReferenceWhereInput | null;
  metadataUrgencyList_some?: MetadataUrgencyWhereInput | null;
  metadataMinorModelAgeDisclosureList_some?: MetadataMinorModelAgeDisclosureWhereInput | null;
  metadataModelReleaseIdList_some?: MetadataModelReleaseIdWhereInput | null;
  metadataModelReleaseStatusList_some?: MetadataModelReleaseStatusWhereInput | null;
  metadataPropertyReleaseIdList_some?: MetadataPropertyReleaseIdWhereInput | null;
  metadataPropertyReleaseStatusList_some?: MetadataPropertyReleaseStatusWhereInput | null;
  metadataVersionList_some?: MetadataVersionWhereInput | null;
  metadataBrandList_some?: MetadataBrandWhereInput | null;
  metadataDivisionList_some?: MetadataDivisionWhereInput | null;
  metadataHeaderList_some?: MetadataHeaderWhereInput | null;
  metadataProductsList_some?: MetadataProductsWhereInput | null;
  metadataProduitsList_some?: MetadataProduitsWhereInput | null;
  metadataPublishingIssueList_some?: MetadataPublishingIssueWhereInput | null;
  metadataPublishingSubjectList_some?: MetadataPublishingSubjectWhereInput | null;
  metadataCompanyList_some?: MetadataCompanyWhereInput | null;
  metadataCompanyShortNameList_some?: MetadataCompanyShortNameWhereInput | null;
  metadataConfidentialityList_some?: MetadataConfidentialityWhereInput | null;
  metadataEditorialVersionList_some?: MetadataEditorialVersionWhereInput | null;
  metadataFileCheckForList_some?: MetadataFileCheckForWhereInput | null;
  metadataFileStageList_some?: MetadataFileStageWhereInput | null;
  metadataFileStatusList_some?: MetadataFileStatusWhereInput | null;
  metadataSentToList_some?: MetadataSentToWhereInput | null;
  metadataTargetVersionList_some?: MetadataTargetVersionWhereInput | null;
  metadataUnitList_some?: MetadataUnitWhereInput | null;
  metadataArchiveChildList_some?: MetadataArchiveChildWhereInput | null;
  metadataArchiveParentList_some?: MetadataArchiveParentWhereInput | null;
  metadataBackupNameList_some?: MetadataBackupNameWhereInput | null;
  metadataBitmapGrayscalePictureList_some?: MetadataBitmapGrayscalePictureWhereInput | null;
  metadataBrandPrismaList_some?: MetadataBrandPrismaWhereInput | null;
  metadataBrandShortNameList_some?: MetadataBrandShortNameWhereInput | null;
  metadataCategoryPrismaList_some?: MetadataCategoryPrismaWhereInput | null;
  metadataContentMediaKindList_some?: MetadataContentMediaKindWhereInput | null;
  metadataCopyrightLayerList_some?: MetadataCopyrightLayerWhereInput | null;
  metadataDeliveryAccountList_some?: MetadataDeliveryAccountWhereInput | null;
  metadataDeliveryCompanyList_some?: MetadataDeliveryCompanyWhereInput | null;
  metadataDeliveryCopyrightList_some?: MetadataDeliveryCopyrightWhereInput | null;
  metadataDeliveryDateFolderList_some?: MetadataDeliveryDateFolderWhereInput | null;
  metadataDeliveryDateTimeList_some?: MetadataDeliveryDateTimeWhereInput | null;
  metadataDeliveryDcCreatorList_some?: MetadataDeliveryDcCreatorWhereInput | null;
  metadataDeliveryDcRightsList_some?: MetadataDeliveryDcRightsWhereInput | null;
  metadataDeliveryFileTypeList_some?: MetadataDeliveryFileTypeWhereInput | null;
  metadataDeliveryFolderList_some?: MetadataDeliveryFolderWhereInput | null;
  metadataDeliveryKindList_some?: MetadataDeliveryKindWhereInput | null;
  metadataDeliveryPathList_some?: MetadataDeliveryPathWhereInput | null;
  metadataDeliveryPersonShownintheImageList_some?: MetadataDeliveryPersonShownintheImageWhereInput | null;
  metadataDeliveryPhotoshopCreditList_some?: MetadataDeliveryPhotoshopCreditWhereInput | null;
  metadataDeliveryPhotoshopSourceList_some?: MetadataDeliveryPhotoshopSourceWhereInput | null;
  metadataDeliveryServiceList_some?: MetadataDeliveryServiceWhereInput | null;
  metadataDeliverySubjectList_some?: MetadataDeliverySubjectWhereInput | null;
  metadataDeliveryUnderSubjectList_some?: MetadataDeliveryUnderSubjectWhereInput | null;
  metadataDepartmentList_some?: MetadataDepartmentWhereInput | null;
  metadataDigitalAssetUrlList_some?: MetadataDigitalAssetUrlWhereInput | null;
  metadataEditionList_some?: MetadataEditionWhereInput | null;
  metadataEnvironnementPhotoList_some?: MetadataEnvironnementPhotoWhereInput | null;
  metadataFabStorageList_some?: MetadataFabStorageWhereInput | null;
  metadataFileTypeList_some?: MetadataFileTypeWhereInput | null;
  metadataHeaderPrismaList_some?: MetadataHeaderPrismaWhereInput | null;
  metadataIdSubjectList_some?: MetadataIdSubjectWhereInput | null;
  metadataIdAssignmentList_some?: MetadataIdAssignmentWhereInput | null;
  metadataIdMediaContentList_some?: MetadataIdMediaContentWhereInput | null;
  metadataIdPhotoPrismaList_some?: MetadataIdPhotoPrismaWhereInput | null;
  metadataIssueList_some?: MetadataIssueWhereInput | null;
  metadataJobProcessingList_some?: MetadataJobProcessingWhereInput | null;
  metadataLayoutPictureEditingList_some?: MetadataLayoutPictureEditingWhereInput | null;
  metadataLayoutStorageList_some?: MetadataLayoutStorageWhereInput | null;
  metadataOriginalColorList_some?: MetadataOriginalColorWhereInput | null;
  metadataOriginalIccProfileList_some?: MetadataOriginalIccProfileWhereInput | null;
  metadataOriginalNameList_some?: MetadataOriginalNameWhereInput | null;
  metadataOriginalWeigthList_some?: MetadataOriginalWeigthWhereInput | null;
  metadataPagePrismaList_some?: MetadataPagePrismaWhereInput | null;
  metadataPhotoStorageList_some?: MetadataPhotoStorageWhereInput | null;
  metadataPrepressPictureEditingList_some?: MetadataPrepressPictureEditingWhereInput | null;
  metadataPriceLevelList_some?: MetadataPriceLevelWhereInput | null;
  metadataPrintingProfileList_some?: MetadataPrintingProfileWhereInput | null;
  metadataPrismaProductionList_some?: MetadataPrismaProductionWhereInput | null;
  metadataProcessHistoryList_some?: MetadataProcessHistoryWhereInput | null;
  metadataProcessParameterList_some?: MetadataProcessParameterWhereInput | null;
  metadataProcessStatusList_some?: MetadataProcessStatusWhereInput | null;
  metadataProductList_some?: MetadataProductWhereInput | null;
  metadataProductShortNameList_some?: MetadataProductShortNameWhereInput | null;
  metadataQualifiedUsePrismaByList_some?: MetadataQualifiedUsePrismaByWhereInput | null;
  metadataQualifiedUsePrismaDateList_some?: MetadataQualifiedUsePrismaDateWhereInput | null;
  metadataQualifiedUsePrismaDurationList_some?: MetadataQualifiedUsePrismaDurationWhereInput | null;
  metadataQualifiedUsePrismaSupportList_some?: MetadataQualifiedUsePrismaSupportWhereInput | null;
  metadataRequiredPermissionList_some?: MetadataRequiredPermissionWhereInput | null;
  metadataResolutionKindList_some?: MetadataResolutionKindWhereInput | null;
  metadataRoyaltyfreeList_some?: MetadataRoyaltyfreeWhereInput | null;
  metadataSectionList_some?: MetadataSectionWhereInput | null;
  metadataSubjectPrismaList_some?: MetadataSubjectPrismaWhereInput | null;
  metadataUndersubjectList_some?: MetadataUndersubjectWhereInput | null;
  metadataUnderUnderSubjectList_some?: MetadataUnderUnderSubjectWhereInput | null;
  metadataUnitShortNameList_some?: MetadataUnitShortNameWhereInput | null;
  metadataWorkflowKindList_some?: MetadataWorkflowKindWhereInput | null;
  metadataMakeList_some?: MetadataMakeWhereInput | null;
  metadataModelList_some?: MetadataModelWhereInput | null;
  metadataCreatorToolList_some?: MetadataCreatorToolWhereInput | null;
  metadataRatingList_some?: MetadataRatingWhereInput | null;
  metadataXmpFileStampsList_some?: MetadataXmpFileStampsWhereInput | null;
  metadataManifestList_some?: MetadataManifestWhereInput | null;
  metadataXmpHistoryList_some?: MetadataXmpHistoryWhereInput | null;
  metadataCertificateList_some?: MetadataCertificateWhereInput | null;
  metadataMarkedList_some?: MetadataMarkedWhereInput | null;
  metadataOwnerList_some?: MetadataOwnerWhereInput | null;
  metadataUsageTermsList_some?: MetadataUsageTermsWhereInput | null;
  metadataWebStatementList_some?: MetadataWebStatementWhereInput | null;
  AND?: ArchiveWhereInput[] | null;
  OR?: ArchiveWhereInput[] | null;
  NOT?: ArchiveWhereInput | null;
}

/**
 * Filters the "Category" nodes by specifying some conditions
 */
export interface CategoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  label?: string | null;
  label_not?: string | null;
  label_is_null?: boolean | null;
  label_in?: (string | null)[] | null;
  label_not_in?: (string | null)[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  archive?: ArchiveWhereInput | null;
  parent?: CategoryWhereInput | null;
  parent_is_null?: boolean | null;
  children_some?: CategoryWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  AND?: CategoryWhereInput[] | null;
  OR?: CategoryWhereInput[] | null;
  NOT?: CategoryWhereInput | null;
}

/**
 * Filters the "DeliverySubject" nodes by specifying some conditions
 */
export interface DeliverySubjectWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  agency?: string | null;
  agency_not?: string | null;
  agency_in?: string[] | null;
  agency_not_in?: string[] | null;
  agency_contains?: string | null;
  agency_not_contains?: string | null;
  agency_starts_with?: string | null;
  agency_not_starts_with?: string | null;
  agency_ends_with?: string | null;
  agency_not_ends_with?: string | null;
  agency_lt?: string | null;
  agency_lte?: string | null;
  agency_gt?: string | null;
  agency_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archive?: ArchiveWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  AND?: DeliverySubjectWhereInput[] | null;
  OR?: DeliverySubjectWhereInput[] | null;
  NOT?: DeliverySubjectWhereInput | null;
}

/**
 * Filters the "GcImportSetting" nodes by specifying some conditions
 */
export interface GcImportSettingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  period?: number | null;
  period_not?: number | null;
  period_is_null?: boolean | null;
  period_in?: (number | null)[] | null;
  period_not_in?: (number | null)[] | null;
  period_lt?: number | null;
  period_lte?: number | null;
  period_gt?: number | null;
  period_gte?: number | null;
  enabled?: boolean | null;
  enabled_not?: boolean | null;
  enabled_is_null?: boolean | null;
  enabled_in?: (boolean | null)[] | null;
  enabled_not_in?: (boolean | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archive?: ArchiveWhereInput | null;
  AND?: GcImportSettingWhereInput[] | null;
  OR?: GcImportSettingWhereInput[] | null;
  NOT?: GcImportSettingWhereInput | null;
}

/**
 * Filters the "ImportQueue" nodes by specifying some conditions
 */
export interface ImportQueueWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  message?: string | null;
  message_not?: string | null;
  message_is_null?: boolean | null;
  message_in?: (string | null)[] | null;
  message_not_in?: (string | null)[] | null;
  message_contains?: string | null;
  message_not_contains?: string | null;
  message_starts_with?: string | null;
  message_not_starts_with?: string | null;
  message_ends_with?: string | null;
  message_not_ends_with?: string | null;
  message_lt?: string | null;
  message_lte?: string | null;
  message_gt?: string | null;
  message_gte?: string | null;
  s3key?: string | null;
  s3key_not?: string | null;
  s3key_in?: string[] | null;
  s3key_not_in?: string[] | null;
  s3key_contains?: string | null;
  s3key_not_contains?: string | null;
  s3key_starts_with?: string | null;
  s3key_not_starts_with?: string | null;
  s3key_ends_with?: string | null;
  s3key_not_ends_with?: string | null;
  s3key_lt?: string | null;
  s3key_lte?: string | null;
  s3key_gt?: string | null;
  s3key_gte?: string | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  agency?: string | null;
  agency_not?: string | null;
  agency_is_null?: boolean | null;
  agency_in?: (string | null)[] | null;
  agency_not_in?: (string | null)[] | null;
  agency_contains?: string | null;
  agency_not_contains?: string | null;
  agency_starts_with?: string | null;
  agency_not_starts_with?: string | null;
  agency_ends_with?: string | null;
  agency_not_ends_with?: string | null;
  agency_lt?: string | null;
  agency_lte?: string | null;
  agency_gt?: string | null;
  agency_gte?: string | null;
  subject?: string | null;
  subject_not?: string | null;
  subject_is_null?: boolean | null;
  subject_in?: (string | null)[] | null;
  subject_not_in?: (string | null)[] | null;
  subject_contains?: string | null;
  subject_not_contains?: string | null;
  subject_starts_with?: string | null;
  subject_not_starts_with?: string | null;
  subject_ends_with?: string | null;
  subject_not_ends_with?: string | null;
  subject_lt?: string | null;
  subject_lte?: string | null;
  subject_gt?: string | null;
  subject_gte?: string | null;
  type?: string | null;
  type_not?: string | null;
  type_is_null?: boolean | null;
  type_in?: (string | null)[] | null;
  type_not_in?: (string | null)[] | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  type_lt?: string | null;
  type_lte?: string | null;
  type_gt?: string | null;
  type_gte?: string | null;
  size?: number | null;
  size_not?: number | null;
  size_is_null?: boolean | null;
  size_in?: (number | null)[] | null;
  size_not_in?: (number | null)[] | null;
  size_lt?: number | null;
  size_lte?: number | null;
  size_gt?: number | null;
  size_gte?: number | null;
  retry?: number | null;
  retry_not?: number | null;
  retry_is_null?: boolean | null;
  retry_in?: (number | null)[] | null;
  retry_not_in?: (number | null)[] | null;
  retry_lt?: number | null;
  retry_lte?: number | null;
  retry_gt?: number | null;
  retry_gte?: number | null;
  duration?: number | null;
  duration_not?: number | null;
  duration_is_null?: boolean | null;
  duration_in?: (number | null)[] | null;
  duration_not_in?: (number | null)[] | null;
  duration_lt?: number | null;
  duration_lte?: number | null;
  duration_gt?: number | null;
  duration_gte?: number | null;
  status?: ImportQueueStatusType | null;
  status_not?: ImportQueueStatusType | null;
  status_in?: ImportQueueStatusType[] | null;
  status_not_in?: ImportQueueStatusType[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  importedAt?: GraphQL$DateTime | null;
  importedAt_not?: GraphQL$DateTime | null;
  importedAt_is_null?: boolean | null;
  importedAt_in?: (GraphQL$DateTime | null)[] | null;
  importedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  importedAt_lt?: GraphQL$DateTime | null;
  importedAt_lte?: GraphQL$DateTime | null;
  importedAt_gt?: GraphQL$DateTime | null;
  importedAt_gte?: GraphQL$DateTime | null;
  archive?: ArchiveWhereInput | null;
  archive_is_null?: boolean | null;
  photo?: PhotoWhereInput | null;
  photo_is_null?: boolean | null;
  AND?: ImportQueueWhereInput[] | null;
  OR?: ImportQueueWhereInput[] | null;
  NOT?: ImportQueueWhereInput | null;
}

/**
 * Filters the "MetadataAccessRights" nodes by specifying some conditions
 */
export interface MetadataAccessRightsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAccessRightsWhereInput[] | null;
  OR?: MetadataAccessRightsWhereInput[] | null;
  NOT?: MetadataAccessRightsWhereInput | null;
}

/**
 * Filters the "MetadataAddlModelInfo" nodes by specifying some conditions
 */
export interface MetadataAddlModelInfoWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAddlModelInfoWhereInput[] | null;
  OR?: MetadataAddlModelInfoWhereInput[] | null;
  NOT?: MetadataAddlModelInfoWhereInput | null;
}

/**
 * Filters the "MetadataArchiveChild" nodes by specifying some conditions
 */
export interface MetadataArchiveChildWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataArchiveChildWhereInput[] | null;
  OR?: MetadataArchiveChildWhereInput[] | null;
  NOT?: MetadataArchiveChildWhereInput | null;
}

/**
 * Filters the "MetadataArchiveParent" nodes by specifying some conditions
 */
export interface MetadataArchiveParentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataArchiveParentWhereInput[] | null;
  OR?: MetadataArchiveParentWhereInput[] | null;
  NOT?: MetadataArchiveParentWhereInput | null;
}

/**
 * Filters the "MetadataAssetState" nodes by specifying some conditions
 */
export interface MetadataAssetStateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAssetStateWhereInput[] | null;
  OR?: MetadataAssetStateWhereInput[] | null;
  NOT?: MetadataAssetStateWhereInput | null;
}

/**
 * Filters the "MetadataAttributionName" nodes by specifying some conditions
 */
export interface MetadataAttributionNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAttributionNameWhereInput[] | null;
  OR?: MetadataAttributionNameWhereInput[] | null;
  NOT?: MetadataAttributionNameWhereInput | null;
}

/**
 * Filters the "MetadataAttributionUrl" nodes by specifying some conditions
 */
export interface MetadataAttributionUrlWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAttributionUrlWhereInput[] | null;
  OR?: MetadataAttributionUrlWhereInput[] | null;
  NOT?: MetadataAttributionUrlWhereInput | null;
}

/**
 * Filters the "MetadataAudience" nodes by specifying some conditions
 */
export interface MetadataAudienceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAudienceWhereInput[] | null;
  OR?: MetadataAudienceWhereInput[] | null;
  NOT?: MetadataAudienceWhereInput | null;
}

/**
 * Filters the "MetadataAuthorsPosition" nodes by specifying some conditions
 */
export interface MetadataAuthorsPositionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAuthorsPositionWhereInput[] | null;
  OR?: MetadataAuthorsPositionWhereInput[] | null;
  NOT?: MetadataAuthorsPositionWhereInput | null;
}

/**
 * Filters the "MetadataBackupName" nodes by specifying some conditions
 */
export interface MetadataBackupNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBackupNameWhereInput[] | null;
  OR?: MetadataBackupNameWhereInput[] | null;
  NOT?: MetadataBackupNameWhereInput | null;
}

/**
 * Filters the "MetadataBitmapGrayscalePicture" nodes by specifying some conditions
 */
export interface MetadataBitmapGrayscalePictureWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBitmapGrayscalePictureWhereInput[] | null;
  OR?: MetadataBitmapGrayscalePictureWhereInput[] | null;
  NOT?: MetadataBitmapGrayscalePictureWhereInput | null;
}

/**
 * Filters the "MetadataBrandPrisma" nodes by specifying some conditions
 */
export interface MetadataBrandPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBrandPrismaWhereInput[] | null;
  OR?: MetadataBrandPrismaWhereInput[] | null;
  NOT?: MetadataBrandPrismaWhereInput | null;
}

/**
 * Filters the "MetadataBrandShortName" nodes by specifying some conditions
 */
export interface MetadataBrandShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBrandShortNameWhereInput[] | null;
  OR?: MetadataBrandShortNameWhereInput[] | null;
  NOT?: MetadataBrandShortNameWhereInput | null;
}

/**
 * Filters the "MetadataBrand" nodes by specifying some conditions
 */
export interface MetadataBrandWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBrandWhereInput[] | null;
  OR?: MetadataBrandWhereInput[] | null;
  NOT?: MetadataBrandWhereInput | null;
}

/**
 * Filters the "MetadataCaptionWriter" nodes by specifying some conditions
 */
export interface MetadataCaptionWriterWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCaptionWriterWhereInput[] | null;
  OR?: MetadataCaptionWriterWhereInput[] | null;
  NOT?: MetadataCaptionWriterWhereInput | null;
}

/**
 * Filters the "MetadataCategoryPrisma" nodes by specifying some conditions
 */
export interface MetadataCategoryPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCategoryPrismaWhereInput[] | null;
  OR?: MetadataCategoryPrismaWhereInput[] | null;
  NOT?: MetadataCategoryPrismaWhereInput | null;
}

/**
 * Filters the "MetadataCategory" nodes by specifying some conditions
 */
export interface MetadataCategoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCategoryWhereInput[] | null;
  OR?: MetadataCategoryWhereInput[] | null;
  NOT?: MetadataCategoryWhereInput | null;
}

/**
 * Filters the "MetadataCertificate" nodes by specifying some conditions
 */
export interface MetadataCertificateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCertificateWhereInput[] | null;
  OR?: MetadataCertificateWhereInput[] | null;
  NOT?: MetadataCertificateWhereInput | null;
}

/**
 * Filters the "MetadataCiAdrCity" nodes by specifying some conditions
 */
export interface MetadataCiAdrCityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrCityWhereInput[] | null;
  OR?: MetadataCiAdrCityWhereInput[] | null;
  NOT?: MetadataCiAdrCityWhereInput | null;
}

/**
 * Filters the "MetadataCiAdrCtry" nodes by specifying some conditions
 */
export interface MetadataCiAdrCtryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrCtryWhereInput[] | null;
  OR?: MetadataCiAdrCtryWhereInput[] | null;
  NOT?: MetadataCiAdrCtryWhereInput | null;
}

/**
 * Filters the "MetadataCiAdrExtadr" nodes by specifying some conditions
 */
export interface MetadataCiAdrExtadrWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrExtadrWhereInput[] | null;
  OR?: MetadataCiAdrExtadrWhereInput[] | null;
  NOT?: MetadataCiAdrExtadrWhereInput | null;
}

/**
 * Filters the "MetadataCiAdrPcode" nodes by specifying some conditions
 */
export interface MetadataCiAdrPcodeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrPcodeWhereInput[] | null;
  OR?: MetadataCiAdrPcodeWhereInput[] | null;
  NOT?: MetadataCiAdrPcodeWhereInput | null;
}

/**
 * Filters the "MetadataCiAdrRegion" nodes by specifying some conditions
 */
export interface MetadataCiAdrRegionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrRegionWhereInput[] | null;
  OR?: MetadataCiAdrRegionWhereInput[] | null;
  NOT?: MetadataCiAdrRegionWhereInput | null;
}

/**
 * Filters the "MetadataCiEmailWork" nodes by specifying some conditions
 */
export interface MetadataCiEmailWorkWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiEmailWorkWhereInput[] | null;
  OR?: MetadataCiEmailWorkWhereInput[] | null;
  NOT?: MetadataCiEmailWorkWhereInput | null;
}

/**
 * Filters the "MetadataCiTelWork" nodes by specifying some conditions
 */
export interface MetadataCiTelWorkWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiTelWorkWhereInput[] | null;
  OR?: MetadataCiTelWorkWhereInput[] | null;
  NOT?: MetadataCiTelWorkWhereInput | null;
}

/**
 * Filters the "MetadataCiUrlWork" nodes by specifying some conditions
 */
export interface MetadataCiUrlWorkWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiUrlWorkWhereInput[] | null;
  OR?: MetadataCiUrlWorkWhereInput[] | null;
  NOT?: MetadataCiUrlWorkWhereInput | null;
}

/**
 * Filters the "MetadataCity" nodes by specifying some conditions
 */
export interface MetadataCityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCityWhereInput[] | null;
  OR?: MetadataCityWhereInput[] | null;
  NOT?: MetadataCityWhereInput | null;
}

/**
 * Filters the "MetadataClassify" nodes by specifying some conditions
 */
export interface MetadataClassifyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataClassifyWhereInput[] | null;
  OR?: MetadataClassifyWhereInput[] | null;
  NOT?: MetadataClassifyWhereInput | null;
}

/**
 * Filters the "MetadataCompanyShortName" nodes by specifying some conditions
 */
export interface MetadataCompanyShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCompanyShortNameWhereInput[] | null;
  OR?: MetadataCompanyShortNameWhereInput[] | null;
  NOT?: MetadataCompanyShortNameWhereInput | null;
}

/**
 * Filters the "MetadataCompany" nodes by specifying some conditions
 */
export interface MetadataCompanyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCompanyWhereInput[] | null;
  OR?: MetadataCompanyWhereInput[] | null;
  NOT?: MetadataCompanyWhereInput | null;
}

/**
 * Filters the "MetadataConfidentiality" nodes by specifying some conditions
 */
export interface MetadataConfidentialityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataConfidentialityWhereInput[] | null;
  OR?: MetadataConfidentialityWhereInput[] | null;
  NOT?: MetadataConfidentialityWhereInput | null;
}

/**
 * Filters the "MetadataContainedIn" nodes by specifying some conditions
 */
export interface MetadataContainedInWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataContainedInWhereInput[] | null;
  OR?: MetadataContainedInWhereInput[] | null;
  NOT?: MetadataContainedInWhereInput | null;
}

/**
 * Filters the "MetadataContentMediaKind" nodes by specifying some conditions
 */
export interface MetadataContentMediaKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataContentMediaKindWhereInput[] | null;
  OR?: MetadataContentMediaKindWhereInput[] | null;
  NOT?: MetadataContentMediaKindWhereInput | null;
}

/**
 * Filters the "MetadataContentValue" nodes by specifying some conditions
 */
export interface MetadataContentValueWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataContentValueWhereInput[] | null;
  OR?: MetadataContentValueWhereInput[] | null;
  NOT?: MetadataContentValueWhereInput | null;
}

/**
 * Filters the "MetadataCopyrightLayer" nodes by specifying some conditions
 */
export interface MetadataCopyrightLayerWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCopyrightLayerWhereInput[] | null;
  OR?: MetadataCopyrightLayerWhereInput[] | null;
  NOT?: MetadataCopyrightLayerWhereInput | null;
}

/**
 * Filters the "MetadataCountryCode" nodes by specifying some conditions
 */
export interface MetadataCountryCodeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCountryCodeWhereInput[] | null;
  OR?: MetadataCountryCodeWhereInput[] | null;
  NOT?: MetadataCountryCodeWhereInput | null;
}

/**
 * Filters the "MetadataCountry" nodes by specifying some conditions
 */
export interface MetadataCountryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCountryWhereInput[] | null;
  OR?: MetadataCountryWhereInput[] | null;
  NOT?: MetadataCountryWhereInput | null;
}

/**
 * Filters the "MetadataCreatedTime" nodes by specifying some conditions
 */
export interface MetadataCreatedTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreatedTimeWhereInput[] | null;
  OR?: MetadataCreatedTimeWhereInput[] | null;
  NOT?: MetadataCreatedTimeWhereInput | null;
}

/**
 * Filters the "MetadataCreatorTool" nodes by specifying some conditions
 */
export interface MetadataCreatorToolWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreatorToolWhereInput[] | null;
  OR?: MetadataCreatorToolWhereInput[] | null;
  NOT?: MetadataCreatorToolWhereInput | null;
}

/**
 * Filters the "MetadataCreator" nodes by specifying some conditions
 */
export interface MetadataCreatorWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreatorWhereInput[] | null;
  OR?: MetadataCreatorWhereInput[] | null;
  NOT?: MetadataCreatorWhereInput | null;
}

/**
 * Filters the "MetadataCredit" nodes by specifying some conditions
 */
export interface MetadataCreditWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreditWhereInput[] | null;
  OR?: MetadataCreditWhereInput[] | null;
  NOT?: MetadataCreditWhereInput | null;
}

/**
 * Filters the "MetadataCustomField14" nodes by specifying some conditions
 */
export interface MetadataCustomField14WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField14WhereInput[] | null;
  OR?: MetadataCustomField14WhereInput[] | null;
  NOT?: MetadataCustomField14WhereInput | null;
}

/**
 * Filters the "MetadataCustomField15" nodes by specifying some conditions
 */
export interface MetadataCustomField15WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField15WhereInput[] | null;
  OR?: MetadataCustomField15WhereInput[] | null;
  NOT?: MetadataCustomField15WhereInput | null;
}

/**
 * Filters the "MetadataCustomField16" nodes by specifying some conditions
 */
export interface MetadataCustomField16WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField16WhereInput[] | null;
  OR?: MetadataCustomField16WhereInput[] | null;
  NOT?: MetadataCustomField16WhereInput | null;
}

/**
 * Filters the "MetadataCustomField17" nodes by specifying some conditions
 */
export interface MetadataCustomField17WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField17WhereInput[] | null;
  OR?: MetadataCustomField17WhereInput[] | null;
  NOT?: MetadataCustomField17WhereInput | null;
}

/**
 * Filters the "MetadataCustomField20" nodes by specifying some conditions
 */
export interface MetadataCustomField20WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField20WhereInput[] | null;
  OR?: MetadataCustomField20WhereInput[] | null;
  NOT?: MetadataCustomField20WhereInput | null;
}

/**
 * Filters the "MetadataCustomField5" nodes by specifying some conditions
 */
export interface MetadataCustomField5WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField5WhereInput[] | null;
  OR?: MetadataCustomField5WhereInput[] | null;
  NOT?: MetadataCustomField5WhereInput | null;
}

/**
 * Filters the "MetadataCustomField6" nodes by specifying some conditions
 */
export interface MetadataCustomField6WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField6WhereInput[] | null;
  OR?: MetadataCustomField6WhereInput[] | null;
  NOT?: MetadataCustomField6WhereInput | null;
}

/**
 * Filters the "MetadataCustomField7" nodes by specifying some conditions
 */
export interface MetadataCustomField7WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField7WhereInput[] | null;
  OR?: MetadataCustomField7WhereInput[] | null;
  NOT?: MetadataCustomField7WhereInput | null;
}

/**
 * Filters the "MetadataCustomField9" nodes by specifying some conditions
 */
export interface MetadataCustomField9WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField9WhereInput[] | null;
  OR?: MetadataCustomField9WhereInput[] | null;
  NOT?: MetadataCustomField9WhereInput | null;
}

/**
 * Filters the "MetadataDateCreated" nodes by specifying some conditions
 */
export interface MetadataDateCreatedWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDateCreatedWhereInput[] | null;
  OR?: MetadataDateCreatedWhereInput[] | null;
  NOT?: MetadataDateCreatedWhereInput | null;
}

/**
 * Filters the "MetadataDateTimeDigitized" nodes by specifying some conditions
 */
export interface MetadataDateTimeDigitizedWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDateTimeDigitizedWhereInput[] | null;
  OR?: MetadataDateTimeDigitizedWhereInput[] | null;
  NOT?: MetadataDateTimeDigitizedWhereInput | null;
}

/**
 * Filters the "MetadataDateTimeOriginal" nodes by specifying some conditions
 */
export interface MetadataDateTimeOriginalWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDateTimeOriginalWhereInput[] | null;
  OR?: MetadataDateTimeOriginalWhereInput[] | null;
  NOT?: MetadataDateTimeOriginalWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryAccount" nodes by specifying some conditions
 */
export interface MetadataDeliveryAccountWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryAccountWhereInput[] | null;
  OR?: MetadataDeliveryAccountWhereInput[] | null;
  NOT?: MetadataDeliveryAccountWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryCompany" nodes by specifying some conditions
 */
export interface MetadataDeliveryCompanyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryCompanyWhereInput[] | null;
  OR?: MetadataDeliveryCompanyWhereInput[] | null;
  NOT?: MetadataDeliveryCompanyWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryCopyright" nodes by specifying some conditions
 */
export interface MetadataDeliveryCopyrightWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryCopyrightWhereInput[] | null;
  OR?: MetadataDeliveryCopyrightWhereInput[] | null;
  NOT?: MetadataDeliveryCopyrightWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryDateFolder" nodes by specifying some conditions
 */
export interface MetadataDeliveryDateFolderWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDateFolderWhereInput[] | null;
  OR?: MetadataDeliveryDateFolderWhereInput[] | null;
  NOT?: MetadataDeliveryDateFolderWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryDateTime" nodes by specifying some conditions
 */
export interface MetadataDeliveryDateTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDateTimeWhereInput[] | null;
  OR?: MetadataDeliveryDateTimeWhereInput[] | null;
  NOT?: MetadataDeliveryDateTimeWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryDcCreator" nodes by specifying some conditions
 */
export interface MetadataDeliveryDcCreatorWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDcCreatorWhereInput[] | null;
  OR?: MetadataDeliveryDcCreatorWhereInput[] | null;
  NOT?: MetadataDeliveryDcCreatorWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryDcRights" nodes by specifying some conditions
 */
export interface MetadataDeliveryDcRightsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDcRightsWhereInput[] | null;
  OR?: MetadataDeliveryDcRightsWhereInput[] | null;
  NOT?: MetadataDeliveryDcRightsWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryFileType" nodes by specifying some conditions
 */
export interface MetadataDeliveryFileTypeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryFileTypeWhereInput[] | null;
  OR?: MetadataDeliveryFileTypeWhereInput[] | null;
  NOT?: MetadataDeliveryFileTypeWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryFolder" nodes by specifying some conditions
 */
export interface MetadataDeliveryFolderWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryFolderWhereInput[] | null;
  OR?: MetadataDeliveryFolderWhereInput[] | null;
  NOT?: MetadataDeliveryFolderWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryKind" nodes by specifying some conditions
 */
export interface MetadataDeliveryKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryKindWhereInput[] | null;
  OR?: MetadataDeliveryKindWhereInput[] | null;
  NOT?: MetadataDeliveryKindWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryPath" nodes by specifying some conditions
 */
export interface MetadataDeliveryPathWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPathWhereInput[] | null;
  OR?: MetadataDeliveryPathWhereInput[] | null;
  NOT?: MetadataDeliveryPathWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryPersonShownintheImage" nodes by specifying some conditions
 */
export interface MetadataDeliveryPersonShownintheImageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPersonShownintheImageWhereInput[] | null;
  OR?: MetadataDeliveryPersonShownintheImageWhereInput[] | null;
  NOT?: MetadataDeliveryPersonShownintheImageWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryPhotoshopCredit" nodes by specifying some conditions
 */
export interface MetadataDeliveryPhotoshopCreditWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPhotoshopCreditWhereInput[] | null;
  OR?: MetadataDeliveryPhotoshopCreditWhereInput[] | null;
  NOT?: MetadataDeliveryPhotoshopCreditWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryPhotoshopSource" nodes by specifying some conditions
 */
export interface MetadataDeliveryPhotoshopSourceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPhotoshopSourceWhereInput[] | null;
  OR?: MetadataDeliveryPhotoshopSourceWhereInput[] | null;
  NOT?: MetadataDeliveryPhotoshopSourceWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryService" nodes by specifying some conditions
 */
export interface MetadataDeliveryServiceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryServiceWhereInput[] | null;
  OR?: MetadataDeliveryServiceWhereInput[] | null;
  NOT?: MetadataDeliveryServiceWhereInput | null;
}

/**
 * Filters the "MetadataDeliverySubject" nodes by specifying some conditions
 */
export interface MetadataDeliverySubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliverySubjectWhereInput[] | null;
  OR?: MetadataDeliverySubjectWhereInput[] | null;
  NOT?: MetadataDeliverySubjectWhereInput | null;
}

/**
 * Filters the "MetadataDeliveryUnderSubject" nodes by specifying some conditions
 */
export interface MetadataDeliveryUnderSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryUnderSubjectWhereInput[] | null;
  OR?: MetadataDeliveryUnderSubjectWhereInput[] | null;
  NOT?: MetadataDeliveryUnderSubjectWhereInput | null;
}

/**
 * Filters the "MetadataDepartment" nodes by specifying some conditions
 */
export interface MetadataDepartmentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDepartmentWhereInput[] | null;
  OR?: MetadataDepartmentWhereInput[] | null;
  NOT?: MetadataDepartmentWhereInput | null;
}

/**
 * Filters the "MetadataDescription" nodes by specifying some conditions
 */
export interface MetadataDescriptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDescriptionWhereInput[] | null;
  OR?: MetadataDescriptionWhereInput[] | null;
  NOT?: MetadataDescriptionWhereInput | null;
}

/**
 * Filters the "MetadataDigitalAssetUrl" nodes by specifying some conditions
 */
export interface MetadataDigitalAssetUrlWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDigitalAssetUrlWhereInput[] | null;
  OR?: MetadataDigitalAssetUrlWhereInput[] | null;
  NOT?: MetadataDigitalAssetUrlWhereInput | null;
}

/**
 * Filters the "MetadataDivision" nodes by specifying some conditions
 */
export interface MetadataDivisionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDivisionWhereInput[] | null;
  OR?: MetadataDivisionWhereInput[] | null;
  NOT?: MetadataDivisionWhereInput | null;
}

/**
 * Filters the "MetadataDocumentText" nodes by specifying some conditions
 */
export interface MetadataDocumentTextWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDocumentTextWhereInput[] | null;
  OR?: MetadataDocumentTextWhereInput[] | null;
  NOT?: MetadataDocumentTextWhereInput | null;
}

/**
 * Filters the "MetadataEditStatus" nodes by specifying some conditions
 */
export interface MetadataEditStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEditStatusWhereInput[] | null;
  OR?: MetadataEditStatusWhereInput[] | null;
  NOT?: MetadataEditStatusWhereInput | null;
}

/**
 * Filters the "MetadataEdition" nodes by specifying some conditions
 */
export interface MetadataEditionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEditionWhereInput[] | null;
  OR?: MetadataEditionWhereInput[] | null;
  NOT?: MetadataEditionWhereInput | null;
}

/**
 * Filters the "MetadataEditorialVersion" nodes by specifying some conditions
 */
export interface MetadataEditorialVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEditorialVersionWhereInput[] | null;
  OR?: MetadataEditorialVersionWhereInput[] | null;
  NOT?: MetadataEditorialVersionWhereInput | null;
}

/**
 * Filters the "MetadataEnvironnementPhoto" nodes by specifying some conditions
 */
export interface MetadataEnvironnementPhotoWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEnvironnementPhotoWhereInput[] | null;
  OR?: MetadataEnvironnementPhotoWhereInput[] | null;
  NOT?: MetadataEnvironnementPhotoWhereInput | null;
}

/**
 * Filters the "MetadataEquipmentInstitution" nodes by specifying some conditions
 */
export interface MetadataEquipmentInstitutionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEquipmentInstitutionWhereInput[] | null;
  OR?: MetadataEquipmentInstitutionWhereInput[] | null;
  NOT?: MetadataEquipmentInstitutionWhereInput | null;
}

/**
 * Filters the "MetadataEquipmentManufacturer" nodes by specifying some conditions
 */
export interface MetadataEquipmentManufacturerWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEquipmentManufacturerWhereInput[] | null;
  OR?: MetadataEquipmentManufacturerWhereInput[] | null;
  NOT?: MetadataEquipmentManufacturerWhereInput | null;
}

/**
 * Filters the "MetadataEvent" nodes by specifying some conditions
 */
export interface MetadataEventWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEventWhereInput[] | null;
  OR?: MetadataEventWhereInput[] | null;
  NOT?: MetadataEventWhereInput | null;
}

/**
 * Filters the "MetadataExif" nodes by specifying some conditions
 */
export interface MetadataExifWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataExifWhereInput[] | null;
  OR?: MetadataExifWhereInput[] | null;
  NOT?: MetadataExifWhereInput | null;
}

/**
 * Filters the "MetadataFabStorage" nodes by specifying some conditions
 */
export interface MetadataFabStorageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFabStorageWhereInput[] | null;
  OR?: MetadataFabStorageWhereInput[] | null;
  NOT?: MetadataFabStorageWhereInput | null;
}

/**
 * Filters the "MetadataFileCheckFor" nodes by specifying some conditions
 */
export interface MetadataFileCheckForWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileCheckForWhereInput[] | null;
  OR?: MetadataFileCheckForWhereInput[] | null;
  NOT?: MetadataFileCheckForWhereInput | null;
}

/**
 * Filters the "MetadataFileInfoBitsPerPixel" nodes by specifying some conditions
 */
export interface MetadataFileInfoBitsPerPixelWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoBitsPerPixelWhereInput[] | null;
  OR?: MetadataFileInfoBitsPerPixelWhereInput[] | null;
  NOT?: MetadataFileInfoBitsPerPixelWhereInput | null;
}

/**
 * Filters the "MetadataFileInfoFrames" nodes by specifying some conditions
 */
export interface MetadataFileInfoFramesWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoFramesWhereInput[] | null;
  OR?: MetadataFileInfoFramesWhereInput[] | null;
  NOT?: MetadataFileInfoFramesWhereInput | null;
}

/**
 * Filters the "MetadataFileInfoPhotometricInterpretation" nodes by specifying some conditions
 */
export interface MetadataFileInfoPhotometricInterpretationWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoPhotometricInterpretationWhereInput[] | null;
  OR?: MetadataFileInfoPhotometricInterpretationWhereInput[] | null;
  NOT?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
}

/**
 * Filters the "MetadataFileInfoTransferSyntax" nodes by specifying some conditions
 */
export interface MetadataFileInfoTransferSyntaxWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoTransferSyntaxWhereInput[] | null;
  OR?: MetadataFileInfoTransferSyntaxWhereInput[] | null;
  NOT?: MetadataFileInfoTransferSyntaxWhereInput | null;
}

/**
 * Filters the "MetadataFileStage" nodes by specifying some conditions
 */
export interface MetadataFileStageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileStageWhereInput[] | null;
  OR?: MetadataFileStageWhereInput[] | null;
  NOT?: MetadataFileStageWhereInput | null;
}

/**
 * Filters the "MetadataFileStatus" nodes by specifying some conditions
 */
export interface MetadataFileStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileStatusWhereInput[] | null;
  OR?: MetadataFileStatusWhereInput[] | null;
  NOT?: MetadataFileStatusWhereInput | null;
}

/**
 * Filters the "MetadataFileType" nodes by specifying some conditions
 */
export interface MetadataFileTypeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileTypeWhereInput[] | null;
  OR?: MetadataFileTypeWhereInput[] | null;
  NOT?: MetadataFileTypeWhereInput | null;
}

/**
 * Filters the "MetadataFixtureIdentifier" nodes by specifying some conditions
 */
export interface MetadataFixtureIdentifierWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFixtureIdentifierWhereInput[] | null;
  OR?: MetadataFixtureIdentifierWhereInput[] | null;
  NOT?: MetadataFixtureIdentifierWhereInput | null;
}

/**
 * Filters the "MetadataFormat" nodes by specifying some conditions
 */
export interface MetadataFormatWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFormatWhereInput[] | null;
  OR?: MetadataFormatWhereInput[] | null;
  NOT?: MetadataFormatWhereInput | null;
}

/**
 * Filters the "MetadataGpsLatitude" nodes by specifying some conditions
 */
export interface MetadataGpsLatitudeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataGpsLatitudeWhereInput[] | null;
  OR?: MetadataGpsLatitudeWhereInput[] | null;
  NOT?: MetadataGpsLatitudeWhereInput | null;
}

/**
 * Filters the "MetadataGpsLongitude" nodes by specifying some conditions
 */
export interface MetadataGpsLongitudeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataGpsLongitudeWhereInput[] | null;
  OR?: MetadataGpsLongitudeWhereInput[] | null;
  NOT?: MetadataGpsLongitudeWhereInput | null;
}

/**
 * Filters the "MetadataGpsProcessingMethod" nodes by specifying some conditions
 */
export interface MetadataGpsProcessingMethodWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataGpsProcessingMethodWhereInput[] | null;
  OR?: MetadataGpsProcessingMethodWhereInput[] | null;
  NOT?: MetadataGpsProcessingMethodWhereInput | null;
}

/**
 * Filters the "MetadataHeaderPrisma" nodes by specifying some conditions
 */
export interface MetadataHeaderPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHeaderPrismaWhereInput[] | null;
  OR?: MetadataHeaderPrismaWhereInput[] | null;
  NOT?: MetadataHeaderPrismaWhereInput | null;
}

/**
 * Filters the "MetadataHeader" nodes by specifying some conditions
 */
export interface MetadataHeaderWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHeaderWhereInput[] | null;
  OR?: MetadataHeaderWhereInput[] | null;
  NOT?: MetadataHeaderWhereInput | null;
}

/**
 * Filters the "MetadataHeadline" nodes by specifying some conditions
 */
export interface MetadataHeadlineWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHeadlineWhereInput[] | null;
  OR?: MetadataHeadlineWhereInput[] | null;
  NOT?: MetadataHeadlineWhereInput | null;
}

/**
 * Filters the "MetadataHistory" nodes by specifying some conditions
 */
export interface MetadataHistoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHistoryWhereInput[] | null;
  OR?: MetadataHistoryWhereInput[] | null;
  NOT?: MetadataHistoryWhereInput | null;
}

/**
 * Filters the "MetadataIccProfile" nodes by specifying some conditions
 */
export interface MetadataIccProfileWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIccProfileWhereInput[] | null;
  OR?: MetadataIccProfileWhereInput[] | null;
  NOT?: MetadataIccProfileWhereInput | null;
}

/**
 * Filters the "MetadataIdAssignment" nodes by specifying some conditions
 */
export interface MetadataIdAssignmentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdAssignmentWhereInput[] | null;
  OR?: MetadataIdAssignmentWhereInput[] | null;
  NOT?: MetadataIdAssignmentWhereInput | null;
}

/**
 * Filters the "MetadataIdMediaContent" nodes by specifying some conditions
 */
export interface MetadataIdMediaContentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdMediaContentWhereInput[] | null;
  OR?: MetadataIdMediaContentWhereInput[] | null;
  NOT?: MetadataIdMediaContentWhereInput | null;
}

/**
 * Filters the "MetadataIdPhotoPrisma" nodes by specifying some conditions
 */
export interface MetadataIdPhotoPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdPhotoPrismaWhereInput[] | null;
  OR?: MetadataIdPhotoPrismaWhereInput[] | null;
  NOT?: MetadataIdPhotoPrismaWhereInput | null;
}

/**
 * Filters the "MetadataIdSubject" nodes by specifying some conditions
 */
export interface MetadataIdSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdSubjectWhereInput[] | null;
  OR?: MetadataIdSubjectWhereInput[] | null;
  NOT?: MetadataIdSubjectWhereInput | null;
}

/**
 * Filters the "MetadataImageNotes" nodes by specifying some conditions
 */
export interface MetadataImageNotesWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataImageNotesWhereInput[] | null;
  OR?: MetadataImageNotesWhereInput[] | null;
  NOT?: MetadataImageNotesWhereInput | null;
}

/**
 * Filters the "MetadataImageUniqueId" nodes by specifying some conditions
 */
export interface MetadataImageUniqueIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataImageUniqueIdWhereInput[] | null;
  OR?: MetadataImageUniqueIdWhereInput[] | null;
  NOT?: MetadataImageUniqueIdWhereInput | null;
}

/**
 * Filters the "MetadataInstructions" nodes by specifying some conditions
 */
export interface MetadataInstructionsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataInstructionsWhereInput[] | null;
  OR?: MetadataInstructionsWhereInput[] | null;
  NOT?: MetadataInstructionsWhereInput | null;
}

/**
 * Filters the "MetadataIntellectualGenre" nodes by specifying some conditions
 */
export interface MetadataIntellectualGenreWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIntellectualGenreWhereInput[] | null;
  OR?: MetadataIntellectualGenreWhereInput[] | null;
  NOT?: MetadataIntellectualGenreWhereInput | null;
}

/**
 * Filters the "MetadataIsoSpeedRatings" nodes by specifying some conditions
 */
export interface MetadataIsoSpeedRatingsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIsoSpeedRatingsWhereInput[] | null;
  OR?: MetadataIsoSpeedRatingsWhereInput[] | null;
  NOT?: MetadataIsoSpeedRatingsWhereInput | null;
}

/**
 * Filters the "MetadataIssue" nodes by specifying some conditions
 */
export interface MetadataIssueWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIssueWhereInput[] | null;
  OR?: MetadataIssueWhereInput[] | null;
  NOT?: MetadataIssueWhereInput | null;
}

/**
 * Filters the "MetadataJobId" nodes by specifying some conditions
 */
export interface MetadataJobIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataJobIdWhereInput[] | null;
  OR?: MetadataJobIdWhereInput[] | null;
  NOT?: MetadataJobIdWhereInput | null;
}

/**
 * Filters the "MetadataJobProcessing" nodes by specifying some conditions
 */
export interface MetadataJobProcessingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataJobProcessingWhereInput[] | null;
  OR?: MetadataJobProcessingWhereInput[] | null;
  NOT?: MetadataJobProcessingWhereInput | null;
}

/**
 * Filters the "MetadataLayoutPictureEditing" nodes by specifying some conditions
 */
export interface MetadataLayoutPictureEditingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLayoutPictureEditingWhereInput[] | null;
  OR?: MetadataLayoutPictureEditingWhereInput[] | null;
  NOT?: MetadataLayoutPictureEditingWhereInput | null;
}

/**
 * Filters the "MetadataLayoutStorage" nodes by specifying some conditions
 */
export interface MetadataLayoutStorageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLayoutStorageWhereInput[] | null;
  OR?: MetadataLayoutStorageWhereInput[] | null;
  NOT?: MetadataLayoutStorageWhereInput | null;
}

/**
 * Filters the "MetadataLicense" nodes by specifying some conditions
 */
export interface MetadataLicenseWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLicenseWhereInput[] | null;
  OR?: MetadataLicenseWhereInput[] | null;
  NOT?: MetadataLicenseWhereInput | null;
}

/**
 * Filters the "MetadataLinks" nodes by specifying some conditions
 */
export interface MetadataLinksWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLinksWhereInput[] | null;
  OR?: MetadataLinksWhereInput[] | null;
  NOT?: MetadataLinksWhereInput | null;
}

/**
 * Filters the "MetadataLocalCaption" nodes by specifying some conditions
 */
export interface MetadataLocalCaptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLocalCaptionWhereInput[] | null;
  OR?: MetadataLocalCaptionWhereInput[] | null;
  NOT?: MetadataLocalCaptionWhereInput | null;
}

/**
 * Filters the "MetadataLocation" nodes by specifying some conditions
 */
export interface MetadataLocationWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLocationWhereInput[] | null;
  OR?: MetadataLocationWhereInput[] | null;
  NOT?: MetadataLocationWhereInput | null;
}

/**
 * Filters the "MetadataMake" nodes by specifying some conditions
 */
export interface MetadataMakeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMakeWhereInput[] | null;
  OR?: MetadataMakeWhereInput[] | null;
  NOT?: MetadataMakeWhereInput | null;
}

/**
 * Filters the "MetadataManifest" nodes by specifying some conditions
 */
export interface MetadataManifestWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataManifestWhereInput[] | null;
  OR?: MetadataManifestWhereInput[] | null;
  NOT?: MetadataManifestWhereInput | null;
}

/**
 * Filters the "MetadataMarked" nodes by specifying some conditions
 */
export interface MetadataMarkedWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMarkedWhereInput[] | null;
  OR?: MetadataMarkedWhereInput[] | null;
  NOT?: MetadataMarkedWhereInput | null;
}

/**
 * Filters the "MetadataMasterDocumentId" nodes by specifying some conditions
 */
export interface MetadataMasterDocumentIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMasterDocumentIdWhereInput[] | null;
  OR?: MetadataMasterDocumentIdWhereInput[] | null;
  NOT?: MetadataMasterDocumentIdWhereInput | null;
}

/**
 * Filters the "MetadataMaxAvailHeight" nodes by specifying some conditions
 */
export interface MetadataMaxAvailHeightWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMaxAvailHeightWhereInput[] | null;
  OR?: MetadataMaxAvailHeightWhereInput[] | null;
  NOT?: MetadataMaxAvailHeightWhereInput | null;
}

/**
 * Filters the "MetadataMaxAvailWidth" nodes by specifying some conditions
 */
export interface MetadataMaxAvailWidthWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMaxAvailWidthWhereInput[] | null;
  OR?: MetadataMaxAvailWidthWhereInput[] | null;
  NOT?: MetadataMaxAvailWidthWhereInput | null;
}

/**
 * Filters the "MetadataMinorModelAgeDisclosure" nodes by specifying some conditions
 */
export interface MetadataMinorModelAgeDisclosureWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMinorModelAgeDisclosureWhereInput[] | null;
  OR?: MetadataMinorModelAgeDisclosureWhereInput[] | null;
  NOT?: MetadataMinorModelAgeDisclosureWhereInput | null;
}

/**
 * Filters the "MetadataModelAge" nodes by specifying some conditions
 */
export interface MetadataModelAgeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelAgeWhereInput[] | null;
  OR?: MetadataModelAgeWhereInput[] | null;
  NOT?: MetadataModelAgeWhereInput | null;
}

/**
 * Filters the "MetadataModelReleaseId" nodes by specifying some conditions
 */
export interface MetadataModelReleaseIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelReleaseIdWhereInput[] | null;
  OR?: MetadataModelReleaseIdWhereInput[] | null;
  NOT?: MetadataModelReleaseIdWhereInput | null;
}

/**
 * Filters the "MetadataModelReleaseStatus" nodes by specifying some conditions
 */
export interface MetadataModelReleaseStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelReleaseStatusWhereInput[] | null;
  OR?: MetadataModelReleaseStatusWhereInput[] | null;
  NOT?: MetadataModelReleaseStatusWhereInput | null;
}

/**
 * Filters the "MetadataModel" nodes by specifying some conditions
 */
export interface MetadataModelWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelWhereInput[] | null;
  OR?: MetadataModelWhereInput[] | null;
  NOT?: MetadataModelWhereInput | null;
}

/**
 * Filters the "MetadataMorePermissions" nodes by specifying some conditions
 */
export interface MetadataMorePermissionsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMorePermissionsWhereInput[] | null;
  OR?: MetadataMorePermissionsWhereInput[] | null;
  NOT?: MetadataMorePermissionsWhereInput | null;
}

/**
 * Filters the "MetadataObjectCycle" nodes by specifying some conditions
 */
export interface MetadataObjectCycleWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataObjectCycleWhereInput[] | null;
  OR?: MetadataObjectCycleWhereInput[] | null;
  NOT?: MetadataObjectCycleWhereInput | null;
}

/**
 * Filters the "MetadataOrganisationInImageName" nodes by specifying some conditions
 */
export interface MetadataOrganisationInImageNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOrganisationInImageNameWhereInput[] | null;
  OR?: MetadataOrganisationInImageNameWhereInput[] | null;
  NOT?: MetadataOrganisationInImageNameWhereInput | null;
}

/**
 * Filters the "MetadataOriginalColor" nodes by specifying some conditions
 */
export interface MetadataOriginalColorWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalColorWhereInput[] | null;
  OR?: MetadataOriginalColorWhereInput[] | null;
  NOT?: MetadataOriginalColorWhereInput | null;
}

/**
 * Filters the "MetadataOriginalIccProfile" nodes by specifying some conditions
 */
export interface MetadataOriginalIccProfileWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalIccProfileWhereInput[] | null;
  OR?: MetadataOriginalIccProfileWhereInput[] | null;
  NOT?: MetadataOriginalIccProfileWhereInput | null;
}

/**
 * Filters the "MetadataOriginalName" nodes by specifying some conditions
 */
export interface MetadataOriginalNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalNameWhereInput[] | null;
  OR?: MetadataOriginalNameWhereInput[] | null;
  NOT?: MetadataOriginalNameWhereInput | null;
}

/**
 * Filters the "MetadataOriginalWeigth" nodes by specifying some conditions
 */
export interface MetadataOriginalWeigthWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalWeigthWhereInput[] | null;
  OR?: MetadataOriginalWeigthWhereInput[] | null;
  NOT?: MetadataOriginalWeigthWhereInput | null;
}

/**
 * Filters the "MetadataOwnerId" nodes by specifying some conditions
 */
export interface MetadataOwnerIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOwnerIdWhereInput[] | null;
  OR?: MetadataOwnerIdWhereInput[] | null;
  NOT?: MetadataOwnerIdWhereInput | null;
}

/**
 * Filters the "MetadataOwner" nodes by specifying some conditions
 */
export interface MetadataOwnerWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOwnerWhereInput[] | null;
  OR?: MetadataOwnerWhereInput[] | null;
  NOT?: MetadataOwnerWhereInput | null;
}

/**
 * Filters the "MetadataPagePrisma" nodes by specifying some conditions
 */
export interface MetadataPagePrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPagePrismaWhereInput[] | null;
  OR?: MetadataPagePrismaWhereInput[] | null;
  NOT?: MetadataPagePrismaWhereInput | null;
}

/**
 * Filters the "MetadataPage" nodes by specifying some conditions
 */
export interface MetadataPageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPageWhereInput[] | null;
  OR?: MetadataPageWhereInput[] | null;
  NOT?: MetadataPageWhereInput | null;
}

/**
 * Filters the "MetadataPaintBasedCorrections" nodes by specifying some conditions
 */
export interface MetadataPaintBasedCorrectionsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPaintBasedCorrectionsWhereInput[] | null;
  OR?: MetadataPaintBasedCorrectionsWhereInput[] | null;
  NOT?: MetadataPaintBasedCorrectionsWhereInput | null;
}

/**
 * Filters the "MetadataPatientDob" nodes by specifying some conditions
 */
export interface MetadataPatientDobWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientDobWhereInput[] | null;
  OR?: MetadataPatientDobWhereInput[] | null;
  NOT?: MetadataPatientDobWhereInput | null;
}

/**
 * Filters the "MetadataPatientId" nodes by specifying some conditions
 */
export interface MetadataPatientIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientIdWhereInput[] | null;
  OR?: MetadataPatientIdWhereInput[] | null;
  NOT?: MetadataPatientIdWhereInput | null;
}

/**
 * Filters the "MetadataPatientName" nodes by specifying some conditions
 */
export interface MetadataPatientNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientNameWhereInput[] | null;
  OR?: MetadataPatientNameWhereInput[] | null;
  NOT?: MetadataPatientNameWhereInput | null;
}

/**
 * Filters the "MetadataPatientSex" nodes by specifying some conditions
 */
export interface MetadataPatientSexWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientSexWhereInput[] | null;
  OR?: MetadataPatientSexWhereInput[] | null;
  NOT?: MetadataPatientSexWhereInput | null;
}

/**
 * Filters the "MetadataPersonInImage" nodes by specifying some conditions
 */
export interface MetadataPersonInImageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPersonInImageWhereInput[] | null;
  OR?: MetadataPersonInImageWhereInput[] | null;
  NOT?: MetadataPersonInImageWhereInput | null;
}

/**
 * Filters the "MetadataPhotoStorage" nodes by specifying some conditions
 */
export interface MetadataPhotoStorageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPhotoStorageWhereInput[] | null;
  OR?: MetadataPhotoStorageWhereInput[] | null;
  NOT?: MetadataPhotoStorageWhereInput | null;
}

/**
 * Filters the "MetadataPrepressPictureEditing" nodes by specifying some conditions
 */
export interface MetadataPrepressPictureEditingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPrepressPictureEditingWhereInput[] | null;
  OR?: MetadataPrepressPictureEditingWhereInput[] | null;
  NOT?: MetadataPrepressPictureEditingWhereInput | null;
}

/**
 * Filters the "MetadataPriceLevel" nodes by specifying some conditions
 */
export interface MetadataPriceLevelWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPriceLevelWhereInput[] | null;
  OR?: MetadataPriceLevelWhereInput[] | null;
  NOT?: MetadataPriceLevelWhereInput | null;
}

/**
 * Filters the "MetadataPrintingProfile" nodes by specifying some conditions
 */
export interface MetadataPrintingProfileWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPrintingProfileWhereInput[] | null;
  OR?: MetadataPrintingProfileWhereInput[] | null;
  NOT?: MetadataPrintingProfileWhereInput | null;
}

/**
 * Filters the "MetadataPrismaProduction" nodes by specifying some conditions
 */
export interface MetadataPrismaProductionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPrismaProductionWhereInput[] | null;
  OR?: MetadataPrismaProductionWhereInput[] | null;
  NOT?: MetadataPrismaProductionWhereInput | null;
}

/**
 * Filters the "MetadataProcessHistory" nodes by specifying some conditions
 */
export interface MetadataProcessHistoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProcessHistoryWhereInput[] | null;
  OR?: MetadataProcessHistoryWhereInput[] | null;
  NOT?: MetadataProcessHistoryWhereInput | null;
}

/**
 * Filters the "MetadataProcessParameter" nodes by specifying some conditions
 */
export interface MetadataProcessParameterWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProcessParameterWhereInput[] | null;
  OR?: MetadataProcessParameterWhereInput[] | null;
  NOT?: MetadataProcessParameterWhereInput | null;
}

/**
 * Filters the "MetadataProcessStatus" nodes by specifying some conditions
 */
export interface MetadataProcessStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProcessStatusWhereInput[] | null;
  OR?: MetadataProcessStatusWhereInput[] | null;
  NOT?: MetadataProcessStatusWhereInput | null;
}

/**
 * Filters the "MetadataProductShortName" nodes by specifying some conditions
 */
export interface MetadataProductShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProductShortNameWhereInput[] | null;
  OR?: MetadataProductShortNameWhereInput[] | null;
  NOT?: MetadataProductShortNameWhereInput | null;
}

/**
 * Filters the "MetadataProduct" nodes by specifying some conditions
 */
export interface MetadataProductWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProductWhereInput[] | null;
  OR?: MetadataProductWhereInput[] | null;
  NOT?: MetadataProductWhereInput | null;
}

/**
 * Filters the "MetadataProducts" nodes by specifying some conditions
 */
export interface MetadataProductsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProductsWhereInput[] | null;
  OR?: MetadataProductsWhereInput[] | null;
  NOT?: MetadataProductsWhereInput | null;
}

/**
 * Filters the "MetadataProduits" nodes by specifying some conditions
 */
export interface MetadataProduitsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProduitsWhereInput[] | null;
  OR?: MetadataProduitsWhereInput[] | null;
  NOT?: MetadataProduitsWhereInput | null;
}

/**
 * Filters the "MetadataProgramVersion" nodes by specifying some conditions
 */
export interface MetadataProgramVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProgramVersionWhereInput[] | null;
  OR?: MetadataProgramVersionWhereInput[] | null;
  NOT?: MetadataProgramVersionWhereInput | null;
}

/**
 * Filters the "MetadataPropertyReleaseId" nodes by specifying some conditions
 */
export interface MetadataPropertyReleaseIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPropertyReleaseIdWhereInput[] | null;
  OR?: MetadataPropertyReleaseIdWhereInput[] | null;
  NOT?: MetadataPropertyReleaseIdWhereInput | null;
}

/**
 * Filters the "MetadataPropertyReleaseStatus" nodes by specifying some conditions
 */
export interface MetadataPropertyReleaseStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPropertyReleaseStatusWhereInput[] | null;
  OR?: MetadataPropertyReleaseStatusWhereInput[] | null;
  NOT?: MetadataPropertyReleaseStatusWhereInput | null;
}

/**
 * Filters the "MetadataPublisher" nodes by specifying some conditions
 */
export interface MetadataPublisherWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPublisherWhereInput[] | null;
  OR?: MetadataPublisherWhereInput[] | null;
  NOT?: MetadataPublisherWhereInput | null;
}

/**
 * Filters the "MetadataPublishingIssue" nodes by specifying some conditions
 */
export interface MetadataPublishingIssueWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPublishingIssueWhereInput[] | null;
  OR?: MetadataPublishingIssueWhereInput[] | null;
  NOT?: MetadataPublishingIssueWhereInput | null;
}

/**
 * Filters the "MetadataPublishingSubject" nodes by specifying some conditions
 */
export interface MetadataPublishingSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPublishingSubjectWhereInput[] | null;
  OR?: MetadataPublishingSubjectWhereInput[] | null;
  NOT?: MetadataPublishingSubjectWhereInput | null;
}

/**
 * Filters the "MetadataQualifiedUsePrismaBy" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaByWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaByWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaByWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaByWhereInput | null;
}

/**
 * Filters the "MetadataQualifiedUsePrismaDate" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaDateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaDateWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaDateWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaDateWhereInput | null;
}

/**
 * Filters the "MetadataQualifiedUsePrismaDuration" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaDurationWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaDurationWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaDurationWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaDurationWhereInput | null;
}

/**
 * Filters the "MetadataQualifiedUsePrismaSupport" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaSupportWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaSupportWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaSupportWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaSupportWhereInput | null;
}

/**
 * Filters the "MetadataRating" nodes by specifying some conditions
 */
export interface MetadataRatingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRatingWhereInput[] | null;
  OR?: MetadataRatingWhereInput[] | null;
  NOT?: MetadataRatingWhereInput | null;
}

/**
 * Filters the "MetadataReferenceDate" nodes by specifying some conditions
 */
export interface MetadataReferenceDateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReferenceDateWhereInput[] | null;
  OR?: MetadataReferenceDateWhereInput[] | null;
  NOT?: MetadataReferenceDateWhereInput | null;
}

/**
 * Filters the "MetadataReferenceNumber" nodes by specifying some conditions
 */
export interface MetadataReferenceNumberWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReferenceNumberWhereInput[] | null;
  OR?: MetadataReferenceNumberWhereInput[] | null;
  NOT?: MetadataReferenceNumberWhereInput | null;
}

/**
 * Filters the "MetadataReferenceService" nodes by specifying some conditions
 */
export interface MetadataReferenceServiceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReferenceServiceWhereInput[] | null;
  OR?: MetadataReferenceServiceWhereInput[] | null;
  NOT?: MetadataReferenceServiceWhereInput | null;
}

/**
 * Filters the "MetadataReleaseDate" nodes by specifying some conditions
 */
export interface MetadataReleaseDateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReleaseDateWhereInput[] | null;
  OR?: MetadataReleaseDateWhereInput[] | null;
  NOT?: MetadataReleaseDateWhereInput | null;
}

/**
 * Filters the "MetadataReleaseTime" nodes by specifying some conditions
 */
export interface MetadataReleaseTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReleaseTimeWhereInput[] | null;
  OR?: MetadataReleaseTimeWhereInput[] | null;
  NOT?: MetadataReleaseTimeWhereInput | null;
}

/**
 * Filters the "MetadataRequiredPermission" nodes by specifying some conditions
 */
export interface MetadataRequiredPermissionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRequiredPermissionWhereInput[] | null;
  OR?: MetadataRequiredPermissionWhereInput[] | null;
  NOT?: MetadataRequiredPermissionWhereInput | null;
}

/**
 * Filters the "MetadataResolutionKind" nodes by specifying some conditions
 */
export interface MetadataResolutionKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataResolutionKindWhereInput[] | null;
  OR?: MetadataResolutionKindWhereInput[] | null;
  NOT?: MetadataResolutionKindWhereInput | null;
}

/**
 * Filters the "MetadataRights" nodes by specifying some conditions
 */
export interface MetadataRightsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRightsWhereInput[] | null;
  OR?: MetadataRightsWhereInput[] | null;
  NOT?: MetadataRightsWhereInput | null;
}

/**
 * Filters the "MetadataRoyaltyfree" nodes by specifying some conditions
 */
export interface MetadataRoyaltyfreeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRoyaltyfreeWhereInput[] | null;
  OR?: MetadataRoyaltyfreeWhereInput[] | null;
  NOT?: MetadataRoyaltyfreeWhereInput | null;
}

/**
 * Filters the "MetadataScene" nodes by specifying some conditions
 */
export interface MetadataSceneWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSceneWhereInput[] | null;
  OR?: MetadataSceneWhereInput[] | null;
  NOT?: MetadataSceneWhereInput | null;
}

/**
 * Filters the "MetadataSection" nodes by specifying some conditions
 */
export interface MetadataSectionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSectionWhereInput[] | null;
  OR?: MetadataSectionWhereInput[] | null;
  NOT?: MetadataSectionWhereInput | null;
}

/**
 * Filters the "MetadataSentTo" nodes by specifying some conditions
 */
export interface MetadataSentToWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSentToWhereInput[] | null;
  OR?: MetadataSentToWhereInput[] | null;
  NOT?: MetadataSentToWhereInput | null;
}

/**
 * Filters the "MetadataSerialNumber" nodes by specifying some conditions
 */
export interface MetadataSerialNumberWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSerialNumberWhereInput[] | null;
  OR?: MetadataSerialNumberWhereInput[] | null;
  NOT?: MetadataSerialNumberWhereInput | null;
}

/**
 * Filters the "MetadataSeriesDateTime" nodes by specifying some conditions
 */
export interface MetadataSeriesDateTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesDateTimeWhereInput[] | null;
  OR?: MetadataSeriesDateTimeWhereInput[] | null;
  NOT?: MetadataSeriesDateTimeWhereInput | null;
}

/**
 * Filters the "MetadataSeriesDescription" nodes by specifying some conditions
 */
export interface MetadataSeriesDescriptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesDescriptionWhereInput[] | null;
  OR?: MetadataSeriesDescriptionWhereInput[] | null;
  NOT?: MetadataSeriesDescriptionWhereInput | null;
}

/**
 * Filters the "MetadataSeriesModality" nodes by specifying some conditions
 */
export interface MetadataSeriesModalityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesModalityWhereInput[] | null;
  OR?: MetadataSeriesModalityWhereInput[] | null;
  NOT?: MetadataSeriesModalityWhereInput | null;
}

/**
 * Filters the "MetadataSeriesNumber" nodes by specifying some conditions
 */
export interface MetadataSeriesNumberWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesNumberWhereInput[] | null;
  OR?: MetadataSeriesNumberWhereInput[] | null;
  NOT?: MetadataSeriesNumberWhereInput | null;
}

/**
 * Filters the "MetadataShortUniqueId" nodes by specifying some conditions
 */
export interface MetadataShortUniqueIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataShortUniqueIdWhereInput[] | null;
  OR?: MetadataShortUniqueIdWhereInput[] | null;
  NOT?: MetadataShortUniqueIdWhereInput | null;
}

/**
 * Filters the "MetadataSource" nodes by specifying some conditions
 */
export interface MetadataSourceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSourceWhereInput[] | null;
  OR?: MetadataSourceWhereInput[] | null;
  NOT?: MetadataSourceWhereInput | null;
}

/**
 * Filters the "MetadataState" nodes by specifying some conditions
 */
export interface MetadataStateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStateWhereInput[] | null;
  OR?: MetadataStateWhereInput[] | null;
  NOT?: MetadataStateWhereInput | null;
}

/**
 * Filters the "MetadataStudyDateTime" nodes by specifying some conditions
 */
export interface MetadataStudyDateTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyDateTimeWhereInput[] | null;
  OR?: MetadataStudyDateTimeWhereInput[] | null;
  NOT?: MetadataStudyDateTimeWhereInput | null;
}

/**
 * Filters the "MetadataStudyDescription" nodes by specifying some conditions
 */
export interface MetadataStudyDescriptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyDescriptionWhereInput[] | null;
  OR?: MetadataStudyDescriptionWhereInput[] | null;
  NOT?: MetadataStudyDescriptionWhereInput | null;
}

/**
 * Filters the "MetadataStudyId" nodes by specifying some conditions
 */
export interface MetadataStudyIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyIdWhereInput[] | null;
  OR?: MetadataStudyIdWhereInput[] | null;
  NOT?: MetadataStudyIdWhereInput | null;
}

/**
 * Filters the "MetadataStudyPhysician" nodes by specifying some conditions
 */
export interface MetadataStudyPhysicianWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyPhysicianWhereInput[] | null;
  OR?: MetadataStudyPhysicianWhereInput[] | null;
  NOT?: MetadataStudyPhysicianWhereInput | null;
}

/**
 * Filters the "MetadataSubjectCode" nodes by specifying some conditions
 */
export interface MetadataSubjectCodeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSubjectCodeWhereInput[] | null;
  OR?: MetadataSubjectCodeWhereInput[] | null;
  NOT?: MetadataSubjectCodeWhereInput | null;
}

/**
 * Filters the "MetadataSubjectPrisma" nodes by specifying some conditions
 */
export interface MetadataSubjectPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSubjectPrismaWhereInput[] | null;
  OR?: MetadataSubjectPrismaWhereInput[] | null;
  NOT?: MetadataSubjectPrismaWhereInput | null;
}

/**
 * Filters the "MetadataSubject" nodes by specifying some conditions
 */
export interface MetadataSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSubjectWhereInput[] | null;
  OR?: MetadataSubjectWhereInput[] | null;
  NOT?: MetadataSubjectWhereInput | null;
}

/**
 * Filters the "MetadataSupplementalCategories" nodes by specifying some conditions
 */
export interface MetadataSupplementalCategoriesWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSupplementalCategoriesWhereInput[] | null;
  OR?: MetadataSupplementalCategoriesWhereInput[] | null;
  NOT?: MetadataSupplementalCategoriesWhereInput | null;
}

/**
 * Filters the "MetadataTargetVersion" nodes by specifying some conditions
 */
export interface MetadataTargetVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTargetVersionWhereInput[] | null;
  OR?: MetadataTargetVersionWhereInput[] | null;
  NOT?: MetadataTargetVersionWhereInput | null;
}

/**
 * Filters the "MetadataTitle" nodes by specifying some conditions
 */
export interface MetadataTitleWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTitleWhereInput[] | null;
  OR?: MetadataTitleWhereInput[] | null;
  NOT?: MetadataTitleWhereInput | null;
}

/**
 * Filters the "MetadataToneCurve" nodes by specifying some conditions
 */
export interface MetadataToneCurveWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataToneCurveWhereInput[] | null;
  OR?: MetadataToneCurveWhereInput[] | null;
  NOT?: MetadataToneCurveWhereInput | null;
}

/**
 * Filters the "MetadataTransferredByEmail" nodes by specifying some conditions
 */
export interface MetadataTransferredByEmailWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransferredByEmailWhereInput[] | null;
  OR?: MetadataTransferredByEmailWhereInput[] | null;
  NOT?: MetadataTransferredByEmailWhereInput | null;
}

/**
 * Filters the "MetadataTransferredByFullName" nodes by specifying some conditions
 */
export interface MetadataTransferredByFullNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransferredByFullNameWhereInput[] | null;
  OR?: MetadataTransferredByFullNameWhereInput[] | null;
  NOT?: MetadataTransferredByFullNameWhereInput | null;
}

/**
 * Filters the "MetadataTransferredBy" nodes by specifying some conditions
 */
export interface MetadataTransferredByWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransferredByWhereInput[] | null;
  OR?: MetadataTransferredByWhereInput[] | null;
  NOT?: MetadataTransferredByWhereInput | null;
}

/**
 * Filters the "MetadataTransmissionReference" nodes by specifying some conditions
 */
export interface MetadataTransmissionReferenceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransmissionReferenceWhereInput[] | null;
  OR?: MetadataTransmissionReferenceWhereInput[] | null;
  NOT?: MetadataTransmissionReferenceWhereInput | null;
}

/**
 * Filters the "MetadataType" nodes by specifying some conditions
 */
export interface MetadataTypeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTypeWhereInput[] | null;
  OR?: MetadataTypeWhereInput[] | null;
  NOT?: MetadataTypeWhereInput | null;
}

/**
 * Filters the "MetadataUnderUnderSubject" nodes by specifying some conditions
 */
export interface MetadataUnderUnderSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUnderUnderSubjectWhereInput[] | null;
  OR?: MetadataUnderUnderSubjectWhereInput[] | null;
  NOT?: MetadataUnderUnderSubjectWhereInput | null;
}

/**
 * Filters the "MetadataUndersubject" nodes by specifying some conditions
 */
export interface MetadataUndersubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUndersubjectWhereInput[] | null;
  OR?: MetadataUndersubjectWhereInput[] | null;
  NOT?: MetadataUndersubjectWhereInput | null;
}

/**
 * Filters the "MetadataUniqueId" nodes by specifying some conditions
 */
export interface MetadataUniqueIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUniqueIdWhereInput[] | null;
  OR?: MetadataUniqueIdWhereInput[] | null;
  NOT?: MetadataUniqueIdWhereInput | null;
}

/**
 * Filters the "MetadataUnitShortName" nodes by specifying some conditions
 */
export interface MetadataUnitShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUnitShortNameWhereInput[] | null;
  OR?: MetadataUnitShortNameWhereInput[] | null;
  NOT?: MetadataUnitShortNameWhereInput | null;
}

/**
 * Filters the "MetadataUnit" nodes by specifying some conditions
 */
export interface MetadataUnitWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUnitWhereInput[] | null;
  OR?: MetadataUnitWhereInput[] | null;
  NOT?: MetadataUnitWhereInput | null;
}

/**
 * Filters the "MetadataUploadTime" nodes by specifying some conditions
 */
export interface MetadataUploadTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUploadTimeWhereInput[] | null;
  OR?: MetadataUploadTimeWhereInput[] | null;
  NOT?: MetadataUploadTimeWhereInput | null;
}

/**
 * Filters the "MetadataUploadedByFullName" nodes by specifying some conditions
 */
export interface MetadataUploadedByFullNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUploadedByFullNameWhereInput[] | null;
  OR?: MetadataUploadedByFullNameWhereInput[] | null;
  NOT?: MetadataUploadedByFullNameWhereInput | null;
}

/**
 * Filters the "MetadataUploadedBy" nodes by specifying some conditions
 */
export interface MetadataUploadedByWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUploadedByWhereInput[] | null;
  OR?: MetadataUploadedByWhereInput[] | null;
  NOT?: MetadataUploadedByWhereInput | null;
}

/**
 * Filters the "MetadataUrgency" nodes by specifying some conditions
 */
export interface MetadataUrgencyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUrgencyWhereInput[] | null;
  OR?: MetadataUrgencyWhereInput[] | null;
  NOT?: MetadataUrgencyWhereInput | null;
}

/**
 * Filters the "MetadataUsageTerms" nodes by specifying some conditions
 */
export interface MetadataUsageTermsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUsageTermsWhereInput[] | null;
  OR?: MetadataUsageTermsWhereInput[] | null;
  NOT?: MetadataUsageTermsWhereInput | null;
}

/**
 * Filters the "MetadataUserComment" nodes by specifying some conditions
 */
export interface MetadataUserCommentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserCommentWhereInput[] | null;
  OR?: MetadataUserCommentWhereInput[] | null;
  NOT?: MetadataUserCommentWhereInput | null;
}

/**
 * Filters the "MetadataUserDefined195" nodes by specifying some conditions
 */
export interface MetadataUserDefined195WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined195WhereInput[] | null;
  OR?: MetadataUserDefined195WhereInput[] | null;
  NOT?: MetadataUserDefined195WhereInput | null;
}

/**
 * Filters the "MetadataUserDefined237" nodes by specifying some conditions
 */
export interface MetadataUserDefined237WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined237WhereInput[] | null;
  OR?: MetadataUserDefined237WhereInput[] | null;
  NOT?: MetadataUserDefined237WhereInput | null;
}

/**
 * Filters the "MetadataUserDefined238" nodes by specifying some conditions
 */
export interface MetadataUserDefined238WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined238WhereInput[] | null;
  OR?: MetadataUserDefined238WhereInput[] | null;
  NOT?: MetadataUserDefined238WhereInput | null;
}

/**
 * Filters the "MetadataUserDefined239" nodes by specifying some conditions
 */
export interface MetadataUserDefined239WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined239WhereInput[] | null;
  OR?: MetadataUserDefined239WhereInput[] | null;
  NOT?: MetadataUserDefined239WhereInput | null;
}

/**
 * Filters the "MetadataUserDefined242" nodes by specifying some conditions
 */
export interface MetadataUserDefined242WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined242WhereInput[] | null;
  OR?: MetadataUserDefined242WhereInput[] | null;
  NOT?: MetadataUserDefined242WhereInput | null;
}

/**
 * Filters the "MetadataUserDefined62" nodes by specifying some conditions
 */
export interface MetadataUserDefined62WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined62WhereInput[] | null;
  OR?: MetadataUserDefined62WhereInput[] | null;
  NOT?: MetadataUserDefined62WhereInput | null;
}

/**
 * Filters the "MetadataValid" nodes by specifying some conditions
 */
export interface MetadataValidWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataValidWhereInput[] | null;
  OR?: MetadataValidWhereInput[] | null;
  NOT?: MetadataValidWhereInput | null;
}

/**
 * Filters the "MetadataVersion" nodes by specifying some conditions
 */
export interface MetadataVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataVersionWhereInput[] | null;
  OR?: MetadataVersionWhereInput[] | null;
  NOT?: MetadataVersionWhereInput | null;
}

/**
 * Filters the "MetadataWebStatement" nodes by specifying some conditions
 */
export interface MetadataWebStatementWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataWebStatementWhereInput[] | null;
  OR?: MetadataWebStatementWhereInput[] | null;
  NOT?: MetadataWebStatementWhereInput | null;
}

/**
 * Filters the "Metadata" nodes by specifying some conditions
 */
export interface MetadataWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  name?: MetadataNameEnumType | null;
  name_not?: MetadataNameEnumType | null;
  name_in?: MetadataNameEnumType[] | null;
  name_not_in?: MetadataNameEnumType[] | null;
  legacyName?: string | null;
  legacyName_not?: string | null;
  legacyName_is_null?: boolean | null;
  legacyName_in?: (string | null)[] | null;
  legacyName_not_in?: (string | null)[] | null;
  legacyName_contains?: string | null;
  legacyName_not_contains?: string | null;
  legacyName_starts_with?: string | null;
  legacyName_not_starts_with?: string | null;
  legacyName_ends_with?: string | null;
  legacyName_not_ends_with?: string | null;
  legacyName_lt?: string | null;
  legacyName_lte?: string | null;
  legacyName_gt?: string | null;
  legacyName_gte?: string | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  namespace?: string | null;
  namespace_not?: string | null;
  namespace_in?: string[] | null;
  namespace_not_in?: string[] | null;
  namespace_contains?: string | null;
  namespace_not_contains?: string | null;
  namespace_starts_with?: string | null;
  namespace_not_starts_with?: string | null;
  namespace_ends_with?: string | null;
  namespace_not_ends_with?: string | null;
  namespace_lt?: string | null;
  namespace_lte?: string | null;
  namespace_gt?: string | null;
  namespace_gte?: string | null;
  structName?: string | null;
  structName_not?: string | null;
  structName_is_null?: boolean | null;
  structName_in?: (string | null)[] | null;
  structName_not_in?: (string | null)[] | null;
  structName_contains?: string | null;
  structName_not_contains?: string | null;
  structName_starts_with?: string | null;
  structName_not_starts_with?: string | null;
  structName_ends_with?: string | null;
  structName_not_ends_with?: string | null;
  structName_lt?: string | null;
  structName_lte?: string | null;
  structName_gt?: string | null;
  structName_gte?: string | null;
  type?: MetadataValueTypeEnum | null;
  type_not?: MetadataValueTypeEnum | null;
  type_in?: MetadataValueTypeEnum[] | null;
  type_not_in?: MetadataValueTypeEnum[] | null;
  photoMetadatas_some?: PhotoMetadataWhereInput | null;
  metadataSerialNumberList_some?: MetadataSerialNumberWhereInput | null;
  metadataAttributionNameList_some?: MetadataAttributionNameWhereInput | null;
  metadataAttributionUrlList_some?: MetadataAttributionUrlWhereInput | null;
  metadataLicenseList_some?: MetadataLicenseWhereInput | null;
  metadataMorePermissionsList_some?: MetadataMorePermissionsWhereInput | null;
  metadataPaintBasedCorrectionsList_some?: MetadataPaintBasedCorrectionsWhereInput | null;
  metadataToneCurveList_some?: MetadataToneCurveWhereInput | null;
  metadataCreatorList_some?: MetadataCreatorWhereInput | null;
  metadataDescriptionList_some?: MetadataDescriptionWhereInput | null;
  metadataFormatList_some?: MetadataFormatWhereInput | null;
  metadataPageList_some?: MetadataPageWhereInput | null;
  metadataPublisherList_some?: MetadataPublisherWhereInput | null;
  metadataRightsList_some?: MetadataRightsWhereInput | null;
  metadataSubjectList_some?: MetadataSubjectWhereInput | null;
  metadataTitleList_some?: MetadataTitleWhereInput | null;
  metadataTypeList_some?: MetadataTypeWhereInput | null;
  metadataAccessRightsList_some?: MetadataAccessRightsWhereInput | null;
  metadataAudienceList_some?: MetadataAudienceWhereInput | null;
  metadataValidList_some?: MetadataValidWhereInput | null;
  metadataEquipmentInstitutionList_some?: MetadataEquipmentInstitutionWhereInput | null;
  metadataEquipmentManufacturerList_some?: MetadataEquipmentManufacturerWhereInput | null;
  metadataFileInfoBitsPerPixelList_some?: MetadataFileInfoBitsPerPixelWhereInput | null;
  metadataFileInfoFramesList_some?: MetadataFileInfoFramesWhereInput | null;
  metadataFileInfoPhotometricInterpretationList_some?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
  metadataFileInfoTransferSyntaxList_some?: MetadataFileInfoTransferSyntaxWhereInput | null;
  metadataPatientDobList_some?: MetadataPatientDobWhereInput | null;
  metadataPatientIdList_some?: MetadataPatientIdWhereInput | null;
  metadataPatientNameList_some?: MetadataPatientNameWhereInput | null;
  metadataPatientSexList_some?: MetadataPatientSexWhereInput | null;
  metadataSeriesDateTimeList_some?: MetadataSeriesDateTimeWhereInput | null;
  metadataSeriesDescriptionList_some?: MetadataSeriesDescriptionWhereInput | null;
  metadataSeriesModalityList_some?: MetadataSeriesModalityWhereInput | null;
  metadataSeriesNumberList_some?: MetadataSeriesNumberWhereInput | null;
  metadataStudyDateTimeList_some?: MetadataStudyDateTimeWhereInput | null;
  metadataStudyDescriptionList_some?: MetadataStudyDescriptionWhereInput | null;
  metadataStudyIdList_some?: MetadataStudyIdWhereInput | null;
  metadataStudyPhysicianList_some?: MetadataStudyPhysicianWhereInput | null;
  metadataDateTimeDigitizedList_some?: MetadataDateTimeDigitizedWhereInput | null;
  metadataDateTimeOriginalList_some?: MetadataDateTimeOriginalWhereInput | null;
  metadataGpsLatitudeList_some?: MetadataGpsLatitudeWhereInput | null;
  metadataGpsLongitudeList_some?: MetadataGpsLongitudeWhereInput | null;
  metadataGpsProcessingMethodList_some?: MetadataGpsProcessingMethodWhereInput | null;
  metadataImageUniqueIdList_some?: MetadataImageUniqueIdWhereInput | null;
  metadataIsoSpeedRatingsList_some?: MetadataIsoSpeedRatingsWhereInput | null;
  metadataUserCommentList_some?: MetadataUserCommentWhereInput | null;
  metadataCustomField14List_some?: MetadataCustomField14WhereInput | null;
  metadataCustomField15List_some?: MetadataCustomField15WhereInput | null;
  metadataCustomField16List_some?: MetadataCustomField16WhereInput | null;
  metadataCustomField17List_some?: MetadataCustomField17WhereInput | null;
  metadataCustomField20List_some?: MetadataCustomField20WhereInput | null;
  metadataCustomField5List_some?: MetadataCustomField5WhereInput | null;
  metadataCustomField6List_some?: MetadataCustomField6WhereInput | null;
  metadataCustomField7List_some?: MetadataCustomField7WhereInput | null;
  metadataCustomField9List_some?: MetadataCustomField9WhereInput | null;
  metadataCreatedTimeList_some?: MetadataCreatedTimeWhereInput | null;
  metadataEditStatusList_some?: MetadataEditStatusWhereInput | null;
  metadataFixtureIdentifierList_some?: MetadataFixtureIdentifierWhereInput | null;
  metadataLocalCaptionList_some?: MetadataLocalCaptionWhereInput | null;
  metadataProgramVersionList_some?: MetadataProgramVersionWhereInput | null;
  metadataReferenceDateList_some?: MetadataReferenceDateWhereInput | null;
  metadataReferenceNumberList_some?: MetadataReferenceNumberWhereInput | null;
  metadataReferenceServiceList_some?: MetadataReferenceServiceWhereInput | null;
  metadataReleaseDateList_some?: MetadataReleaseDateWhereInput | null;
  metadataReleaseTimeList_some?: MetadataReleaseTimeWhereInput | null;
  metadataAssetStateList_some?: MetadataAssetStateWhereInput | null;
  metadataClassifyList_some?: MetadataClassifyWhereInput | null;
  metadataContainedInList_some?: MetadataContainedInWhereInput | null;
  metadataContentValueList_some?: MetadataContentValueWhereInput | null;
  metadataDocumentTextList_some?: MetadataDocumentTextWhereInput | null;
  metadataExifList_some?: MetadataExifWhereInput | null;
  metadataHistoryList_some?: MetadataHistoryWhereInput | null;
  metadataImageNotesList_some?: MetadataImageNotesWhereInput | null;
  metadataJobIdList_some?: MetadataJobIdWhereInput | null;
  metadataLinksList_some?: MetadataLinksWhereInput | null;
  metadataMasterDocumentIdList_some?: MetadataMasterDocumentIdWhereInput | null;
  metadataObjectCycleList_some?: MetadataObjectCycleWhereInput | null;
  metadataOwnerIdList_some?: MetadataOwnerIdWhereInput | null;
  metadataShortUniqueIdList_some?: MetadataShortUniqueIdWhereInput | null;
  metadataTransferredByList_some?: MetadataTransferredByWhereInput | null;
  metadataTransferredByEmailList_some?: MetadataTransferredByEmailWhereInput | null;
  metadataTransferredByFullNameList_some?: MetadataTransferredByFullNameWhereInput | null;
  metadataUniqueIdList_some?: MetadataUniqueIdWhereInput | null;
  metadataUploadedByList_some?: MetadataUploadedByWhereInput | null;
  metadataUploadedByFullNameList_some?: MetadataUploadedByFullNameWhereInput | null;
  metadataUploadTimeList_some?: MetadataUploadTimeWhereInput | null;
  metadataUserDefined195List_some?: MetadataUserDefined195WhereInput | null;
  metadataUserDefined237List_some?: MetadataUserDefined237WhereInput | null;
  metadataUserDefined238List_some?: MetadataUserDefined238WhereInput | null;
  metadataUserDefined239List_some?: MetadataUserDefined239WhereInput | null;
  metadataUserDefined242List_some?: MetadataUserDefined242WhereInput | null;
  metadataUserDefined62List_some?: MetadataUserDefined62WhereInput | null;
  metadataCiAdrCityList_some?: MetadataCiAdrCityWhereInput | null;
  metadataCiAdrCtryList_some?: MetadataCiAdrCtryWhereInput | null;
  metadataCiAdrExtadrList_some?: MetadataCiAdrExtadrWhereInput | null;
  metadataCiAdrPcodeList_some?: MetadataCiAdrPcodeWhereInput | null;
  metadataCiAdrRegionList_some?: MetadataCiAdrRegionWhereInput | null;
  metadataCiEmailWorkList_some?: MetadataCiEmailWorkWhereInput | null;
  metadataCiTelWorkList_some?: MetadataCiTelWorkWhereInput | null;
  metadataCiUrlWorkList_some?: MetadataCiUrlWorkWhereInput | null;
  metadataCountryCodeList_some?: MetadataCountryCodeWhereInput | null;
  metadataIntellectualGenreList_some?: MetadataIntellectualGenreWhereInput | null;
  metadataLocationList_some?: MetadataLocationWhereInput | null;
  metadataSceneList_some?: MetadataSceneWhereInput | null;
  metadataSubjectCodeList_some?: MetadataSubjectCodeWhereInput | null;
  metadataAddlModelInfoList_some?: MetadataAddlModelInfoWhereInput | null;
  metadataEventList_some?: MetadataEventWhereInput | null;
  metadataMaxAvailHeightList_some?: MetadataMaxAvailHeightWhereInput | null;
  metadataMaxAvailWidthList_some?: MetadataMaxAvailWidthWhereInput | null;
  metadataModelAgeList_some?: MetadataModelAgeWhereInput | null;
  metadataOrganisationInImageNameList_some?: MetadataOrganisationInImageNameWhereInput | null;
  metadataPersonInImageList_some?: MetadataPersonInImageWhereInput | null;
  metadataAuthorsPositionList_some?: MetadataAuthorsPositionWhereInput | null;
  metadataCaptionWriterList_some?: MetadataCaptionWriterWhereInput | null;
  metadataCategoryList_some?: MetadataCategoryWhereInput | null;
  metadataCityList_some?: MetadataCityWhereInput | null;
  metadataCountryList_some?: MetadataCountryWhereInput | null;
  metadataCreditList_some?: MetadataCreditWhereInput | null;
  metadataDateCreatedList_some?: MetadataDateCreatedWhereInput | null;
  metadataHeadlineList_some?: MetadataHeadlineWhereInput | null;
  metadataIccProfileList_some?: MetadataIccProfileWhereInput | null;
  metadataInstructionsList_some?: MetadataInstructionsWhereInput | null;
  metadataSourceList_some?: MetadataSourceWhereInput | null;
  metadataStateList_some?: MetadataStateWhereInput | null;
  metadataSupplementalCategoriesList_some?: MetadataSupplementalCategoriesWhereInput | null;
  metadataTransmissionReferenceList_some?: MetadataTransmissionReferenceWhereInput | null;
  metadataUrgencyList_some?: MetadataUrgencyWhereInput | null;
  metadataMinorModelAgeDisclosureList_some?: MetadataMinorModelAgeDisclosureWhereInput | null;
  metadataModelReleaseIdList_some?: MetadataModelReleaseIdWhereInput | null;
  metadataModelReleaseStatusList_some?: MetadataModelReleaseStatusWhereInput | null;
  metadataPropertyReleaseIdList_some?: MetadataPropertyReleaseIdWhereInput | null;
  metadataPropertyReleaseStatusList_some?: MetadataPropertyReleaseStatusWhereInput | null;
  metadataVersionList_some?: MetadataVersionWhereInput | null;
  metadataBrandList_some?: MetadataBrandWhereInput | null;
  metadataDivisionList_some?: MetadataDivisionWhereInput | null;
  metadataHeaderList_some?: MetadataHeaderWhereInput | null;
  metadataProductsList_some?: MetadataProductsWhereInput | null;
  metadataProduitsList_some?: MetadataProduitsWhereInput | null;
  metadataPublishingIssueList_some?: MetadataPublishingIssueWhereInput | null;
  metadataPublishingSubjectList_some?: MetadataPublishingSubjectWhereInput | null;
  metadataCompanyList_some?: MetadataCompanyWhereInput | null;
  metadataCompanyShortNameList_some?: MetadataCompanyShortNameWhereInput | null;
  metadataConfidentialityList_some?: MetadataConfidentialityWhereInput | null;
  metadataEditorialVersionList_some?: MetadataEditorialVersionWhereInput | null;
  metadataFileCheckForList_some?: MetadataFileCheckForWhereInput | null;
  metadataFileStageList_some?: MetadataFileStageWhereInput | null;
  metadataFileStatusList_some?: MetadataFileStatusWhereInput | null;
  metadataSentToList_some?: MetadataSentToWhereInput | null;
  metadataTargetVersionList_some?: MetadataTargetVersionWhereInput | null;
  metadataUnitList_some?: MetadataUnitWhereInput | null;
  metadataArchiveChildList_some?: MetadataArchiveChildWhereInput | null;
  metadataArchiveParentList_some?: MetadataArchiveParentWhereInput | null;
  metadataBackupNameList_some?: MetadataBackupNameWhereInput | null;
  metadataBitmapGrayscalePictureList_some?: MetadataBitmapGrayscalePictureWhereInput | null;
  metadataBrandPrismaList_some?: MetadataBrandPrismaWhereInput | null;
  metadataBrandShortNameList_some?: MetadataBrandShortNameWhereInput | null;
  metadataCategoryPrismaList_some?: MetadataCategoryPrismaWhereInput | null;
  metadataContentMediaKindList_some?: MetadataContentMediaKindWhereInput | null;
  metadataCopyrightLayerList_some?: MetadataCopyrightLayerWhereInput | null;
  metadataDeliveryAccountList_some?: MetadataDeliveryAccountWhereInput | null;
  metadataDeliveryCompanyList_some?: MetadataDeliveryCompanyWhereInput | null;
  metadataDeliveryCopyrightList_some?: MetadataDeliveryCopyrightWhereInput | null;
  metadataDeliveryDateFolderList_some?: MetadataDeliveryDateFolderWhereInput | null;
  metadataDeliveryDateTimeList_some?: MetadataDeliveryDateTimeWhereInput | null;
  metadataDeliveryDcCreatorList_some?: MetadataDeliveryDcCreatorWhereInput | null;
  metadataDeliveryDcRightsList_some?: MetadataDeliveryDcRightsWhereInput | null;
  metadataDeliveryFileTypeList_some?: MetadataDeliveryFileTypeWhereInput | null;
  metadataDeliveryFolderList_some?: MetadataDeliveryFolderWhereInput | null;
  metadataDeliveryKindList_some?: MetadataDeliveryKindWhereInput | null;
  metadataDeliveryPathList_some?: MetadataDeliveryPathWhereInput | null;
  metadataDeliveryPersonShownintheImageList_some?: MetadataDeliveryPersonShownintheImageWhereInput | null;
  metadataDeliveryPhotoshopCreditList_some?: MetadataDeliveryPhotoshopCreditWhereInput | null;
  metadataDeliveryPhotoshopSourceList_some?: MetadataDeliveryPhotoshopSourceWhereInput | null;
  metadataDeliveryServiceList_some?: MetadataDeliveryServiceWhereInput | null;
  metadataDeliverySubjectList_some?: MetadataDeliverySubjectWhereInput | null;
  metadataDeliveryUnderSubjectList_some?: MetadataDeliveryUnderSubjectWhereInput | null;
  metadataDepartmentList_some?: MetadataDepartmentWhereInput | null;
  metadataDigitalAssetUrlList_some?: MetadataDigitalAssetUrlWhereInput | null;
  metadataEditionList_some?: MetadataEditionWhereInput | null;
  metadataEnvironnementPhotoList_some?: MetadataEnvironnementPhotoWhereInput | null;
  metadataFabStorageList_some?: MetadataFabStorageWhereInput | null;
  metadataFileTypeList_some?: MetadataFileTypeWhereInput | null;
  metadataHeaderPrismaList_some?: MetadataHeaderPrismaWhereInput | null;
  metadataIdSubjectList_some?: MetadataIdSubjectWhereInput | null;
  metadataIdAssignmentList_some?: MetadataIdAssignmentWhereInput | null;
  metadataIdMediaContentList_some?: MetadataIdMediaContentWhereInput | null;
  metadataIdPhotoPrismaList_some?: MetadataIdPhotoPrismaWhereInput | null;
  metadataIssueList_some?: MetadataIssueWhereInput | null;
  metadataJobProcessingList_some?: MetadataJobProcessingWhereInput | null;
  metadataLayoutPictureEditingList_some?: MetadataLayoutPictureEditingWhereInput | null;
  metadataLayoutStorageList_some?: MetadataLayoutStorageWhereInput | null;
  metadataOriginalColorList_some?: MetadataOriginalColorWhereInput | null;
  metadataOriginalIccProfileList_some?: MetadataOriginalIccProfileWhereInput | null;
  metadataOriginalNameList_some?: MetadataOriginalNameWhereInput | null;
  metadataOriginalWeigthList_some?: MetadataOriginalWeigthWhereInput | null;
  metadataPagePrismaList_some?: MetadataPagePrismaWhereInput | null;
  metadataPhotoStorageList_some?: MetadataPhotoStorageWhereInput | null;
  metadataPrepressPictureEditingList_some?: MetadataPrepressPictureEditingWhereInput | null;
  metadataPriceLevelList_some?: MetadataPriceLevelWhereInput | null;
  metadataPrintingProfileList_some?: MetadataPrintingProfileWhereInput | null;
  metadataPrismaProductionList_some?: MetadataPrismaProductionWhereInput | null;
  metadataProcessHistoryList_some?: MetadataProcessHistoryWhereInput | null;
  metadataProcessParameterList_some?: MetadataProcessParameterWhereInput | null;
  metadataProcessStatusList_some?: MetadataProcessStatusWhereInput | null;
  metadataProductList_some?: MetadataProductWhereInput | null;
  metadataProductShortNameList_some?: MetadataProductShortNameWhereInput | null;
  metadataQualifiedUsePrismaByList_some?: MetadataQualifiedUsePrismaByWhereInput | null;
  metadataQualifiedUsePrismaDateList_some?: MetadataQualifiedUsePrismaDateWhereInput | null;
  metadataQualifiedUsePrismaDurationList_some?: MetadataQualifiedUsePrismaDurationWhereInput | null;
  metadataQualifiedUsePrismaSupportList_some?: MetadataQualifiedUsePrismaSupportWhereInput | null;
  metadataRequiredPermissionList_some?: MetadataRequiredPermissionWhereInput | null;
  metadataResolutionKindList_some?: MetadataResolutionKindWhereInput | null;
  metadataRoyaltyfreeList_some?: MetadataRoyaltyfreeWhereInput | null;
  metadataSectionList_some?: MetadataSectionWhereInput | null;
  metadataSubjectPrismaList_some?: MetadataSubjectPrismaWhereInput | null;
  metadataUndersubjectList_some?: MetadataUndersubjectWhereInput | null;
  metadataUnderUnderSubjectList_some?: MetadataUnderUnderSubjectWhereInput | null;
  metadataUnitShortNameList_some?: MetadataUnitShortNameWhereInput | null;
  metadataWorkflowKindList_some?: MetadataWorkflowKindWhereInput | null;
  metadataMakeList_some?: MetadataMakeWhereInput | null;
  metadataModelList_some?: MetadataModelWhereInput | null;
  metadataCreatorToolList_some?: MetadataCreatorToolWhereInput | null;
  metadataRatingList_some?: MetadataRatingWhereInput | null;
  metadataXmpFileStampsList_some?: MetadataXmpFileStampsWhereInput | null;
  metadataManifestList_some?: MetadataManifestWhereInput | null;
  metadataXmpHistoryList_some?: MetadataXmpHistoryWhereInput | null;
  metadataCertificateList_some?: MetadataCertificateWhereInput | null;
  metadataMarkedList_some?: MetadataMarkedWhereInput | null;
  metadataOwnerList_some?: MetadataOwnerWhereInput | null;
  metadataUsageTermsList_some?: MetadataUsageTermsWhereInput | null;
  metadataWebStatementList_some?: MetadataWebStatementWhereInput | null;
  AND?: MetadataWhereInput[] | null;
  OR?: MetadataWhereInput[] | null;
  NOT?: MetadataWhereInput | null;
}

/**
 * Filters the "MetadataWorkflowKind" nodes by specifying some conditions
 */
export interface MetadataWorkflowKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataWorkflowKindWhereInput[] | null;
  OR?: MetadataWorkflowKindWhereInput[] | null;
  NOT?: MetadataWorkflowKindWhereInput | null;
}

/**
 * Filters the "MetadataXmpFileStamps" nodes by specifying some conditions
 */
export interface MetadataXmpFileStampsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataXmpFileStampsWhereInput[] | null;
  OR?: MetadataXmpFileStampsWhereInput[] | null;
  NOT?: MetadataXmpFileStampsWhereInput | null;
}

/**
 * Filters the "MetadataXmpHistory" nodes by specifying some conditions
 */
export interface MetadataXmpHistoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataXmpHistoryWhereInput[] | null;
  OR?: MetadataXmpHistoryWhereInput[] | null;
  NOT?: MetadataXmpHistoryWhereInput | null;
}

/**
 * Filters the "PhotoMetadata" nodes by specifying some conditions
 */
export interface PhotoMetadataWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: PhotoMetadataWhereInput[] | null;
  OR?: PhotoMetadataWhereInput[] | null;
  NOT?: PhotoMetadataWhereInput | null;
}

/**
 * Filters the "PhotoPublication" nodes by specifying some conditions
 */
export interface PhotoPublicationWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  photoIntId?: number | null;
  photoIntId_not?: number | null;
  photoIntId_is_null?: boolean | null;
  photoIntId_in?: (number | null)[] | null;
  photoIntId_not_in?: (number | null)[] | null;
  photoIntId_lt?: number | null;
  photoIntId_lte?: number | null;
  photoIntId_gt?: number | null;
  photoIntId_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  printPublicationKey?: string | null;
  printPublicationKey_not?: string | null;
  printPublicationKey_is_null?: boolean | null;
  printPublicationKey_in?: (string | null)[] | null;
  printPublicationKey_not_in?: (string | null)[] | null;
  printPublicationKey_contains?: string | null;
  printPublicationKey_not_contains?: string | null;
  printPublicationKey_starts_with?: string | null;
  printPublicationKey_not_starts_with?: string | null;
  printPublicationKey_ends_with?: string | null;
  printPublicationKey_not_ends_with?: string | null;
  printPublicationKey_lt?: string | null;
  printPublicationKey_lte?: string | null;
  printPublicationKey_gt?: string | null;
  printPublicationKey_gte?: string | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_is_null?: boolean | null;
  filename_in?: (string | null)[] | null;
  filename_not_in?: (string | null)[] | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  printIssueTitle?: string | null;
  printIssueTitle_not?: string | null;
  printIssueTitle_is_null?: boolean | null;
  printIssueTitle_in?: (string | null)[] | null;
  printIssueTitle_not_in?: (string | null)[] | null;
  printIssueTitle_contains?: string | null;
  printIssueTitle_not_contains?: string | null;
  printIssueTitle_starts_with?: string | null;
  printIssueTitle_not_starts_with?: string | null;
  printIssueTitle_ends_with?: string | null;
  printIssueTitle_not_ends_with?: string | null;
  printIssueTitle_lt?: string | null;
  printIssueTitle_lte?: string | null;
  printIssueTitle_gt?: string | null;
  printIssueTitle_gte?: string | null;
  page?: number | null;
  page_not?: number | null;
  page_is_null?: boolean | null;
  page_in?: (number | null)[] | null;
  page_not_in?: (number | null)[] | null;
  page_lt?: number | null;
  page_lte?: number | null;
  page_gt?: number | null;
  page_gte?: number | null;
  dimensions?: string | null;
  dimensions_not?: string | null;
  dimensions_is_null?: boolean | null;
  dimensions_in?: (string | null)[] | null;
  dimensions_not_in?: (string | null)[] | null;
  dimensions_contains?: string | null;
  dimensions_not_contains?: string | null;
  dimensions_starts_with?: string | null;
  dimensions_not_starts_with?: string | null;
  dimensions_ends_with?: string | null;
  dimensions_not_ends_with?: string | null;
  dimensions_lt?: string | null;
  dimensions_lte?: string | null;
  dimensions_gt?: string | null;
  dimensions_gte?: string | null;
  credit?: string | null;
  credit_not?: string | null;
  credit_is_null?: boolean | null;
  credit_in?: (string | null)[] | null;
  credit_not_in?: (string | null)[] | null;
  credit_contains?: string | null;
  credit_not_contains?: string | null;
  credit_starts_with?: string | null;
  credit_not_starts_with?: string | null;
  credit_ends_with?: string | null;
  credit_not_ends_with?: string | null;
  credit_lt?: string | null;
  credit_lte?: string | null;
  credit_gt?: string | null;
  credit_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  photo?: PhotoWhereInput | null;
  AND?: PhotoPublicationWhereInput[] | null;
  OR?: PhotoPublicationWhereInput[] | null;
  NOT?: PhotoPublicationWhereInput | null;
}

/**
 * Filters the "Photo" nodes by specifying some conditions
 */
export interface PhotoWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  originalName?: string | null;
  originalName_not?: string | null;
  originalName_is_null?: boolean | null;
  originalName_in?: (string | null)[] | null;
  originalName_not_in?: (string | null)[] | null;
  originalName_contains?: string | null;
  originalName_not_contains?: string | null;
  originalName_starts_with?: string | null;
  originalName_not_starts_with?: string | null;
  originalName_ends_with?: string | null;
  originalName_not_ends_with?: string | null;
  originalName_lt?: string | null;
  originalName_lte?: string | null;
  originalName_gt?: string | null;
  originalName_gte?: string | null;
  filesize?: number | null;
  filesize_not?: number | null;
  filesize_is_null?: boolean | null;
  filesize_in?: (number | null)[] | null;
  filesize_not_in?: (number | null)[] | null;
  filesize_lt?: number | null;
  filesize_lte?: number | null;
  filesize_gt?: number | null;
  filesize_gte?: number | null;
  height?: number | null;
  height_not?: number | null;
  height_is_null?: boolean | null;
  height_in?: (number | null)[] | null;
  height_not_in?: (number | null)[] | null;
  height_lt?: number | null;
  height_lte?: number | null;
  height_gt?: number | null;
  height_gte?: number | null;
  width?: number | null;
  width_not?: number | null;
  width_is_null?: boolean | null;
  width_in?: (number | null)[] | null;
  width_not_in?: (number | null)[] | null;
  width_lt?: number | null;
  width_lte?: number | null;
  width_gt?: number | null;
  width_gte?: number | null;
  contentType?: string | null;
  contentType_not?: string | null;
  contentType_is_null?: boolean | null;
  contentType_in?: (string | null)[] | null;
  contentType_not_in?: (string | null)[] | null;
  contentType_contains?: string | null;
  contentType_not_contains?: string | null;
  contentType_starts_with?: string | null;
  contentType_not_starts_with?: string | null;
  contentType_ends_with?: string | null;
  contentType_not_ends_with?: string | null;
  contentType_lt?: string | null;
  contentType_lte?: string | null;
  contentType_gt?: string | null;
  contentType_gte?: string | null;
  isUploaded?: boolean | null;
  isUploaded_not?: boolean | null;
  isUploaded_is_null?: boolean | null;
  isUploaded_in?: (boolean | null)[] | null;
  isUploaded_not_in?: (boolean | null)[] | null;
  synced?: boolean | null;
  synced_not?: boolean | null;
  synced_is_null?: boolean | null;
  synced_in?: (boolean | null)[] | null;
  synced_not_in?: (boolean | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  importedAt?: GraphQL$DateTime | null;
  importedAt_not?: GraphQL$DateTime | null;
  importedAt_is_null?: boolean | null;
  importedAt_in?: (GraphQL$DateTime | null)[] | null;
  importedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  importedAt_lt?: GraphQL$DateTime | null;
  importedAt_lte?: GraphQL$DateTime | null;
  importedAt_gt?: GraphQL$DateTime | null;
  importedAt_gte?: GraphQL$DateTime | null;
  takenAt?: GraphQL$DateTime | null;
  takenAt_not?: GraphQL$DateTime | null;
  takenAt_is_null?: boolean | null;
  takenAt_in?: (GraphQL$DateTime | null)[] | null;
  takenAt_not_in?: (GraphQL$DateTime | null)[] | null;
  takenAt_lt?: GraphQL$DateTime | null;
  takenAt_lte?: GraphQL$DateTime | null;
  takenAt_gt?: GraphQL$DateTime | null;
  takenAt_gte?: GraphQL$DateTime | null;
  isLegacy?: boolean | null;
  isLegacy_not?: boolean | null;
  isLegacy_is_null?: boolean | null;
  isLegacy_in?: (boolean | null)[] | null;
  isLegacy_not_in?: (boolean | null)[] | null;
  archive?: ArchiveWhereInput | null;
  category?: CategoryWhereInput | null;
  category_is_null?: boolean | null;
  agency?: AgencyWhereInput | null;
  agency_is_null?: boolean | null;
  deliverySubject?: DeliverySubjectWhereInput | null;
  deliverySubject_is_null?: boolean | null;
  importQueue?: ImportQueueWhereInput | null;
  importQueue_is_null?: boolean | null;
  photoMetadatas_some?: PhotoMetadataWhereInput | null;
  photoPublications_some?: PhotoPublicationWhereInput | null;
  Metadata_SerialNumber_some?: MetadataSerialNumberWhereInput | null;
  Metadata_attributionName_some?: MetadataAttributionNameWhereInput | null;
  Metadata_attributionURL_some?: MetadataAttributionUrlWhereInput | null;
  Metadata_license_some?: MetadataLicenseWhereInput | null;
  Metadata_morePermissions_some?: MetadataMorePermissionsWhereInput | null;
  Metadata_PaintBasedCorrections_some?: MetadataPaintBasedCorrectionsWhereInput | null;
  Metadata_ToneCurve_some?: MetadataToneCurveWhereInput | null;
  Metadata_creator_some?: MetadataCreatorWhereInput | null;
  Metadata_description_some?: MetadataDescriptionWhereInput | null;
  Metadata_Format_some?: MetadataFormatWhereInput | null;
  Metadata_Page_some?: MetadataPageWhereInput | null;
  Metadata_Publisher_some?: MetadataPublisherWhereInput | null;
  Metadata_rights_some?: MetadataRightsWhereInput | null;
  Metadata_subject_some?: MetadataSubjectWhereInput | null;
  Metadata_title_some?: MetadataTitleWhereInput | null;
  Metadata_Type_some?: MetadataTypeWhereInput | null;
  Metadata_AccessRights_some?: MetadataAccessRightsWhereInput | null;
  Metadata_Audience_some?: MetadataAudienceWhereInput | null;
  Metadata_Valid_some?: MetadataValidWhereInput | null;
  Metadata_EquipmentInstitution_some?: MetadataEquipmentInstitutionWhereInput | null;
  Metadata_EquipmentManufacturer_some?: MetadataEquipmentManufacturerWhereInput | null;
  Metadata_FileInfoBitsPerPixel_some?: MetadataFileInfoBitsPerPixelWhereInput | null;
  Metadata_FileInfoFrames_some?: MetadataFileInfoFramesWhereInput | null;
  Metadata_FileInfoPhotometricInterpretation_some?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
  Metadata_FileInfoTransferSyntax_some?: MetadataFileInfoTransferSyntaxWhereInput | null;
  Metadata_PatientDOB_some?: MetadataPatientDobWhereInput | null;
  Metadata_PatientID_some?: MetadataPatientIdWhereInput | null;
  Metadata_PatientName_some?: MetadataPatientNameWhereInput | null;
  Metadata_PatientSex_some?: MetadataPatientSexWhereInput | null;
  Metadata_SeriesDateTime_some?: MetadataSeriesDateTimeWhereInput | null;
  Metadata_SeriesDescription_some?: MetadataSeriesDescriptionWhereInput | null;
  Metadata_SeriesModality_some?: MetadataSeriesModalityWhereInput | null;
  Metadata_SeriesNumber_some?: MetadataSeriesNumberWhereInput | null;
  Metadata_StudyDateTime_some?: MetadataStudyDateTimeWhereInput | null;
  Metadata_StudyDescription_some?: MetadataStudyDescriptionWhereInput | null;
  Metadata_StudyID_some?: MetadataStudyIdWhereInput | null;
  Metadata_StudyPhysician_some?: MetadataStudyPhysicianWhereInput | null;
  Metadata_DateTimeDigitized_some?: MetadataDateTimeDigitizedWhereInput | null;
  Metadata_DateTimeOriginal_some?: MetadataDateTimeOriginalWhereInput | null;
  Metadata_GPSLatitude_some?: MetadataGpsLatitudeWhereInput | null;
  Metadata_GPSLongitude_some?: MetadataGpsLongitudeWhereInput | null;
  Metadata_GPSProcessingMethod_some?: MetadataGpsProcessingMethodWhereInput | null;
  Metadata_ImageUniqueID_some?: MetadataImageUniqueIdWhereInput | null;
  Metadata_ISOSpeedRatings_some?: MetadataIsoSpeedRatingsWhereInput | null;
  Metadata_UserComment_some?: MetadataUserCommentWhereInput | null;
  Metadata_CustomField14_some?: MetadataCustomField14WhereInput | null;
  Metadata_CustomField15_some?: MetadataCustomField15WhereInput | null;
  Metadata_CustomField16_some?: MetadataCustomField16WhereInput | null;
  Metadata_CustomField17_some?: MetadataCustomField17WhereInput | null;
  Metadata_CustomField20_some?: MetadataCustomField20WhereInput | null;
  Metadata_CustomField5_some?: MetadataCustomField5WhereInput | null;
  Metadata_CustomField6_some?: MetadataCustomField6WhereInput | null;
  Metadata_CustomField7_some?: MetadataCustomField7WhereInput | null;
  Metadata_CustomField9_some?: MetadataCustomField9WhereInput | null;
  Metadata_CreatedTime_some?: MetadataCreatedTimeWhereInput | null;
  Metadata_EditStatus_some?: MetadataEditStatusWhereInput | null;
  Metadata_FixtureIdentifier_some?: MetadataFixtureIdentifierWhereInput | null;
  Metadata_LocalCaption_some?: MetadataLocalCaptionWhereInput | null;
  Metadata_ProgramVersion_some?: MetadataProgramVersionWhereInput | null;
  Metadata_ReferenceDate_some?: MetadataReferenceDateWhereInput | null;
  Metadata_ReferenceNumber_some?: MetadataReferenceNumberWhereInput | null;
  Metadata_ReferenceService_some?: MetadataReferenceServiceWhereInput | null;
  Metadata_ReleaseDate_some?: MetadataReleaseDateWhereInput | null;
  Metadata_ReleaseTime_some?: MetadataReleaseTimeWhereInput | null;
  Metadata_AssetState_some?: MetadataAssetStateWhereInput | null;
  Metadata_Classify_some?: MetadataClassifyWhereInput | null;
  Metadata_ContainedIn_some?: MetadataContainedInWhereInput | null;
  Metadata_ContentValue_some?: MetadataContentValueWhereInput | null;
  Metadata_DocumentText_some?: MetadataDocumentTextWhereInput | null;
  Metadata_Exif_some?: MetadataExifWhereInput | null;
  Metadata_History_some?: MetadataHistoryWhereInput | null;
  Metadata_ImageNotes_some?: MetadataImageNotesWhereInput | null;
  Metadata_JobId_some?: MetadataJobIdWhereInput | null;
  Metadata_Links_some?: MetadataLinksWhereInput | null;
  Metadata_MasterDocumentId_some?: MetadataMasterDocumentIdWhereInput | null;
  Metadata_ObjectCycle_some?: MetadataObjectCycleWhereInput | null;
  Metadata_OwnerId_some?: MetadataOwnerIdWhereInput | null;
  Metadata_ShortUniqueId_some?: MetadataShortUniqueIdWhereInput | null;
  Metadata_TransferredBy_some?: MetadataTransferredByWhereInput | null;
  Metadata_TransferredByEmail_some?: MetadataTransferredByEmailWhereInput | null;
  Metadata_TransferredByFullName_some?: MetadataTransferredByFullNameWhereInput | null;
  Metadata_UniqueId_some?: MetadataUniqueIdWhereInput | null;
  Metadata_UploadedBy_some?: MetadataUploadedByWhereInput | null;
  Metadata_UploadedByFullName_some?: MetadataUploadedByFullNameWhereInput | null;
  Metadata_UploadTime_some?: MetadataUploadTimeWhereInput | null;
  Metadata_UserDefined195_some?: MetadataUserDefined195WhereInput | null;
  Metadata_UserDefined237_some?: MetadataUserDefined237WhereInput | null;
  Metadata_UserDefined238_some?: MetadataUserDefined238WhereInput | null;
  Metadata_UserDefined239_some?: MetadataUserDefined239WhereInput | null;
  Metadata_UserDefined242_some?: MetadataUserDefined242WhereInput | null;
  Metadata_UserDefined62_some?: MetadataUserDefined62WhereInput | null;
  Metadata_CiAdrCity_some?: MetadataCiAdrCityWhereInput | null;
  Metadata_CiAdrCtry_some?: MetadataCiAdrCtryWhereInput | null;
  Metadata_CiAdrExtadr_some?: MetadataCiAdrExtadrWhereInput | null;
  Metadata_CiAdrPcode_some?: MetadataCiAdrPcodeWhereInput | null;
  Metadata_CiAdrRegion_some?: MetadataCiAdrRegionWhereInput | null;
  Metadata_CiEmailWork_some?: MetadataCiEmailWorkWhereInput | null;
  Metadata_CiTelWork_some?: MetadataCiTelWorkWhereInput | null;
  Metadata_CiUrlWork_some?: MetadataCiUrlWorkWhereInput | null;
  Metadata_CountryCode_some?: MetadataCountryCodeWhereInput | null;
  Metadata_IntellectualGenre_some?: MetadataIntellectualGenreWhereInput | null;
  Metadata_Location_some?: MetadataLocationWhereInput | null;
  Metadata_Scene_some?: MetadataSceneWhereInput | null;
  Metadata_SubjectCode_some?: MetadataSubjectCodeWhereInput | null;
  Metadata_AddlModelInfo_some?: MetadataAddlModelInfoWhereInput | null;
  Metadata_Event_some?: MetadataEventWhereInput | null;
  Metadata_MaxAvailHeight_some?: MetadataMaxAvailHeightWhereInput | null;
  Metadata_MaxAvailWidth_some?: MetadataMaxAvailWidthWhereInput | null;
  Metadata_ModelAge_some?: MetadataModelAgeWhereInput | null;
  Metadata_OrganisationInImageName_some?: MetadataOrganisationInImageNameWhereInput | null;
  Metadata_PersonInImage_some?: MetadataPersonInImageWhereInput | null;
  Metadata_AuthorsPosition_some?: MetadataAuthorsPositionWhereInput | null;
  Metadata_CaptionWriter_some?: MetadataCaptionWriterWhereInput | null;
  Metadata_Category_some?: MetadataCategoryWhereInput | null;
  Metadata_City_some?: MetadataCityWhereInput | null;
  Metadata_Country_some?: MetadataCountryWhereInput | null;
  Metadata_Credit_some?: MetadataCreditWhereInput | null;
  Metadata_DateCreated_some?: MetadataDateCreatedWhereInput | null;
  Metadata_Headline_some?: MetadataHeadlineWhereInput | null;
  Metadata_ICCProfile_some?: MetadataIccProfileWhereInput | null;
  Metadata_Instructions_some?: MetadataInstructionsWhereInput | null;
  Metadata_Source_some?: MetadataSourceWhereInput | null;
  Metadata_State_some?: MetadataStateWhereInput | null;
  Metadata_SupplementalCategories_some?: MetadataSupplementalCategoriesWhereInput | null;
  Metadata_TransmissionReference_some?: MetadataTransmissionReferenceWhereInput | null;
  Metadata_Urgency_some?: MetadataUrgencyWhereInput | null;
  Metadata_MinorModelAgeDisclosure_some?: MetadataMinorModelAgeDisclosureWhereInput | null;
  Metadata_ModelReleaseID_some?: MetadataModelReleaseIdWhereInput | null;
  Metadata_ModelReleaseStatus_some?: MetadataModelReleaseStatusWhereInput | null;
  Metadata_PropertyReleaseID_some?: MetadataPropertyReleaseIdWhereInput | null;
  Metadata_PropertyReleaseStatus_some?: MetadataPropertyReleaseStatusWhereInput | null;
  Metadata_Version_some?: MetadataVersionWhereInput | null;
  Metadata_Brand_some?: MetadataBrandWhereInput | null;
  Metadata_Division_some?: MetadataDivisionWhereInput | null;
  Metadata_Header_some?: MetadataHeaderWhereInput | null;
  Metadata_Products_some?: MetadataProductsWhereInput | null;
  Metadata_Produits_some?: MetadataProduitsWhereInput | null;
  Metadata_publishingIssue_some?: MetadataPublishingIssueWhereInput | null;
  Metadata_publishingSubject_some?: MetadataPublishingSubjectWhereInput | null;
  Metadata_Company_some?: MetadataCompanyWhereInput | null;
  Metadata_CompanyShortName_some?: MetadataCompanyShortNameWhereInput | null;
  Metadata_Confidentiality_some?: MetadataConfidentialityWhereInput | null;
  Metadata_EditorialVersion_some?: MetadataEditorialVersionWhereInput | null;
  Metadata_FileCheckFor_some?: MetadataFileCheckForWhereInput | null;
  Metadata_FileStage_some?: MetadataFileStageWhereInput | null;
  Metadata_FileStatus_some?: MetadataFileStatusWhereInput | null;
  Metadata_SentTo_some?: MetadataSentToWhereInput | null;
  Metadata_TargetVersion_some?: MetadataTargetVersionWhereInput | null;
  Metadata_Unit_some?: MetadataUnitWhereInput | null;
  Metadata_Archive_child_some?: MetadataArchiveChildWhereInput | null;
  Metadata_Archive_parent_some?: MetadataArchiveParentWhereInput | null;
  Metadata_BackupName_some?: MetadataBackupNameWhereInput | null;
  Metadata_BitmapGrayscalePicture_some?: MetadataBitmapGrayscalePictureWhereInput | null;
  Metadata_BrandPrisma_some?: MetadataBrandPrismaWhereInput | null;
  Metadata_BrandShortName_some?: MetadataBrandShortNameWhereInput | null;
  Metadata_CategoryPrisma_some?: MetadataCategoryPrismaWhereInput | null;
  Metadata_ContentMediaKind_some?: MetadataContentMediaKindWhereInput | null;
  Metadata_CopyrightLayer_some?: MetadataCopyrightLayerWhereInput | null;
  Metadata_DeliveryAccount_some?: MetadataDeliveryAccountWhereInput | null;
  Metadata_DeliveryCompany_some?: MetadataDeliveryCompanyWhereInput | null;
  Metadata_DeliveryCopyright_some?: MetadataDeliveryCopyrightWhereInput | null;
  Metadata_DeliveryDateFolder_some?: MetadataDeliveryDateFolderWhereInput | null;
  Metadata_DeliveryDateTime_some?: MetadataDeliveryDateTimeWhereInput | null;
  Metadata_DeliveryDcCreator_some?: MetadataDeliveryDcCreatorWhereInput | null;
  Metadata_DeliveryDcRights_some?: MetadataDeliveryDcRightsWhereInput | null;
  Metadata_DeliveryFileType_some?: MetadataDeliveryFileTypeWhereInput | null;
  Metadata_DeliveryFolder_some?: MetadataDeliveryFolderWhereInput | null;
  Metadata_DeliveryKind_some?: MetadataDeliveryKindWhereInput | null;
  Metadata_DeliveryPath_some?: MetadataDeliveryPathWhereInput | null;
  Metadata_DeliveryPersonShownintheImage_some?: MetadataDeliveryPersonShownintheImageWhereInput | null;
  Metadata_DeliveryPhotoshopCredit_some?: MetadataDeliveryPhotoshopCreditWhereInput | null;
  Metadata_DeliveryPhotoshopSource_some?: MetadataDeliveryPhotoshopSourceWhereInput | null;
  Metadata_DeliveryService_some?: MetadataDeliveryServiceWhereInput | null;
  Metadata_DeliverySubject_some?: MetadataDeliverySubjectWhereInput | null;
  Metadata_DeliveryUnderSubject_some?: MetadataDeliveryUnderSubjectWhereInput | null;
  Metadata_Department_some?: MetadataDepartmentWhereInput | null;
  Metadata_DigitalAssetURL_some?: MetadataDigitalAssetUrlWhereInput | null;
  Metadata_Edition_some?: MetadataEditionWhereInput | null;
  Metadata_EnvironnementPhoto_some?: MetadataEnvironnementPhotoWhereInput | null;
  Metadata_FabStorage_some?: MetadataFabStorageWhereInput | null;
  Metadata_FileType_some?: MetadataFileTypeWhereInput | null;
  Metadata_HeaderPrisma_some?: MetadataHeaderPrismaWhereInput | null;
  Metadata_ID_subject_some?: MetadataIdSubjectWhereInput | null;
  Metadata_IdAssignment_some?: MetadataIdAssignmentWhereInput | null;
  Metadata_IdMediaContent_some?: MetadataIdMediaContentWhereInput | null;
  Metadata_IdPhotoPrisma_some?: MetadataIdPhotoPrismaWhereInput | null;
  Metadata_Issue_some?: MetadataIssueWhereInput | null;
  Metadata_JobProcessing_some?: MetadataJobProcessingWhereInput | null;
  Metadata_LayoutPictureEditing_some?: MetadataLayoutPictureEditingWhereInput | null;
  Metadata_LayoutStorage_some?: MetadataLayoutStorageWhereInput | null;
  Metadata_OriginalColor_some?: MetadataOriginalColorWhereInput | null;
  Metadata_OriginalICCProfile_some?: MetadataOriginalIccProfileWhereInput | null;
  Metadata_OriginalName_some?: MetadataOriginalNameWhereInput | null;
  Metadata_OriginalWeigth_some?: MetadataOriginalWeigthWhereInput | null;
  Metadata_PagePrisma_some?: MetadataPagePrismaWhereInput | null;
  Metadata_PhotoStorage_some?: MetadataPhotoStorageWhereInput | null;
  Metadata_PrepressPictureEditing_some?: MetadataPrepressPictureEditingWhereInput | null;
  Metadata_PriceLevel_some?: MetadataPriceLevelWhereInput | null;
  Metadata_PrintingProfile_some?: MetadataPrintingProfileWhereInput | null;
  Metadata_PrismaProduction_some?: MetadataPrismaProductionWhereInput | null;
  Metadata_ProcessHistory_some?: MetadataProcessHistoryWhereInput | null;
  Metadata_ProcessParameter_some?: MetadataProcessParameterWhereInput | null;
  Metadata_ProcessStatus_some?: MetadataProcessStatusWhereInput | null;
  Metadata_Product_some?: MetadataProductWhereInput | null;
  Metadata_ProductShortName_some?: MetadataProductShortNameWhereInput | null;
  Metadata_QualifiedUsePrismaBy_some?: MetadataQualifiedUsePrismaByWhereInput | null;
  Metadata_QualifiedUsePrismaDate_some?: MetadataQualifiedUsePrismaDateWhereInput | null;
  Metadata_QualifiedUsePrismaDuration_some?: MetadataQualifiedUsePrismaDurationWhereInput | null;
  Metadata_QualifiedUsePrismaSupport_some?: MetadataQualifiedUsePrismaSupportWhereInput | null;
  Metadata_RequiredPermission_some?: MetadataRequiredPermissionWhereInput | null;
  Metadata_ResolutionKind_some?: MetadataResolutionKindWhereInput | null;
  Metadata_Royaltyfree_some?: MetadataRoyaltyfreeWhereInput | null;
  Metadata_Section_some?: MetadataSectionWhereInput | null;
  Metadata_SubjectPrisma_some?: MetadataSubjectPrismaWhereInput | null;
  Metadata_Undersubject_some?: MetadataUndersubjectWhereInput | null;
  Metadata_UnderUnderSubject_some?: MetadataUnderUnderSubjectWhereInput | null;
  Metadata_UnitShortName_some?: MetadataUnitShortNameWhereInput | null;
  Metadata_WorkflowKind_some?: MetadataWorkflowKindWhereInput | null;
  Metadata_Make_some?: MetadataMakeWhereInput | null;
  Metadata_Model_some?: MetadataModelWhereInput | null;
  Metadata_CreatorTool_some?: MetadataCreatorToolWhereInput | null;
  Metadata_Rating_some?: MetadataRatingWhereInput | null;
  Metadata_XMPFileStamps_some?: MetadataXmpFileStampsWhereInput | null;
  Metadata_Manifest_some?: MetadataManifestWhereInput | null;
  Metadata_XMPHistory_some?: MetadataXmpHistoryWhereInput | null;
  Metadata_Certificate_some?: MetadataCertificateWhereInput | null;
  Metadata_Marked_some?: MetadataMarkedWhereInput | null;
  Metadata_Owner_some?: MetadataOwnerWhereInput | null;
  Metadata_UsageTerms_some?: MetadataUsageTermsWhereInput | null;
  Metadata_WebStatement_some?: MetadataWebStatementWhereInput | null;
  AND?: PhotoWhereInput[] | null;
  OR?: PhotoWhereInput[] | null;
  NOT?: PhotoWhereInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
