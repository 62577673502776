/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_users {
  __typename: "User";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  name: GraphQL$NonEmptyTrimmedString | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  email: GraphQL$NonEmptyTrimmedString;
  /**
   * Retrieve the roles, skipping googleGroups
   */
  roles: string[];
  googleId: GraphQL$NonEmptyTrimmedString | null;
}

export interface GetUsers {
  /**
   * Retrieves a list of "Users"
   */
  users: GetUsers_users[];
  /**
   * Gets the number of "Users"
   */
  userCount: GraphQL$UnsignedInt;
}

export interface GetUsersVariables {
  first?: GraphQL$UnsignedInt | null;
  skip?: GraphQL$UnsignedInt | null;
  where?: UserFilterInput | null;
  orderBy?: UserOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUser
// ====================================================

export interface GetUser_user {
  __typename: "User";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  name: GraphQL$NonEmptyTrimmedString | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  email: GraphQL$NonEmptyTrimmedString;
  avatarUrl: GraphQL$NonEmptyTrimmedString | null;
  /**
   * Retrieve the roles, skipping googleGroups
   */
  roles: string[];
}

export interface GetUser {
  /**
   * Retrieves one "User", throws an error if it does not exist
   */
  user: GetUser_user;
}

export interface GetUserVariables {
  where: UserUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
}

export interface UpdateUser {
  /**
   * Updates one "User", throws an error if it does not exists
   */
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  where: UserUniqueFilterInput;
  data: UserUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  __typename: "User";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
}

export interface CreateUser {
  /**
   * Creates one "User", throws an error if it already exists
   */
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  data: UserCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGoogleGroup
// ====================================================

export interface GetGoogleGroup_googleGroup_role {
  __typename: "Role";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface GetGoogleGroup_googleGroup {
  __typename: "GoogleGroup";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  groupTitle: GraphQL$NonEmptyTrimmedString;
  role: GetGoogleGroup_googleGroup_role | null;
}

export interface GetGoogleGroup {
  /**
   * Retrieves one "GoogleGroup", throws an error if it does not exist
   */
  googleGroup: GetGoogleGroup_googleGroup;
}

export interface GetGoogleGroupVariables {
  where: GoogleGroupUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGoogleGroup
// ====================================================

export interface UpdateGoogleGroup_updateGoogleGroup {
  __typename: "GoogleGroup";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
}

export interface UpdateGoogleGroup {
  /**
   * Updates one "GoogleGroup", throws an error if it does not exists
   */
  updateGoogleGroup: UpdateGoogleGroup_updateGoogleGroup;
}

export interface UpdateGoogleGroupVariables {
  where: GoogleGroupUniqueFilterInput;
  data: GoogleGroupUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGoogleGroup
// ====================================================

export interface CreateGoogleGroup_createGoogleGroup {
  __typename: "GoogleGroup";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
}

export interface CreateGoogleGroup {
  /**
   * Creates one "GoogleGroup", throws an error if it already exists
   */
  createGoogleGroup: CreateGoogleGroup_createGoogleGroup;
}

export interface CreateGoogleGroupVariables {
  data: GoogleGroupCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGoogleGroups
// ====================================================

export interface GetGoogleGroups_googleGroups_role {
  __typename: "Role";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface GetGoogleGroups_googleGroups {
  __typename: "GoogleGroup";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  groupTitle: GraphQL$NonEmptyTrimmedString;
  role: GetGoogleGroups_googleGroups_role | null;
}

export interface GetGoogleGroups {
  /**
   * Retrieves a list of "GoogleGroups"
   */
  googleGroups: GetGoogleGroups_googleGroups[];
}

export interface GetGoogleGroupsVariables {
  first: GraphQL$UnsignedInt;
  skip?: GraphQL$UnsignedInt | null;
  where?: GoogleGroupFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRole
// ====================================================

export interface GetRole_role {
  __typename: "Role";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface GetRole {
  /**
   * Retrieves one "Role", throws an error if it does not exist
   */
  role: GetRole_role;
}

export interface GetRoleVariables {
  where: RoleUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRole
// ====================================================

export interface UpdateRole_updateRole {
  __typename: "Role";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
}

export interface UpdateRole {
  /**
   * Updates one "Role", throws an error if it does not exists
   */
  updateRole: UpdateRole_updateRole;
}

export interface UpdateRoleVariables {
  where: RoleUniqueFilterInput;
  data: RoleUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRole
// ====================================================

export interface CreateRole_createRole {
  __typename: "Role";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
}

export interface CreateRole {
  /**
   * Creates one "Role", throws an error if it already exists
   */
  createRole: CreateRole_createRole;
}

export interface CreateRoleVariables {
  data: RoleCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRoles
// ====================================================

export interface GetRoles_roles {
  __typename: "Role";
  /**
   * The public ID
   */
  id: GraphQL$UUID;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface GetRoles {
  /**
   * Retrieves a list of "Roles"
   */
  roles: GetRoles_roles[];
}

export interface GetRolesVariables {
  first: GraphQL$UnsignedInt;
  skip?: GraphQL$UnsignedInt | null;
  where?: RoleFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Order the "User" nodes
 */
export enum UserOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  googleGroupCount_ASC = "googleGroupCount_ASC",
  googleGroupCount_DESC = "googleGroupCount_DESC",
  googleId_ASC = "googleId_ASC",
  googleId_DESC = "googleId_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  lastLoginAt_ASC = "lastLoginAt_ASC",
  lastLoginAt_DESC = "lastLoginAt_DESC",
  loginHistoryCount_ASC = "loginHistoryCount_ASC",
  loginHistoryCount_DESC = "loginHistoryCount_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum UserType {
  ServiceUser = "ServiceUser",
  User = "User",
}

/**
 * The "GoogleGroup" node's creation
 */
export interface GoogleGroupCreationInput {
  id?: GraphQL$UUIDv4 | null;
  groupTitle: GraphQL$NonEmptyTrimmedString;
  role?: GoogleGroupCreationRoleInput | null;
  users?: GoogleGroupCreationUsersInput | null;
}

export interface GoogleGroupCreationRoleCreateIfNotExistsInput {
  where: RoleUniqueFilterInput;
  data: RoleCreationInput;
}

export interface GoogleGroupCreationRoleInput {
  connect?: RoleUniqueFilterInput | null;
  connectIfExists?: RoleUniqueFilterInput | null;
  create?: RoleCreationInput | null;
  createIfNotExists?: GoogleGroupCreationRoleCreateIfNotExistsInput | null;
}

export interface GoogleGroupCreationUsersInput {
  create?: UserGoogleGroupCreationWithoutGoogleGroupInput[] | null;
}

/**
 * The "GoogleGroup" node's creation
 */
export interface GoogleGroupCreationWithoutRoleInput {
  id?: GraphQL$UUIDv4 | null;
  groupTitle: GraphQL$NonEmptyTrimmedString;
  users?: GoogleGroupCreationUsersInput | null;
}

/**
 * The "GoogleGroup" nodes' filter
 */
export interface GoogleGroupFilterInput {
  id?: GraphQL$UUID | null;
  id_not?: GraphQL$UUID | null;
  id_in?: GraphQL$UUID[] | null;
  id_not_in?: GraphQL$UUID[] | null;
  id_gt?: GraphQL$UUID | null;
  id_gte?: GraphQL$UUID | null;
  id_lt?: GraphQL$UUID | null;
  id_lte?: GraphQL$UUID | null;
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_not?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_in?: GraphQL$NonEmptyTrimmedString[] | null;
  groupTitle_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  groupTitle_gt?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_gte?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_lt?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_lte?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_contains?: GraphQL$NonEmptyString | null;
  groupTitle_not_contains?: GraphQL$NonEmptyString | null;
  groupTitle_starts_with?: GraphQL$NonEmptyString | null;
  groupTitle_not_starts_with?: GraphQL$NonEmptyString | null;
  groupTitle_ends_with?: GraphQL$NonEmptyString | null;
  groupTitle_not_ends_with?: GraphQL$NonEmptyString | null;
  role?: RoleFilterInput | null;
  role_not?: RoleFilterInput | null;
  role_is_null?: boolean | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  users_every?: UserGoogleGroupFilterInput | null;
  users_some?: UserGoogleGroupFilterInput | null;
  users_none?: UserGoogleGroupFilterInput | null;
  userCount?: GraphQL$UnsignedInt | null;
  userCount_not?: GraphQL$UnsignedInt | null;
  userCount_gt?: GraphQL$UnsignedInt | null;
  userCount_gte?: GraphQL$UnsignedInt | null;
  userCount_lt?: GraphQL$UnsignedInt | null;
  userCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (GoogleGroupFilterInput | null)[] | null;
  OR?: (GoogleGroupFilterInput | null)[] | null;
  NOT?: GoogleGroupFilterInput | null;
}

/**
 * Identifies exactly one "GoogleGroup" by "id" or "groupTitle"
 */
export interface GoogleGroupUniqueFilterInput {
  id?: GraphQL$UUID | null;
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "GoogleGroup" node's update
 */
export interface GoogleGroupUpdateInput {
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
  role?: GoogleGroupUpdateRoleInput | null;
  users?: GoogleGroupUpdateUsersInput | null;
}

export interface GoogleGroupUpdateRoleCreateIfNotExistsInput {
  where: RoleUniqueFilterInput;
  data: RoleCreationInput;
}

export interface GoogleGroupUpdateRoleInput {
  connect?: RoleUniqueFilterInput | null;
  connectIfExists?: RoleUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RoleCreationInput | null;
  createIfNotExists?: GoogleGroupUpdateRoleCreateIfNotExistsInput | null;
  update?: RoleUpdateInput | null;
  updateIfExists?: RoleUpdateInput | null;
}

export interface GoogleGroupUpdateUsersCreateIfNotExistsInput {
  where: UserGoogleGroupUniqueFilterWithoutGoogleGroupInput;
  data: UserGoogleGroupCreationWithoutGoogleGroupInput;
}

export interface GoogleGroupUpdateUsersInput {
  deleteAll?: boolean | null;
  deleteMany?: UserGoogleGroupFilterInput | null;
  delete?: UserGoogleGroupUniqueFilterWithoutGoogleGroupInput[] | null;
  deleteIfExists?: UserGoogleGroupUniqueFilterWithoutGoogleGroupInput[] | null;
  create?: UserGoogleGroupCreationWithoutGoogleGroupInput[] | null;
  createIfNotExists?: GoogleGroupUpdateUsersCreateIfNotExistsInput[] | null;
  updateAll?: UserGoogleGroupUpdateWithoutGoogleGroupInput | null;
  updateMany?: GoogleGroupUpdateUsersUpdateManyInput[] | null;
  update?: GoogleGroupUpdateUsersUpdateInput[] | null;
  updateIfExists?: GoogleGroupUpdateUsersUpdateIfExistsInput[] | null;
  upsert?: GoogleGroupUpdateUsersUpsertInput[] | null;
}

export interface GoogleGroupUpdateUsersUpdateIfExistsInput {
  where: UserGoogleGroupUniqueFilterWithoutGoogleGroupInput;
  data?: UserGoogleGroupUpdateWithoutGoogleGroupInput | null;
}

export interface GoogleGroupUpdateUsersUpdateInput {
  where: UserGoogleGroupUniqueFilterWithoutGoogleGroupInput;
  data?: UserGoogleGroupUpdateWithoutGoogleGroupInput | null;
}

export interface GoogleGroupUpdateUsersUpdateManyInput {
  where?: UserGoogleGroupFilterInput | null;
  data?: UserGoogleGroupUpdateWithoutGoogleGroupInput | null;
}

export interface GoogleGroupUpdateUsersUpsertInput {
  where: UserGoogleGroupUniqueFilterWithoutGoogleGroupInput;
  create: UserGoogleGroupCreationWithoutGoogleGroupInput;
  update?: UserGoogleGroupUpdateWithoutGoogleGroupInput | null;
}

/**
 * The "GoogleGroup" node's update
 */
export interface GoogleGroupUpdateWithoutRoleInput {
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
  users?: GoogleGroupUpdateUsersInput | null;
}

export interface RoleCreationGoogleGroupsInput {
  create?: GoogleGroupCreationWithoutRoleInput[] | null;
}

/**
 * The "Role" node's creation
 */
export interface RoleCreationInput {
  id?: GraphQL$UUIDv4 | null;
  slug: GraphQL$NonEmptyTrimmedString;
  googleGroups?: RoleCreationGoogleGroupsInput | null;
}

/**
 * The "Role" nodes' filter
 */
export interface RoleFilterInput {
  id?: GraphQL$UUID | null;
  id_not?: GraphQL$UUID | null;
  id_in?: GraphQL$UUID[] | null;
  id_not_in?: GraphQL$UUID[] | null;
  id_gt?: GraphQL$UUID | null;
  id_gte?: GraphQL$UUID | null;
  id_lt?: GraphQL$UUID | null;
  id_lte?: GraphQL$UUID | null;
  slug?: GraphQL$NonEmptyTrimmedString | null;
  slug_not?: GraphQL$NonEmptyTrimmedString | null;
  slug_in?: GraphQL$NonEmptyTrimmedString[] | null;
  slug_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  slug_gt?: GraphQL$NonEmptyTrimmedString | null;
  slug_gte?: GraphQL$NonEmptyTrimmedString | null;
  slug_lt?: GraphQL$NonEmptyTrimmedString | null;
  slug_lte?: GraphQL$NonEmptyTrimmedString | null;
  slug_contains?: GraphQL$NonEmptyString | null;
  slug_not_contains?: GraphQL$NonEmptyString | null;
  slug_starts_with?: GraphQL$NonEmptyString | null;
  slug_not_starts_with?: GraphQL$NonEmptyString | null;
  slug_ends_with?: GraphQL$NonEmptyString | null;
  slug_not_ends_with?: GraphQL$NonEmptyString | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  googleGroups_every?: GoogleGroupFilterInput | null;
  googleGroups_some?: GoogleGroupFilterInput | null;
  googleGroups_none?: GoogleGroupFilterInput | null;
  googleGroupCount?: GraphQL$UnsignedInt | null;
  googleGroupCount_not?: GraphQL$UnsignedInt | null;
  googleGroupCount_gt?: GraphQL$UnsignedInt | null;
  googleGroupCount_gte?: GraphQL$UnsignedInt | null;
  googleGroupCount_lt?: GraphQL$UnsignedInt | null;
  googleGroupCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (RoleFilterInput | null)[] | null;
  OR?: (RoleFilterInput | null)[] | null;
  NOT?: RoleFilterInput | null;
}

/**
 * Identifies exactly one "Role" by "id" or "slug"
 */
export interface RoleUniqueFilterInput {
  id?: GraphQL$UUID | null;
  slug?: GraphQL$NonEmptyTrimmedString | null;
}

export interface RoleUpdateGoogleGroupsInput {
  deleteAll?: boolean | null;
  deleteMany?: GoogleGroupFilterInput | null;
  create?: GoogleGroupCreationWithoutRoleInput[] | null;
  updateAll?: GoogleGroupUpdateWithoutRoleInput | null;
  updateMany?: RoleUpdateGoogleGroupsUpdateManyInput[] | null;
}

export interface RoleUpdateGoogleGroupsUpdateManyInput {
  where?: GoogleGroupFilterInput | null;
  data?: GoogleGroupUpdateWithoutRoleInput | null;
}

/**
 * The "Role" node's update
 */
export interface RoleUpdateInput {
  slug?: GraphQL$NonEmptyTrimmedString | null;
  googleGroups?: RoleUpdateGoogleGroupsInput | null;
}

export interface UserCreationGoogleGroupsInput {
  create?: UserGoogleGroupCreationWithoutUserInput[] | null;
}

/**
 * The "User" node's creation
 */
export interface UserCreationInput {
  id?: GraphQL$UUIDv4 | null;
  email: GraphQL$NonEmptyTrimmedString;
  name?: GraphQL$NonEmptyTrimmedString | null;
  googleId?: GraphQL$NonEmptyTrimmedString | null;
  lastLoginAt?: GraphQL$DateTime | null;
  lastIpAddress?: GraphQL$NonEmptyTrimmedString | null;
  avatarUrl?: GraphQL$NonEmptyTrimmedString | null;
  type: UserType;
  googleGroups?: UserCreationGoogleGroupsInput | null;
  loginHistories?: UserCreationLoginHistoriesInput | null;
}

export interface UserCreationLoginHistoriesInput {
  create?: UserLoginHistoryCreationWithoutUserInput[] | null;
}

/**
 * The "User" nodes' filter
 */
export interface UserFilterInput {
  id?: GraphQL$UUID | null;
  id_not?: GraphQL$UUID | null;
  id_in?: GraphQL$UUID[] | null;
  id_not_in?: GraphQL$UUID[] | null;
  id_gt?: GraphQL$UUID | null;
  id_gte?: GraphQL$UUID | null;
  id_lt?: GraphQL$UUID | null;
  id_lte?: GraphQL$UUID | null;
  email?: GraphQL$NonEmptyTrimmedString | null;
  email_not?: GraphQL$NonEmptyTrimmedString | null;
  email_in?: GraphQL$NonEmptyTrimmedString[] | null;
  email_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  email_gt?: GraphQL$NonEmptyTrimmedString | null;
  email_gte?: GraphQL$NonEmptyTrimmedString | null;
  email_lt?: GraphQL$NonEmptyTrimmedString | null;
  email_lte?: GraphQL$NonEmptyTrimmedString | null;
  email_contains?: GraphQL$NonEmptyString | null;
  email_not_contains?: GraphQL$NonEmptyString | null;
  email_starts_with?: GraphQL$NonEmptyString | null;
  email_not_starts_with?: GraphQL$NonEmptyString | null;
  email_ends_with?: GraphQL$NonEmptyString | null;
  email_not_ends_with?: GraphQL$NonEmptyString | null;
  name?: GraphQL$NonEmptyTrimmedString | null;
  name_not?: GraphQL$NonEmptyTrimmedString | null;
  name_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  name_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  name_gt?: GraphQL$NonEmptyTrimmedString | null;
  name_gte?: GraphQL$NonEmptyTrimmedString | null;
  name_lt?: GraphQL$NonEmptyTrimmedString | null;
  name_lte?: GraphQL$NonEmptyTrimmedString | null;
  name_is_null?: boolean | null;
  name_contains?: GraphQL$NonEmptyString | null;
  name_not_contains?: GraphQL$NonEmptyString | null;
  name_starts_with?: GraphQL$NonEmptyString | null;
  name_not_starts_with?: GraphQL$NonEmptyString | null;
  name_ends_with?: GraphQL$NonEmptyString | null;
  name_not_ends_with?: GraphQL$NonEmptyString | null;
  googleId?: GraphQL$NonEmptyTrimmedString | null;
  googleId_not?: GraphQL$NonEmptyTrimmedString | null;
  googleId_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  googleId_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  googleId_gt?: GraphQL$NonEmptyTrimmedString | null;
  googleId_gte?: GraphQL$NonEmptyTrimmedString | null;
  googleId_lt?: GraphQL$NonEmptyTrimmedString | null;
  googleId_lte?: GraphQL$NonEmptyTrimmedString | null;
  googleId_is_null?: boolean | null;
  googleId_contains?: GraphQL$NonEmptyString | null;
  googleId_not_contains?: GraphQL$NonEmptyString | null;
  googleId_starts_with?: GraphQL$NonEmptyString | null;
  googleId_not_starts_with?: GraphQL$NonEmptyString | null;
  googleId_ends_with?: GraphQL$NonEmptyString | null;
  googleId_not_ends_with?: GraphQL$NonEmptyString | null;
  lastLoginAt?: GraphQL$DateTime | null;
  lastLoginAt_not?: GraphQL$DateTime | null;
  lastLoginAt_in?: (GraphQL$DateTime | null)[] | null;
  lastLoginAt_not_in?: (GraphQL$DateTime | null)[] | null;
  lastLoginAt_gt?: GraphQL$DateTime | null;
  lastLoginAt_gte?: GraphQL$DateTime | null;
  lastLoginAt_lt?: GraphQL$DateTime | null;
  lastLoginAt_lte?: GraphQL$DateTime | null;
  lastLoginAt_is_null?: boolean | null;
  lastIpAddress?: GraphQL$NonEmptyTrimmedString | null;
  lastIpAddress_not?: GraphQL$NonEmptyTrimmedString | null;
  lastIpAddress_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  lastIpAddress_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  lastIpAddress_is_null?: boolean | null;
  lastIpAddress_contains?: GraphQL$NonEmptyString | null;
  lastIpAddress_not_contains?: GraphQL$NonEmptyString | null;
  lastIpAddress_starts_with?: GraphQL$NonEmptyString | null;
  lastIpAddress_not_starts_with?: GraphQL$NonEmptyString | null;
  lastIpAddress_ends_with?: GraphQL$NonEmptyString | null;
  lastIpAddress_not_ends_with?: GraphQL$NonEmptyString | null;
  avatarUrl?: GraphQL$NonEmptyTrimmedString | null;
  avatarUrl_not?: GraphQL$NonEmptyTrimmedString | null;
  avatarUrl_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  avatarUrl_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  avatarUrl_is_null?: boolean | null;
  avatarUrl_contains?: GraphQL$NonEmptyString | null;
  avatarUrl_not_contains?: GraphQL$NonEmptyString | null;
  avatarUrl_starts_with?: GraphQL$NonEmptyString | null;
  avatarUrl_not_starts_with?: GraphQL$NonEmptyString | null;
  avatarUrl_ends_with?: GraphQL$NonEmptyString | null;
  avatarUrl_not_ends_with?: GraphQL$NonEmptyString | null;
  type?: UserType | null;
  type_not?: UserType | null;
  type_in?: UserType[] | null;
  type_not_in?: UserType[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  googleGroups_every?: UserGoogleGroupFilterInput | null;
  googleGroups_some?: UserGoogleGroupFilterInput | null;
  googleGroups_none?: UserGoogleGroupFilterInput | null;
  googleGroupCount?: GraphQL$UnsignedInt | null;
  googleGroupCount_not?: GraphQL$UnsignedInt | null;
  googleGroupCount_gt?: GraphQL$UnsignedInt | null;
  googleGroupCount_gte?: GraphQL$UnsignedInt | null;
  googleGroupCount_lt?: GraphQL$UnsignedInt | null;
  googleGroupCount_lte?: GraphQL$UnsignedInt | null;
  loginHistories_every?: UserLoginHistoryFilterInput | null;
  loginHistories_some?: UserLoginHistoryFilterInput | null;
  loginHistories_none?: UserLoginHistoryFilterInput | null;
  loginHistoryCount?: GraphQL$UnsignedInt | null;
  loginHistoryCount_not?: GraphQL$UnsignedInt | null;
  loginHistoryCount_gt?: GraphQL$UnsignedInt | null;
  loginHistoryCount_gte?: GraphQL$UnsignedInt | null;
  loginHistoryCount_lt?: GraphQL$UnsignedInt | null;
  loginHistoryCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (UserFilterInput | null)[] | null;
  OR?: (UserFilterInput | null)[] | null;
  NOT?: UserFilterInput | null;
}

export interface UserGoogleGroupCreationGoogleGroupCreateIfNotExistsInput {
  where: GoogleGroupUniqueFilterInput;
  data: GoogleGroupCreationInput;
}

export interface UserGoogleGroupCreationGoogleGroupInput {
  connect?: GoogleGroupUniqueFilterInput | null;
  create?: GoogleGroupCreationInput | null;
  createIfNotExists?: UserGoogleGroupCreationGoogleGroupCreateIfNotExistsInput | null;
}

export interface UserGoogleGroupCreationUserCreateIfNotExistsInput {
  where: UserUniqueFilterInput;
  data: UserCreationInput;
}

export interface UserGoogleGroupCreationUserInput {
  connect?: UserUniqueFilterInput | null;
  create?: UserCreationInput | null;
  createIfNotExists?: UserGoogleGroupCreationUserCreateIfNotExistsInput | null;
}

/**
 * The "UserGoogleGroup" node's creation
 */
export interface UserGoogleGroupCreationWithoutGoogleGroupInput {
  id?: GraphQL$UUIDv4 | null;
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
  user: UserGoogleGroupCreationUserInput;
}

/**
 * The "UserGoogleGroup" node's creation
 */
export interface UserGoogleGroupCreationWithoutUserInput {
  id?: GraphQL$UUIDv4 | null;
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
  googleGroup: UserGoogleGroupCreationGoogleGroupInput;
}

/**
 * The "UserGoogleGroup" nodes' filter
 */
export interface UserGoogleGroupFilterInput {
  id?: GraphQL$UUID | null;
  id_not?: GraphQL$UUID | null;
  id_in?: GraphQL$UUID[] | null;
  id_not_in?: GraphQL$UUID[] | null;
  id_gt?: GraphQL$UUID | null;
  id_gte?: GraphQL$UUID | null;
  id_lt?: GraphQL$UUID | null;
  id_lte?: GraphQL$UUID | null;
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_not?: GraphQL$NonEmptyTrimmedString | null;
  groupTitle_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  groupTitle_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  groupTitle_is_null?: boolean | null;
  groupTitle_contains?: GraphQL$NonEmptyString | null;
  groupTitle_not_contains?: GraphQL$NonEmptyString | null;
  groupTitle_starts_with?: GraphQL$NonEmptyString | null;
  groupTitle_not_starts_with?: GraphQL$NonEmptyString | null;
  groupTitle_ends_with?: GraphQL$NonEmptyString | null;
  groupTitle_not_ends_with?: GraphQL$NonEmptyString | null;
  user?: UserFilterInput | null;
  user_not?: UserFilterInput | null;
  googleGroup?: GoogleGroupFilterInput | null;
  googleGroup_not?: GoogleGroupFilterInput | null;
  AND?: (UserGoogleGroupFilterInput | null)[] | null;
  OR?: (UserGoogleGroupFilterInput | null)[] | null;
  NOT?: UserGoogleGroupFilterInput | null;
}

/**
 * Given a "googleGroup", identifies exactly one "UserGoogleGroup" by "user"
 */
export interface UserGoogleGroupUniqueFilterWithoutGoogleGroupInput {
  user: UserUniqueFilterInput;
}

/**
 * Given a "user", identifies exactly one "UserGoogleGroup" by "googleGroup"
 */
export interface UserGoogleGroupUniqueFilterWithoutUserInput {
  googleGroup: GoogleGroupUniqueFilterInput;
}

/**
 * The "UserGoogleGroup" node's update
 */
export interface UserGoogleGroupUpdateWithoutGoogleGroupInput {
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "UserGoogleGroup" node's update
 */
export interface UserGoogleGroupUpdateWithoutUserInput {
  groupTitle?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "UserLoginHistory" node's creation
 */
export interface UserLoginHistoryCreationWithoutUserInput {
  id?: GraphQL$UUIDv4 | null;
  jwt: GraphQL$NonEmptyTrimmedString;
}

/**
 * The "UserLoginHistory" nodes' filter
 */
export interface UserLoginHistoryFilterInput {
  id?: GraphQL$UUID | null;
  id_not?: GraphQL$UUID | null;
  id_in?: GraphQL$UUID[] | null;
  id_not_in?: GraphQL$UUID[] | null;
  id_gt?: GraphQL$UUID | null;
  id_gte?: GraphQL$UUID | null;
  id_lt?: GraphQL$UUID | null;
  id_lte?: GraphQL$UUID | null;
  jwt?: GraphQL$NonEmptyTrimmedString | null;
  jwt_not?: GraphQL$NonEmptyTrimmedString | null;
  jwt_in?: GraphQL$NonEmptyTrimmedString[] | null;
  jwt_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  jwt_gt?: GraphQL$NonEmptyTrimmedString | null;
  jwt_gte?: GraphQL$NonEmptyTrimmedString | null;
  jwt_lt?: GraphQL$NonEmptyTrimmedString | null;
  jwt_lte?: GraphQL$NonEmptyTrimmedString | null;
  jwt_contains?: GraphQL$NonEmptyString | null;
  jwt_not_contains?: GraphQL$NonEmptyString | null;
  jwt_starts_with?: GraphQL$NonEmptyString | null;
  jwt_not_starts_with?: GraphQL$NonEmptyString | null;
  jwt_ends_with?: GraphQL$NonEmptyString | null;
  jwt_not_ends_with?: GraphQL$NonEmptyString | null;
  user?: UserFilterInput | null;
  user_not?: UserFilterInput | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  AND?: (UserLoginHistoryFilterInput | null)[] | null;
  OR?: (UserLoginHistoryFilterInput | null)[] | null;
  NOT?: UserLoginHistoryFilterInput | null;
}

/**
 * The "UserLoginHistory" node's update
 */
export interface UserLoginHistoryUpdateWithoutUserInput {
  jwt?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * Identifies exactly one "User" by "id" or "email"
 */
export interface UserUniqueFilterInput {
  id?: GraphQL$UUID | null;
  email?: GraphQL$NonEmptyTrimmedString | null;
}

export interface UserUpdateGoogleGroupsCreateIfNotExistsInput {
  where: UserGoogleGroupUniqueFilterWithoutUserInput;
  data: UserGoogleGroupCreationWithoutUserInput;
}

export interface UserUpdateGoogleGroupsInput {
  deleteAll?: boolean | null;
  deleteMany?: UserGoogleGroupFilterInput | null;
  delete?: UserGoogleGroupUniqueFilterWithoutUserInput[] | null;
  deleteIfExists?: UserGoogleGroupUniqueFilterWithoutUserInput[] | null;
  create?: UserGoogleGroupCreationWithoutUserInput[] | null;
  createIfNotExists?: UserUpdateGoogleGroupsCreateIfNotExistsInput[] | null;
  updateAll?: UserGoogleGroupUpdateWithoutUserInput | null;
  updateMany?: UserUpdateGoogleGroupsUpdateManyInput[] | null;
  update?: UserUpdateGoogleGroupsUpdateInput[] | null;
  updateIfExists?: UserUpdateGoogleGroupsUpdateIfExistsInput[] | null;
  upsert?: UserUpdateGoogleGroupsUpsertInput[] | null;
}

export interface UserUpdateGoogleGroupsUpdateIfExistsInput {
  where: UserGoogleGroupUniqueFilterWithoutUserInput;
  data?: UserGoogleGroupUpdateWithoutUserInput | null;
}

export interface UserUpdateGoogleGroupsUpdateInput {
  where: UserGoogleGroupUniqueFilterWithoutUserInput;
  data?: UserGoogleGroupUpdateWithoutUserInput | null;
}

export interface UserUpdateGoogleGroupsUpdateManyInput {
  where?: UserGoogleGroupFilterInput | null;
  data?: UserGoogleGroupUpdateWithoutUserInput | null;
}

export interface UserUpdateGoogleGroupsUpsertInput {
  where: UserGoogleGroupUniqueFilterWithoutUserInput;
  create: UserGoogleGroupCreationWithoutUserInput;
  update?: UserGoogleGroupUpdateWithoutUserInput | null;
}

/**
 * The "User" node's update
 */
export interface UserUpdateInput {
  email?: GraphQL$NonEmptyTrimmedString | null;
  name?: GraphQL$NonEmptyTrimmedString | null;
  googleId?: GraphQL$NonEmptyTrimmedString | null;
  lastLoginAt?: GraphQL$DateTime | null;
  lastIpAddress?: GraphQL$NonEmptyTrimmedString | null;
  avatarUrl?: GraphQL$NonEmptyTrimmedString | null;
  type?: UserType | null;
  googleGroups?: UserUpdateGoogleGroupsInput | null;
  loginHistories?: UserUpdateLoginHistoriesInput | null;
}

export interface UserUpdateLoginHistoriesInput {
  deleteAll?: boolean | null;
  deleteMany?: UserLoginHistoryFilterInput | null;
  create?: UserLoginHistoryCreationWithoutUserInput[] | null;
  updateAll?: UserLoginHistoryUpdateWithoutUserInput | null;
  updateMany?: UserUpdateLoginHistoriesUpdateManyInput[] | null;
}

export interface UserUpdateLoginHistoriesUpdateManyInput {
  where?: UserLoginHistoryFilterInput | null;
  data?: UserLoginHistoryUpdateWithoutUserInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
