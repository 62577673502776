import { gql } from '@apollo/client';
import { useCreate, useUpdate } from '../../../utils/useUpdateCreate';
import {
  GoogleGroupUpdateInput,
  GoogleGroupCreationInput,
  UpdateGoogleGroup,
  CreateGoogleGroup,
} from '../../../__generated__/queries-auth';
import { GET_GOOGLE_GROUPS } from '../userGroupList/googleGroups.auth.graphql';
import { GET_GOOGLE_GROUP } from './getGoogleGroup.auth.graphql';

export const UPDATE_GOOGLE_GROUP = gql`
  mutation UpdateGoogleGroup(
    $where: GoogleGroupUniqueFilterInput!
    $data: GoogleGroupUpdateInput!
  ) {
    updateGoogleGroup(where: $where, data: $data) {
      id
    }
  }
`;

export const useUpdateGoogleGroup = () =>
  useUpdate<UpdateGoogleGroup, GoogleGroupUpdateInput>(UPDATE_GOOGLE_GROUP, [
    GET_GOOGLE_GROUP,
  ]);

export const CREATE_GOOGLE_GROUP = gql`
  mutation CreateGoogleGroup($data: GoogleGroupCreationInput!) {
    createGoogleGroup(data: $data) {
      id
    }
  }
`;

export const useCreateGoogleGroup = () =>
  useCreate<CreateGoogleGroup, GoogleGroupCreationInput>(CREATE_GOOGLE_GROUP, [
    GET_GOOGLE_GROUPS,
  ]);
