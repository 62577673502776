import { Close, InsertPhotoOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { brandTitleByKey } from '@prismamedia/one-brandkey';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { pick as _pick } from 'ramda';
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebounce } from 'react-use';
import uuid from 'uuid';
import {
  AttributeOrderByInput,
  GetAttributes_attributes,
  GetAuthors_authors,
  BrandKey,
} from '../../../__generated__/queries-web';
import {
  useAuthorCreate,
  useAuthorUpdater,
} from '../../../apollo/mutations/author.web.graphql';
import { useAttributes } from '../../../apollo/queries/getAttributes.web.graphql';
import { useResolvedImageGetter } from '../../../apollo/queries/resolvedImage.image.graphql';
import { useUploadUrlGetter } from '../../../apollo/queries/uploadUrl.image.graphql';
import { ATTRIBUTE_LIST_RESULT } from '../../../crud/attributes/attributesList';
import { auth } from '../../../utils/auth';
import { embedImage, uploadImageWithSignedURL } from '../../../utils/upload';
import {
  AUTHOR_DUP_ERROR_MSG,
  AUTHOR_SUCCESS_MSG,
  DUP_API_ERROR_MSG,
  EMAIL_API_ERROR_MSG,
  EMAIL_ERROR_MSG,
  ERROR_MSG,
} from './constants';
import { InputField } from './InputField';
import { useStyles } from './styles';
import { AuthorContext } from './types';

interface EditContentProps {
  shouldUseDebounce?: boolean;
  author: AuthorContext;
  handleClose: () => void;
  title: string;
  setAuthor: Dispatch<SetStateAction<GetAuthors_authors[] | []>>;
}

export const EditContent: FC<EditContentProps> = ({
  shouldUseDebounce,
  author,
  handleClose,
  title,
  setAuthor,
}) => {
  const { pushNotification } = useNotification();
  const [state, setContext] = useState<AuthorContext>(author);
  const [updateAuthor, { data: updateData }] = useAuthorUpdater();
  const [createAuthor, { data: createData }] = useAuthorCreate();
  const getUploadUrl = useUploadUrlGetter();
  const getResolvedImage = useResolvedImageGetter();

  const [currentInput, setCurrent] = useState<{
    key: keyof AuthorContext;
    value: string | GetAttributes_attributes[];
  }>();

  const { data: attributesByBrand } = useAttributes({
    first: ATTRIBUTE_LIST_RESULT,
    where: {
      brandKey: state.brandKey ?? author.brandKey,
    },
    orderBy: [AttributeOrderByInput.createdAt_DESC],
  });
  const brandsOptions = Object.values(BrandKey).map((key) => ({
    label: brandTitleByKey[key],
    value: key,
  }));

  const queryParams = _pick(
    [
      'name',
      'brandKey',
      'userId',
      'googleId',
      'avatarUrl',
      'description',
      'facebookId',
      'twitterId',
      'instagramId',
      'linkedinId',
      'pinterestId',
      'snapshatId',
      'isMain',
      'isExternal',
      'hasPublicProfile',
      'resume',
      'email',
      'media',
    ],
    state,
  );

  const setValueByProperty = useCallback(
    (
      property: keyof AuthorContext,
      value: string | boolean | GetAttributes_attributes[],
    ) => {
      setContext({ ...state, [property]: value });
    },
    [state],
  );

  useDebounce(
    () => {
      if (currentInput) {
        setValueByProperty(currentInput.key, currentInput.value);
      }
    },
    300,
    [currentInput],
  );

  const isFormValid = state.name !== '' && state.brandKey !== null;
  const isNew = !author.id;

  const handleSwitchChange = useCallback(
    (property: keyof AuthorContext) => {
      return (event: ChangeEvent<HTMLInputElement>) =>
        setValueByProperty(property, event.target.checked);
    },
    [setValueByProperty],
  );

  const handleAttribute = useCallback(
    (attributes: GetAttributes_attributes[]) => {
      if (shouldUseDebounce) {
        setCurrent({ key: 'authorAttributes', value: attributes });
      } else {
        setValueByProperty('authorAttributes', attributes);
      }
    },
    [setValueByProperty, shouldUseDebounce],
  );

  const handleError = (error: Error): string => {
    if (error?.message === EMAIL_API_ERROR_MSG) {
      return EMAIL_ERROR_MSG;
    } else if (error?.message.includes(DUP_API_ERROR_MSG)) {
      return AUTHOR_DUP_ERROR_MSG;
    }
    return ERROR_MSG;
  };

  const getTextProps = useCallback(
    (property: keyof AuthorContext) => {
      return {
        property,
        defaultValue: author[property] as string,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          if (shouldUseDebounce) {
            setCurrent({ key: property, value: event.target.value });
          } else {
            setValueByProperty(property, event.target.value);
          }
        },
      };
    },
    [author, setValueByProperty, shouldUseDebounce],
  );

  const onSubmit = useCallback(async () => {
    const where = state.id ? { id: state.id } : undefined;
    const attributes = state.authorAttributes.map((att, index) => ({
      attribute: {
        connect: {
          id: typeof att === 'string' ? att : att.attribute.id,
        },
      },
      order: index + 1,
    }));

    const deleteAttributes = author.authorAttributes.map(({ order }) => ({
      order,
    }));

    try {
      const result = where
        ? await updateAuthor({
            variables: {
              data: {
                ...queryParams,
                authorAttributes: {
                  ...(deleteAttributes.length && { delete: deleteAttributes }),
                  ...(attributes.length && { create: attributes }),
                },
              },
              where,
            },
          })
        : await createAuthor({
            variables: {
              data: {
                id: uuid(),
                ...queryParams,
              },
            },
          });
      if (result) {
        pushNotification({
          type: NotificationTypeEnum.success,
          message: AUTHOR_SUCCESS_MSG(Boolean(where)),
        });
        handleClose();
      }
    } catch (error) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: handleError(error as Error),
      });
    }
  }, [
    createAuthor,
    handleClose,
    pushNotification,
    queryParams,
    state.id,
    updateAuthor,
    state.authorAttributes,
    author.authorAttributes,
  ]);

  const classes = useStyles();

  const upload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) {
        const {
          getUploadUrl: { key, uploadURL },
        } = await getUploadUrl({
          namespace: 'authors',
          originalName: files[0].name,
        });
        await uploadImageWithSignedURL(files[0], uploadURL);
        const {
          getResolvedImage: { url },
        } = await getResolvedImage({
          key,
        });
        const iframely = await embedImage(url);

        setCurrent({ key: 'avatarUrl', value: url });
        setContext({
          ...state,
          ['media']: iframely ? JSON.stringify(iframely) : null,
        });
      }
    },
    // eslint-disable-next-line
    [state.name],
  );

  useEffect(() => {
    if (isNew && createData?.createAuthor) {
      setAuthor((prev: GetAuthors_authors[]) => [
        ...prev,
        createData.createAuthor,
      ]);
    } else if (updateData?.updateAuthor) {
      setAuthor((prev: GetAuthors_authors[]) =>
        prev?.map(
          (prevAuthor) =>
            [updateData?.updateAuthor].find(
              (update) => update?.id === prevAuthor.id,
            ) || prevAuthor,
        ),
      );
    }
  }, [setAuthor, isNew, createData?.createAuthor, updateData?.updateAuthor]);

  return useMemo(
    () => (
      <>
        <DialogTitle
          classes={{ root: classes.title }}
          data-testid="formDialogTitle"
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={handleClose} aria-label="close" size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content} dividers={true}>
          <div className={classes.leftContent}>
            <div>
              <input
                accept="image/*"
                className={classes.upload}
                id="icon-button-file"
                type="file"
                onChange={upload}
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  size="large"
                >
                  {state.avatarUrl?.length ? (
                    <Avatar alt={state.name} src={state.avatarUrl} />
                  ) : (
                    <InsertPhotoOutlined />
                  )}
                </IconButton>
              </label>
            </div>
          </div>
          <div data-testid="formDialogInputs" className={classes.rightContent}>
            <InputField
              {...getTextProps('userId')}
              label="IdUtilisateur"
              disabled={!auth.user?.isSuperAdmin()}
            />
            <div>
              <span className={classes.switchLabel}>Externe</span>
              <Switch
                onChange={handleSwitchChange('isExternal')}
                checked={Boolean(state.isExternal)}
                color="primary"
              />
            </div>
            <Box display="flex">
              <InputField {...getTextProps('name')} label="Auteur *" />
              <TextField
                select
                size="small"
                label="Marque*"
                sx={{
                  flex: 1,
                  justifyContent: 'end',
                  '> label': {
                    paddingTop: '6px',
                  },
                }}
                {...getTextProps('brandKey')}
                defaultValue={getTextProps('brandKey').defaultValue ?? ''}
                disabled={!!getTextProps('brandKey').defaultValue}
              >
                {brandsOptions.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <InputField
              {...getTextProps('resume')}
              label="Fonction et spécialité"
              rows={4}
            />

            <InputField {...getTextProps('email')} label="Email" />

            <InputField
              {...getTextProps('description')}
              label="Biographie auteur"
              multiline
              rows={4}
            />

            <div>
              <InputField
                {...getTextProps('facebookId')}
                label="Pseudo Facebook"
              />
              <InputField
                {...getTextProps('twitterId')}
                label="Pseudo Twitter"
              />
            </div>
            <div>
              <InputField
                {...getTextProps('instagramId')}
                label="Pseudo Instagram"
              />

              <InputField
                {...getTextProps('linkedinId')}
                label="Pseudo Linkedin"
              />
            </div>
            <div>
              <InputField
                {...getTextProps('pinterestId')}
                label="Pseudo Pinterest"
              />

              <InputField
                {...getTextProps('snapshatId')}
                label="Pseudo Snapchat"
              />
            </div>
            <div>
              <span className={classes.switchLabel}>Auteur principal</span>
              <Switch
                onChange={handleSwitchChange('isMain')}
                checked={Boolean(state.isMain)}
                color="primary"
              />
            </div>
            <div>
              <span className={classes.switchLabel}>
                Afficher sa page profil
              </span>
              <Switch
                onChange={handleSwitchChange('hasPublicProfile')}
                checked={Boolean(state.hasPublicProfile)}
                color="primary"
              />
            </div>
            {!(state.brandKey === BrandKey.GAL) && (
              <Box
                sx={{
                  display: 'flex',
                  paddingRight: '16px',
                  paddingBottom: '16px',
                }}
              >
                <TextField
                  disabled={
                    !state.brandKey ||
                    attributesByBrand?.attributes.length === 0
                  }
                  select
                  size="small"
                  label="Attribut"
                  sx={{
                    flex: 1,
                  }}
                  defaultValue={
                    state.authorAttributes.map((att) =>
                      typeof att === 'string' ? att : att.attribute.id,
                    ) ?? []
                  }
                  SelectProps={{
                    multiple: true,
                    onChange: (value) =>
                      handleAttribute(
                        value.target.value as GetAttributes_attributes[],
                      ),
                  }}
                >
                  {attributesByBrand?.attributes?.map(
                    ({ id, title: attributeTitle }, index) => (
                      <MenuItem key={index} value={id}>
                        {attributeTitle}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Box>
            )}
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.footer }}>
          <Button
            data-testid="submitBtn"
            onClick={onSubmit}
            disabled={!isFormValid}
            color="primary"
            variant="contained"
          >
            {isNew ? 'Créer' : 'Modifier'}
          </Button>
        </DialogActions>
      </>
    ),
    [
      attributesByBrand,
      brandsOptions,
      classes,
      title,
      handleClose,
      upload,
      state,
      getTextProps,
      handleSwitchChange,
      onSubmit,
      isFormValid,
      isNew,
      handleAttribute,
    ],
  );
};
