import { TextField } from '@mui/material';
import { OneMedia, SimpleSelect, isImage } from '@prismamedia/one-components';
import { Dispatch, FC, SetStateAction } from 'react';
import { BrandKey } from '../../__generated__/queries-web';
import { ImageSourceSelect } from '../AppBar/AdvancedSearch/ImageSourceSelect';
import { useFormats } from './utils';

export const MediaFields: FC<{
  media: OneMedia;
  setMedia: Dispatch<SetStateAction<OneMedia>>;
  brandKey: BrandKey;
}> = ({ media, setMedia, brandKey }) => {
  const formats = useFormats(brandKey);

  if (!media) return null;

  const selectedIsImage = isImage(media);
  const credit = media.credit || media.iframely.meta.credit;

  return (
    <>
      {selectedIsImage && (
        <TextField
          label="Crédit"
          data-testid="multimedia-credit"
          required
          sx={{ mb: 3 }}
          variant="standard"
          value={credit || ''}
          onChange={({ target }) =>
            setMedia((prev) => ({ ...prev, credit: target.value }))
          }
          error={!credit}
          helperText={!credit && 'Le champ "credit" du média est requis'}
        />
      )}
      <TextField
        label="Légende"
        sx={{ mb: 3 }}
        variant="standard"
        value={media.caption || ''}
        onChange={({ target }) =>
          setMedia((prev) => ({ ...prev, caption: target.value }))
        }
      />
      {formats && (
        <SimpleSelect
          label="Format"
          options={formats}
          value={media.format || ' '}
          onChange={(_, format) => setMedia((prev) => ({ ...prev, format }))}
          variant="standard"
          sx={{ mb: 3 }}
        />
      )}
      {selectedIsImage && (
        <>
          <ImageSourceSelect
            value={
              media.source
                ? {
                    id: media.source,
                    label: media.source,
                  }
                : undefined
            }
            label="Source"
            onChange={(value) =>
              setMedia((prev) => ({
                ...prev,
                source: value ? (value.label as string) : '',
              }))
            }
            valueToSelectFrom={media.credit}
            TextFieldProps={{
              required: true,
              error: !media.source,
              helperText:
                !media.source && 'Le champ "source" du média est requis',
            }}
          />
        </>
      )}
    </>
  );
};
