import { TextField } from '@mui/material';
import {
  PageWithDrawer,
  NonEditableField,
  getRequiredErrorMessage,
  objectsAreDifferents,
  EditAppBar,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { paths } from '../../../routing/Router/paths';
import { GetUser_user } from '../../../__generated__/queries-auth';
import { useStyles } from './styles';
import { useGetUser, useUpdateUser, useCreateUser } from './user.auth.graphql';
import { formatDate } from '../../../utils/dateUtils';
import { emptyItem, onSave, saveMandatoryFields } from './utils';

export const UserEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [item, setItem] = useState<GetUser_user | undefined>(undefined);
  const { id } = useParams();
  const isCreate = id === 'new';
  const { data, loading, error } = useGetUser(id, isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateItem = useUpdateUser();
  const createItem = useCreateUser();
  const itemDiff = objectsAreDifferents(
    item,
    isCreate ? emptyItem : data?.user,
  );
  const requiredFields = getRequiredErrorMessage<GetUser_user>(
    item,
    saveMandatoryFields,
  );

  useEffect(() => {
    setItem(isCreate ? emptyItem : data?.user || undefined);
  }, [isCreate, data, setItem]);

  return (
    <>
      <EditAppBar
        title={
          isCreate ? item?.name || 'Nouvel utilisateur' : data?.user?.name || ''
        }
        onNavigateBack={() => history.push(paths.USER_LIST)}
        save={{
          disabled: !itemDiff && !isCreate,
          loading: saveLoading,
          onClick: () => {
            onSave(
              item,
              isCreate,
              history,
              setSaveLoading,
              createItem,
              updateItem,
            );
          },
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!item && !data?.user
            ? Error("Cet utilisateur n'existe pas")
            : undefined)
        }
        rightDrawer={
          <>
            <NonEditableField
              className={classes.field}
              label="Id"
              value={item?.id}
            />
            <NonEditableField
              className={classes.field}
              label="Date de création"
              value={formatDate(item?.createdAt, 'dd/MM/yyyy à HH:mm')}
            />
          </>
        }
      >
        {item && (
          <>
            <TextField
              className={classes.field}
              label="Nom"
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, name: value } : prev));
              }}
              value={item.name}
              variant="standard"
            />
            <TextField
              className={classes.field}
              label="Email"
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, email: value } : prev));
              }}
              value={item.email}
              variant="standard"
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
