import DatePicker from '@mui/lab/DatePicker';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { AdvancedSearchProps, SimpleSelect } from '@prismamedia/one-components';
import clsx from 'clsx';
import { ChangeEvent, FC } from 'react';
import {
  ArticleFormat,
  ArticleStatus,
  QualifierStatus,
} from '../../../__generated__/queries-web';
import { useQualifiers } from '../../../pages/ArticleEdit/DrawerContent/getQualifers.web.graphql';
import { useGetSources } from '../../../pages/ArticleEdit/DrawerContent/getSources.web.graphql';
import { NO_SOURCE_ID } from '../../../pages/shared/ListPage/getListPageVariables';
import { CategoriesSearch } from '../../CategoriesSearch';
import { AuthorSelect } from './AuthorSelect';
import { CreatorSelect } from './CreatorSelect';
import { ImageSourceSelect } from './ImageSourceSelect';
import { TagsInput } from './TagsInput';
import { useStyles } from './styles';

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  searchParams,
  updateSearchParam,
  brandKey,
  articleFormat,
}) => {
  const classes = useStyles();
  const { data: qualifiersData } = useQualifiers({
    first: 100,
    where: {
      brandKey,
      status: QualifierStatus.Published,
      format: articleFormat,
    },
  });
  const { data: sourcesData } = useGetSources(brandKey);

  return (
    <>
      <div className={classes.section}>
        <DatePicker
          disableFuture
          inputFormat="dd/MM/yyyy"
          label="Édité du"
          maxDate={searchParams.updatedBefore}
          onChange={updateSearchParam('updatedAfter')}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              className={clsx(classes.input, classes.flexInput)}
            />
          )}
          value={searchParams.updatedAfter || null}
        />
        <DatePicker
          disableFuture
          inputFormat="dd/MM/yyyy"
          label="Au"
          minDate={searchParams.updatedAfter}
          onChange={updateSearchParam('updatedBefore')}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              className={clsx(classes.input, classes.flexInput)}
            />
          )}
          value={searchParams.updatedBefore || null}
        />
        {articleFormat !== ArticleFormat.Video && (
          <CreatorSelect
            value={searchParams.creator}
            onChange={updateSearchParam('creator')}
            className={clsx(classes.input, classes.flexInput)}
          />
        )}
        {articleFormat === ArticleFormat.Rich && (
          <AuthorSelect
            brandKey={brandKey}
            value={searchParams.author}
            onChange={updateSearchParam('author')}
            className={clsx(classes.input, classes.flexInput)}
          />
        )}
        <FormControl
          variant="standard"
          className={clsx(classes.input, classes.flexInput)}
        >
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            multiple
            value={searchParams.status_in || []}
            onChange={({ target }) => {
              updateSearchParam('status_in')(
                target.value?.includes(ArticleStatus.Scheduled)
                  ? [ArticleStatus.Scheduled]
                  : undefined,
              );
            }}
            renderValue={(selected) =>
              selected.includes(ArticleStatus.Scheduled)
                ? 'Publié, Programmé'
                : ''
            }
          >
            <MenuItem dense value={''} disabled>
              <Checkbox checked />
              <ListItemText primary="Publié" />
            </MenuItem>
            <MenuItem dense value={ArticleStatus.Scheduled}>
              <Checkbox
                checked={
                  !!searchParams.status_in?.includes(ArticleStatus.Scheduled)
                }
              />
              <ListItemText primary="Programmé" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Divider />
      <div className={classes.section}>
        <CategoriesSearch
          brandKey={brandKey}
          articleFormat={articleFormat}
          value={searchParams.categories}
          onChange={updateSearchParam('categories')}
          className={clsx(classes.input, classes.flexInput)}
        />
        <TagsInput
          brandKey={brandKey}
          articleFormat={articleFormat}
          value={searchParams.tags}
          onChange={updateSearchParam('tags')}
          className={clsx(classes.input, classes.flexInput)}
        />
        <SimpleSelect
          variant="standard"
          className={clsx(classes.input, classes.flexInput)}
          label="Source de l'article"
          options={[
            { value: '', label: '-' },
            { value: NO_SOURCE_ID, label: 'Sans source' },
            ...(sourcesData?.sources.map(({ id, title }) => ({
              value: id,
              label: title,
            })) || []),
          ]}
          value={searchParams.source}
          onChange={(_, value) => updateSearchParam('source')(value)}
        />
        {!!qualifiersData?.qualifiers.length && (
          <SimpleSelect
            variant="standard"
            className={clsx(classes.input, classes.flexInput)}
            label="Qualifier"
            options={[
              { value: '', label: '-' },
              ...(qualifiersData?.qualifiers.map(({ id, title }) => ({
                value: id,
                label: title,
              })) || []),
            ]}
            value={searchParams.qualifier}
            onChange={(_, value) => updateSearchParam('qualifier')(value)}
          />
        )}
      </div>
      {articleFormat !== ArticleFormat.Video && (
        <>
          <Divider />
          <div className={classes.section}>
            {articleFormat === ArticleFormat.Slideshow && (
              <TextField
                className={clsx(classes.input, classes.flexInput)}
                label="Crédit de l'image"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  updateSearchParam('imageCredit')(event.target.value);
                }}
                value={searchParams.imageCredit}
                variant="standard"
              />
            )}
            <ImageSourceSelect
              value={searchParams.imageSource}
              onChange={updateSearchParam('imageSource')}
              className={clsx(classes.input, classes.flexInput)}
            />
          </div>
        </>
      )}
      {articleFormat === ArticleFormat.Rich && (
        <>
          <Divider />
          <div className={classes.section}>
            <FormControlLabel
              className={classes.flexInput}
              label="Contient push"
              control={
                <Checkbox
                  checked={!!searchParams.containPush}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateSearchParam('containPush')(event.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              className={classes.flexInput}
              label="Contient live"
              control={
                <Checkbox
                  checked={!!searchParams.containLive}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateSearchParam('containLive')(event.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              className={classes.flexInput}
              label="Contient vidéo"
              control={
                <Checkbox
                  checked={!!searchParams.containVideo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateSearchParam('containVideo')(event.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              className={classes.flexInput}
              label="Contient diapo"
              control={
                <Checkbox
                  checked={!!searchParams.containDiapo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateSearchParam('containDiapo')(event.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              className={classes.flexInput}
              label="Contient podcast"
              control={
                <Checkbox
                  checked={!!searchParams.containPodcast}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateSearchParam('containPodcast')(event.target.checked);
                  }}
                />
              }
            />
          </div>
        </>
      )}
    </>
  );
};
