import { gql } from '@apollo/client';
import { useCreate, useUpdate } from '../../../utils/useUpdateCreate';
import {
  RoleUpdateInput,
  RoleCreationInput,
  UpdateRole,
  CreateRole,
} from '../../../__generated__/queries-auth';
import { GET_ROLES } from '../userRoleList/roles.auth.graphql';
import { GET_ROLE } from './getUserRole.auth.graphql';

export const UPDATE_ROLE = gql`
  mutation UpdateRole($where: RoleUniqueFilterInput!, $data: RoleUpdateInput!) {
    updateRole(where: $where, data: $data) {
      id
    }
  }
`;

export const useUpdateRole = () =>
  useUpdate<UpdateRole, RoleUpdateInput>(UPDATE_ROLE, [GET_ROLE]);

export const CREATE_ROLE = gql`
  mutation CreateRole($data: RoleCreationInput!) {
    createRole(data: $data) {
      id
    }
  }
`;

export const useCreateRole = () =>
  useCreate<CreateRole, RoleCreationInput>(CREATE_ROLE, [GET_ROLES]);
