import { gql, useQuery } from '@apollo/client';
import { useFetch } from '../../../utils/useFetch';
import {
  GetRoles,
  GetRolesVariables,
} from '../../../__generated__/queries-auth';

export const GET_ROLES = gql`
  query GetRoles(
    $first: UnsignedInt!
    $skip: UnsignedInt
    $where: RoleFilterInput
  ) {
    roles(first: $first, skip: $skip, where: $where) {
      id
      slug
    }
  }
`;

export const useGetRoles = () =>
  useFetch<GetRoles, GetRolesVariables>(GET_ROLES);

export const useRoles = (variables: GetRolesVariables, skip?: boolean) =>
  useQuery<GetRoles, GetRolesVariables>(GET_ROLES, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
