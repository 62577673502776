import { gql, useQuery } from '@apollo/client';
import { client } from '../../../apollo';
import { GET_USERS } from '../../../apollo/queries/users.auth.graphql';
import { useCreate, useUpdate } from '../../../utils/useUpdateCreate';
import {
  CreateUser,
  GetUser,
  GetUserVariables,
  UpdateUser,
  UserCreationInput,
  UserUpdateInput,
} from '../../../__generated__/queries-auth';

export const GET_USER = gql`
  query GetUser($where: UserUniqueFilterInput!) {
    user(where: $where) {
      id
      name
      createdAt
      email
      avatarUrl
      roles
    }
  }
`;

export const useGetUser = (id?: string, skip?: boolean) =>
  useQuery<GetUser, GetUserVariables>(GET_USER, {
    variables: { where: { id } },
    skip: !id || skip,
  });

export const UPDATE_USER = gql`
  mutation UpdateUser($where: UserUniqueFilterInput!, $data: UserUpdateInput!) {
    updateUser(where: $where, data: $data) {
      id
    }
  }
`;

export const useUpdateUser = () =>
  useUpdate<UpdateUser, UserUpdateInput>(UPDATE_USER, [GET_USER]);

export const CREATE_USER = gql`
  mutation CreateUser($data: UserCreationInput!) {
    createUser(data: $data) {
      id
    }
  }
`;

export const useCreateUser = () =>
  useCreate<CreateUser, UserCreationInput>(CREATE_USER, [GET_USERS]);

export const getUser = async (id: string) => {
  const { data, errors } = await client.query<GetUser, GetUserVariables>({
    query: GET_USER,
    variables: { where: { id } },
  });

  if (errors?.length) {
    throw errors[0];
  }
  return data?.user;
};
